import React, { createContext, useEffect, useRef, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ActiveRequestsManager_Class, getCookie, logout } from './Common/ts/appTools';
import { callAxios, dev_console, isSuccess, printStateMsg, setQueryParameter, whatIsMode } from './Common/ts/commonTools';
// import * as Sentry from "@sentry/react";

// 공용 페이지
import Login from './PublicPage/Login/Login';
import Navi from './PublicPage/Navi/Navi';
import Header from './PublicPage/Header/Header';

// 고객 페이지
import MyInfo from './CustomerPage/Mypage/MyInfo';
import ReservationHome from './CustomerPage/Reservation/ReservationHome';

import ResetUserPassword from './CustomerPage/ResetUserPassword/ResetUserPassword';

import SelectTheme from './CustomerPage/Reservation/SelectTheme';
import PhotoList from './CustomerPage/PhotoList/PhotoList';
import PhotoDetail from './CustomerPage/PhotoList/PhotoDetail';
import PhotoSelect from './CustomerPage/PhotoSelect/PhotoSelect';
import ThemeBookHome from './PublicPage/ThemeBook/ThemeBookHome';

import ThemeBookManagement from './AdminPage/ThemeBookManagement/ThemeBookManagement';

// 네비게이션바 이미지
import schedule_list_icon from "./PublicPage/Navi/static/img/Group 17279.svg";
import photo_icon from "./PublicPage/Navi/static/img/Group 17280.svg";
import photos_icon from "./PublicPage/Navi/static/img/Group 17281.svg";
import bag_icom from "./PublicPage/Navi/static/img/bag-03.svg";
import home_icon_disabled from "./PublicPage/Navi/static/img/home_icon_disabled.svg";
import user_icon from "./PublicPage/Navi/static/img/user_icon.svg";

// 네비게이션바 이미지(활성상태)
import schedule_list_icon_activated from "./PublicPage/Navi/static/img/Group 17279 activated.svg";
import photo_icon_activated from "./PublicPage/Navi/static/img/Group 17280 activated.svg";
import photos_icon_activated from "./PublicPage/Navi/static/img/Group 17281 activated.svg";
import bag_icom_activated from "./PublicPage/Navi/static/img/bag-03 activated.svg";
import home_icon from "./PublicPage/Navi/static/img/home_icon.svg";
import user_icon_activated from "./PublicPage/Navi/static/img/user_icon activated.svg";

import { commonResponseInterface, requestDataFunctionResponseInterface, userInfoInterface, windowSizeInterface } from './Common/interface/App.interface';

import CSS from "./Common/css/common.module.css";
import ThemeBookDetailPage from './PublicPage/ThemeBook/ThemeBookDetailPage';
import { progressScheduleInterface } from './CustomerPage/Reservation/static/interface/reservationHome.interface';
import { AxiosResponse } from 'axios';
import { defaultApiReaponseInterface, tokenCheckInterface } from './Common/interface/Common.interface';
import { scheduleProductInterface } from './CustomerPage/PhotoSelect/static/interface/photoSelect.interface';
import { photoReadDataInterface } from './CustomerPage/PhotoList/static/interface/PhotoList.interface';
import DeleteURL from './PublicPage/DeleteURL/DeleteURL';
import { isNeedToSeletItem } from './CustomerPage/PhotoSelect/static/ts/photoSelectTools';
import TestPage from './TestPage/TestPage';
import CustomerPageHome from './PublicPage/Home/CustomerPage/CustomerPageHome';
import { isProgressingScheduledItem } from './CustomerPage/Reservation/static/ts/reservation.tool';
import MyContractList from './CustomerPage/MyContract/MyContractList';
import MyContractPrintPage from './CustomerPage/MyContract/MyContractPrintPage';
import useInitData from './Common/hooks/useInitData.hook';
import { isCanConfrom } from './CustomerPage/PhotoList/static/ts/confirmEvent.tools';

export interface getPhotoListDataResult {
    photo_read_contract_origin_result: requestDataFunctionResponseInterface<photoReadDataInterface[]>;
    photo_read_contract_edited_result: requestDataFunctionResponseInterface<photoReadDataInterface[]>;
    photo_read_contract_editing_result: requestDataFunctionResponseInterface<photoReadDataInterface[]>;
    photo_read_review_result: requestDataFunctionResponseInterface<photoReadDataInterface[]>;
}

type getMypageDataReturnType = Promise<requestDataFunctionResponseInterface<userInfoInterface | null>>;
type getProgressScheduleReturnType = Promise<requestDataFunctionResponseInterface<progressScheduleInterface[]>>;
type getScheduleProductDataReturnType = Promise<requestDataFunctionResponseInterface<scheduleProductInterface[]>>;
type getPhotoListDataReturnType = Promise<requestDataFunctionResponseInterface<getPhotoListDataResult>>;

export interface commonContextInterface{
    updateMypageData?: () => Promise<void>;
    updateProgressSchedule?: () => Promise<void>;
    updatePhotoListData?: () => Promise<void>;
    updateScheduleProductData?: () => Promise<void>;
    getPhotoDataListPart?: (mode: "origin" | "editing" | "edited" | "review") => Promise<requestDataFunctionResponseInterface<photoReadDataInterface[]>>;
    userInfo?: userInfoInterface | null;
    progressSchedule?: progressScheduleInterface[];
    productList?: scheduleProductInterface[];
    photoListData?: getPhotoListDataResult | null;
    windowSize: windowSizeInterface;
    activeRequestsManager: ActiveRequestsManager_Class | null;
    history: string[];
    historyManager: ({path, action}:{path?: string, action: "add" | "sub" | "clear" | "get_last" | "pop"}) => void | string;
}

const commonContextDafuiltValue:commonContextInterface = {
    windowSize: {
        width: 0, 
        height: 0,
    },
    activeRequestsManager: null,
    history: [],
    historyManager: () => {},
}

// 고객 계정에서 사용하는 context
export const commonContext = createContext<commonContextInterface>(commonContextDafuiltValue);

function App() {

    // Sentry.init({
    //     dsn: "http://7a7a5b86a4a24b1548ee7ccc299c247d@124.111.208.229:9009/3",
    //     integrations: [
    //       Sentry.browserTracingIntegration(),
    //     //   Sentry.replayIntegration(),
    //     ],
    //     // Performance Monitoring
    //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
    //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    //     // Session Replay
    //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // });

    // 데이터를 최신것으로 업데이트하는테 사용하는 state
    const [updateInitData, setUpdateInitData] = useState<boolean>(false);
    
    // 네비게이션바 컨트롤
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

    //  유저 정보
    const {
        data: userInfo,
        setData: setUserInfo,
        dataRef: userInfoRef,
    } = useInitData<userInfoInterface | null>(null);

    //  촬영일정 목록
    const {
        data: progressSchedule,
        setData: setProgressSchedule,
        dataRef: progressScheduleRef,
    } = useInitData<progressScheduleInterface[]>([]);

    // 고객의 제품 정보
    const {
        data: productList,
        setData: setProductList,
        dataRef: productListRef,
    } = useInitData<scheduleProductInterface[]>([]);

    // 고객의 사진 정보
    const {
        data: photoListData,
        setData: setPhotoListData,
        dataRef: photoListDataRef,
    } = useInitData<getPhotoListDataResult | null>(null);

    //  윈도우 창 크기 변화 감지
    const [windowSize, setWindowSize] = useState<{width: number, height:number}>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // api 호출 컨트롤(다중 호출 방지)
    const activeRequestsRef = useRef<string[]>([]);

    // 뒤로가기 버튼의 정보를 저장하는 state
    const [history, setHistory] = useState<string[]>([]);

    // 실제로 컨텐츠들이 출력되는 영역의 ref
    const containerRef = useRef<HTMLDivElement>(null);

    const location = useLocation();

    // 헤더와 네비게이션 바를 제외시킬 경로들
    const popupPaths = [
        '/myContract/print/',
    ];
    // 현재 경로가 excludedPaths 배열에 포함되는지 확인
    const isPopupUrl = popupPaths.some(path => location.pathname.startsWith(path));

    // 로그인한 계정의 타입 (C : 고객, E : OOPS관리자.)
    const user_type = getCookie('user_type');
    // 로그인한 계정의 고유 토큰값
    const token = getCookie('token');

    dev_console.hlog("리랜더링!")
    
    const historyManager = (
        {
            path,
            action,
        }:{
            path?: string, 
            action: "add" | "sub" | "clear" | "get_last" | "pop"
        }
    ) => {
        if(action === "add"){
            setHistory(prevHistory => [...prevHistory, location.pathname]);
        }else if(action === "sub"){
            setHistory(history => history.filter((filter_item) => filter_item !== path));
        }else if(action === "clear"){
            setHistory([]);
        }else if(action === "get_last"){
            return history.pop();
        }else if(action === "pop"){
            if(history.length > 0){
                const last = history.pop();
                setHistory((history) => {
                    if (history.length > 0) {
                        return history.slice(0, history.length - 1);
                    }
                    return history;
                })
                return last;
            }
        }
    };

    const activeRequestsManager = new ActiveRequestsManager_Class(activeRequestsRef);

    // 스케줄 정보를 호출하는 함수
    const getProgressSchedule = async (): getProgressScheduleReturnType => {
        const contract_url = `api/v3/customers/progress_schedule?main_type=0&status_type=0`;
        let contract_schedule_result: AxiosResponse<any, any> | null = null;
        const event_url = `api/v3/customers/progress_schedule?main_type=1&status_type=0`;
        let event_schedule_result: AxiosResponse<any, any> | null = null;

        try{
            let schedule_list: progressScheduleInterface[] = [];

            dev_console.log(contract_url);
            activeRequestsManager.add(contract_url);
            contract_schedule_result = await callAxios.api({
                url: contract_url,
                method: "get",
            })
            dev_console.log(contract_schedule_result);
            if(contract_schedule_result){
                if(isSuccess(contract_schedule_result)){
                    const data: defaultApiReaponseInterface<progressScheduleInterface[]> = contract_schedule_result.data;
                    const data_map_result = data.result.map((item) => {
                        item.main_type = 0;
                        return item;
                    })
                    schedule_list = [...schedule_list, ...data_map_result];
                }else{
                    printStateMsg(contract_schedule_result);
                }
            }

            dev_console.log(event_url);
            activeRequestsManager.add(event_url);
            event_schedule_result = await callAxios.api({
                url: event_url,
                method: "get",
            })
            dev_console.log(event_schedule_result);
            if(event_schedule_result){
                if(isSuccess(event_schedule_result)){
                    const data: defaultApiReaponseInterface<progressScheduleInterface[]> = event_schedule_result.data;
                    const data_map_result = data.result.map((item) => {
                        item.main_type = 1;
                        return item;
                    })
                    schedule_list = [...schedule_list, ...data_map_result];
                }else{
                    printStateMsg(event_schedule_result);
                }
            }

            const now = Date.now();
            schedule_list
            .sort((a, b) => {
                // 1차 정렬: status_id (0이 상위로)
                if (a.status_contents !== b.status_contents) {
                    return a.status_contents - b.status_contents;
                }

                // 2차 정렬: 현재시간에 가까울수록 상단으로
                // 현재 시간

                // a의 시간 계산
                const a_datetime_time = new Date(`${a.schedule_date} ${a.detail?.photo_start}`).getTime();
                // b의 시간 계산
                const b_datetime_time = new Date(`${b.schedule_date} ${b.detail?.photo_start}`).getTime();

                // 유효하지 않은 날짜 처리
                if (isNaN(a_datetime_time) && isNaN(b_datetime_time)) {
                    return 0;
                } else if (isNaN(a_datetime_time)) {
                    return 1;
                } else if (isNaN(b_datetime_time)) {
                    return -1;
                } else {
                    // 2차 정렬: 현재 시간과의 차이가 적은 순서대로 정렬
                    return Math.abs(a_datetime_time - now) - Math.abs(b_datetime_time - now);
                }
            })

            progressScheduleRef.current = schedule_list;

            return ({
                msg: "OK",
                return_data: schedule_list,
            })
        }catch(error){
            dev_console.error(error);
            progressScheduleRef.current = [];
            return ({
                msg: "ERROR",
                error_msg: error,
            })
        }finally{
            activeRequestsManager.sub(contract_url);
            activeRequestsManager.sub(event_url);
        }
    }

    // 유저 개인 정보를 호출하는 함수
    const getMypageData = async (): getMypageDataReturnType => {
        const mypage_url = "api/v3/customers/mypage";
        activeRequestsManager.add(mypage_url);
        try{
            const response = await callAxios.api({
                url: mypage_url,
                method: "get",
            })

            dev_console.log(mypage_url);
            dev_console.log(response);

            let result_data: userInfoInterface | null = null;

            if (response.status === 200 && response.data.msg === "success") {
                result_data = response.data.data;
            } else {
                printStateMsg(response);
            }

            userInfoRef.current = result_data;

            return ({
                msg: "OK",
                return_data: result_data,
            })
        }catch(error){
            dev_console.error(error);
            userInfoRef.current = null;
            return ({
                msg: "ERROR",
                error_msg: error,
            })
        }finally{
            activeRequestsManager.sub(mypage_url);
        }
    }

    const getScheduleProductData = async (): getScheduleProductDataReturnType => {
        const schedule_product_url = `api/v3/customers/schedule_product?search_type=0`;
        try{
            activeRequestsManager.add(schedule_product_url);
            const response = await callAxios.api({
                method: "get",
                url: schedule_product_url,
            })

            dev_console.log(schedule_product_url);
            dev_console.log(response);

            let schedule_product_data: scheduleProductInterface[] = [];
            if(isSuccess(response)){
                const data: defaultApiReaponseInterface<scheduleProductInterface[]> = response.data;
                schedule_product_data = data.result;
                productListRef.current = data.result;
            }else{
                productListRef.current = [];
            }

            return {
                msg: "OK",
                return_data: schedule_product_data,
            }
        }catch(error){
            productListRef.current = [];
            return {
                msg: "ERROR",
                error_msg: error,
            }

        }finally{
            activeRequestsManager.sub(schedule_product_url);
        }
    }

    const getPhotoDataListPart = async (mode: "origin" | "editing" | "edited" | "review") => {
        let main_type_value = 0;
        let status_valeu = 0
        if(mode === "origin"){
            main_type_value = 1;
            status_valeu = 1;
        }else if(mode === "editing"){
            main_type_value = 1;
            status_valeu = 2;
        }else if(mode === "edited"){
            main_type_value = 1;
            status_valeu = 3;
        }else if(mode === "review"){
            main_type_value = 2;
            status_valeu = 1;
        }
        const photo_read_url = `api/v3/customers/photo_read?${setQueryParameter([{key: "main_type", value: `${main_type_value}`}, {key: "status", value: `${status_valeu}`}])}`;

        const return_value: requestDataFunctionResponseInterface<photoReadDataInterface[]> = {
            msg: "ERROR"
        }

        try{
            activeRequestsManager.add(photo_read_url);
            const result = await callAxios.api({
                url: photo_read_url,
                method: "get",
            })
            if(result){
                dev_console.log(photo_read_url);
                dev_console.log(result);
    
                if(result.status === 200 && result.data.msg === "success"){
                    const data:photoReadDataInterface[] = result.data.datas; 
                    return_value.msg = "OK";
                    return_value.return_data = data;
                }else{
                    return_value.msg = "ERROR";
                    return_value.error_msg = result.data.msg;
                }
            }else{
                return_value.msg = "ERROR";
                return_value.error_msg = result;
            }
        }catch(error){
            dev_console.error(error);
            return_value.msg = "ERROR";
            return_value.error_msg = error;
        }finally{
            activeRequestsManager.sub(photo_read_url);
            return (return_value);
        }
    }

    const getPhotoListData = async (): getPhotoListDataReturnType => {
        const photo_read_contract_origin_url = `api/v3/customers/photo_read?main_type=1&status=1`; // 계약촬영사진 - 원본
        const photo_read_contract_editing_url = `api/v3/customers/photo_read?main_type=1&status=2`; // 계약촬영사진 - 편집완료
        const photo_read_contract_edited_url = `api/v3/customers/photo_read?main_type=1&status=3`; // 계약촬영사진 - 편집완료
        const photo_read_review_url = `api/v3/customers/photo_read?main_type=2&status=1`; // 후기사진 - 원본
        try{
            const result: getPhotoListDataResult = {
                photo_read_contract_origin_result: {
                    msg: "ERROR"
                },
                photo_read_contract_editing_result: {
                    msg: "ERROR"
                },
                photo_read_contract_edited_result: {
                    msg: "ERROR"
                },
                photo_read_review_result: {
                    msg: "ERROR"
                },
            }

            result.photo_read_contract_origin_result = await getPhotoDataListPart("origin");
            result.photo_read_contract_editing_result = await getPhotoDataListPart("editing");
            result.photo_read_contract_edited_result = await getPhotoDataListPart("edited");
            result.photo_read_review_result = await getPhotoDataListPart("review");

            photoListDataRef.current = result;

            return ({
                msg: "OK",
                return_data: result,
            })

        }catch(error){

            dev_console.error(error);
            photoListDataRef.current = null;

            return ({
                msg: "ERROR",
                error_msg: error,
            })

        }finally{
            activeRequestsManager.sub(photo_read_contract_origin_url);
            activeRequestsManager.sub(photo_read_contract_editing_url);
            activeRequestsManager.sub(photo_read_contract_edited_url);
            activeRequestsManager.sub(photo_read_review_url);
        }
    }

    const checkIsNewPhoto = () => {
        if(photoListData){
            if(photoListData.photo_read_contract_origin_result.msg === "OK" && photoListData.photo_read_contract_origin_result.return_data){
                if(photoListData.photo_read_contract_origin_result.return_data.some((item) => !item.download_at)){
                    return true;
                }
            }

            if(photoListData.photo_read_contract_editing_result.msg === "OK" && photoListData.photo_read_contract_editing_result.return_data){
                if(photoListData.photo_read_contract_editing_result.return_data.some((item) => {
                    return(
                        isCanConfrom({
                            customer_confirmed: item.customer_confirmed,
                            manager_confirmed: item.manager_confirmed,
                        }).flag
                    );
                })){
                    return true;
                }
            }


            if(photoListData.photo_read_contract_edited_result.msg === "OK" && photoListData.photo_read_contract_edited_result.return_data){
                if(photoListData.photo_read_contract_edited_result.return_data.some((item) => !item.download_at)){
                    return true;
                }
            }

            if(photoListData.photo_read_review_result.msg === "OK" && photoListData.photo_read_review_result.return_data){
                if(photoListData.photo_read_review_result.return_data.some((item) => !item.download_at)){
                    return true;
                }
            }

            return false;
        }else{
            return false;
        }
    }

    useEffect(() => {
        if (token) {
            // 토큰값 검증
            const web_token_api = 'api/v3/accounts/web-token';
            activeRequestsManager.add(web_token_api);
            callAxios.api({
                url: web_token_api,
                method: 'post',
            })
            .then((response) => {
                dev_console.log(web_token_api);
                dev_console.log(response);

                const data: commonResponseInterface<tokenCheckInterface> = response.data;
                if (isSuccess(response)) {
                    if (data.result.msg === "valid") {
                        if(data.result.user_type === user_type){
                            dev_console.log('유효한 토큰');
                        }else{
                            printStateMsg(response);
                            alert("잘못된 로그인 입니다.");
                            logout();
                        }
                    }else{
                        dev_console.error('만료된 토큰');
                        alert("로그인 정보가 만료되었습니다");
                        logout();
                    }
                }
            })
            .catch((error) => {
                dev_console.error(error);
                alert('토큰 검증과정에서 문제가 발생했습니다.');
                logout();
            })
            .finally(() => {
                activeRequestsManager.add(web_token_api);
            })
        }

        // service worker
        if ('serviceWorker' in navigator) {
            const registInit = async () => {
                const registration = await navigator.serviceWorker.register('/service-worker.js');

                registration.waiting?.postMessage('SKIP_WATING');
            };
            registInit();
        }


        // 창 크기 변경 이벤트 핸들러
        const handleAppResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
    
        // 이벤트 리스너 추가
        window.addEventListener('resize', handleAppResize);
    
        if(user_type === "C"){
            callInitData();
        }

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleAppResize);
        };

    }, []);

    const callInitData = async () => {
        try{
            const mypage_data_result = await getMypageData();    //  서버로 데이터 요청후 state를 업데이트
            if(mypage_data_result.error_msg){
                dev_console.error(mypage_data_result.error_msg);
            }
            const progress_schedule_result = await getProgressSchedule();  //  서버로 데이터 요청후 state를 업데이트
            if(progress_schedule_result.error_msg){
                dev_console.error(progress_schedule_result.error_msg);
            }
            const photo_list_Data_result = await getPhotoListData(); //  서버로 데이터 요청후 state를 업데이트
            if(photo_list_Data_result.error_msg){
                dev_console.error(photo_list_Data_result.error_msg);
            }
            const schedule_product_data_result = await getScheduleProductData();   //  서버로 데이터 요청후 state를 업데이트
            if(schedule_product_data_result.error_msg){
                dev_console.error(schedule_product_data_result.error_msg);
            }
        }catch{
            alert("정보 요청 과정에서 문제가 발생했습니다.");
        }finally{
            setUpdateInitData((flag) => !flag);
        }
    }

    // 고객 데이터 개별 호출
    const updateMypageData = async () => {
        try{
            const mypage_data_result = await getMypageData();
            if(mypage_data_result.msg === "ERROR"){
                alert("데이터 호출중에 문제가 발생했습니다.")
            }
            setUserInfo(userInfoRef.current);
        }catch{
            setUserInfo(null);
        }
    }

    // 촬영 일정 정보 업데이트
    const updateProgressSchedule = async () => {
        try{
            const progress_schedule_result = await getProgressSchedule();
            if(progress_schedule_result.msg === "ERROR"){
                alert("촬영일정 정보를 요청하는 과정에서 문제가 발생했습니다.");
            }
            setProgressSchedule(progressScheduleRef.current);
        }catch{
            setProgressSchedule([]);
        }
    }

    // 사진 정보 개별 업데이트
    const updatePhotoListData = async () => {
        try{
            const photo_list_data_result = await getPhotoListData();
            if(photo_list_data_result.msg === "ERROR"){
                alert("사진 정보를 요청하는 과정에서 문제가 발생했습니다.");
            }

            setPhotoListData(photoListDataRef.current);
        }catch{
            setPhotoListData(null);
        }
    }

    // 제품정보 개별 업데이트
    const updateScheduleProductData = async () => {
        try{
            const schedule_product_data_result = await getScheduleProductData();
            if(schedule_product_data_result.msg === "ERROR"){
                alert("제품 정보를 요청하는 과정에서 문제가 발생했습니다.");
            }
            setProductList(productListRef.current);
        }catch{
            setProductList([]);
        }
    }

    useEffect(() => {
        setUserInfo(userInfoRef.current);
        setProgressSchedule(progressScheduleRef.current);
        setProductList(productListRef.current);
        setPhotoListData(photoListDataRef.current);
    }, [updateInitData])

    // // 오른쪽 클릭 방지
    // div의 onContextMenu={handleRightClick}로 설정해서 사용한다.
    // const handleRightClick = (event: React.MouseEvent) => {
    //     event.preventDefault(); // 기본 동작을 방지하여 오른쪽 클릭 메뉴를 막습니다.
    //     // 추가적인 로직을 여기에 구현할 수 있습니다.
    // };
    
    // 로그인한 사람이 고객
    if (token && user_type === 'C') {
        return (
            isPopupUrl
            ?   <Routes>
                    <Route path="/myContract/print/:contract_id" element={<MyContractPrintPage />} />
                </Routes>
            :   <commonContext.Provider value={{
                    updateMypageData,
                    updateProgressSchedule,
                    updatePhotoListData,
                    updateScheduleProductData,
                    getPhotoDataListPart,
                    userInfo,
                    progressSchedule,
                    productList,
                    photoListData,
                    windowSize,
                    activeRequestsManager,
                    history, 
                    historyManager
                }}>
                    <div className={CSS.l_body}>
                        <Header
                            setIsOpenMenu={setIsOpenMenu}
                        />

                        <div 
                            className={CSS.l_container}
                            ref={containerRef}
                        >
                            {/* 네비게이션 바 */}
                            <Navi 
                                isOpenMenu={isOpenMenu}
                                setIsOpenMenu={setIsOpenMenu}
                                navi_item_list={[
                                    {
                                        name: "홈",
                                        img_src: home_icon_disabled,
                                        activated_img_src: home_icon,
                                        navigate: "/",
                                        is_badge_on: false,
                                    },
                                    {
                                        name: "촬영 일정",
                                        img_src: schedule_list_icon,
                                        activated_img_src: schedule_list_icon_activated,
                                        navigate: "/reservationHome",
                                        is_badge_on: progressSchedule.some((item) => isProgressingScheduledItem(item)),
                                    },                                
                                    {
                                        name: "내 사진",
                                        img_src: photos_icon,
                                        activated_img_src: photos_icon_activated,
                                        navigate: "/photoList",
                                        is_badge_on: checkIsNewPhoto(),
                                    },                                
                                    {
                                        name: "제품 제작 신청",
                                        img_src: photo_icon,
                                        activated_img_src: photo_icon_activated,
                                        navigate: "/photoSelect",
                                        is_badge_on: productList.some((item) => isNeedToSeletItem(item)),
                                    },                                
                                    {
                                        name: "테마북",
                                        img_src: bag_icom,
                                        activated_img_src: bag_icom_activated,
                                        navigate: "/themeBook",
                                        is_badge_on: false,
                                    },
                                    {
                                        name: "내 계약",
                                        img_src: user_icon,
                                        activated_img_src: user_icon_activated,
                                        navigate: "/myContract",
                                        is_badge_on: false,
                                    }, 
                                ]}
                            />

                            <main className={CSS.l_main_contents}>
                                {/* 라우팅 */}
                                <Routes>
                                    <Route path='/' element={<CustomerPageHome />} />
                                    
                                    <Route path='/myInfo' element={<MyInfo />} />
                                    
                                    <Route path="/myContract" element={<MyContractList />} />

                                    <Route path='/reservationHome' element={<ReservationHome />} />
                                    <Route path="/selectTheme" element={<SelectTheme />} />

                                    <Route path="/photoList" element={<PhotoList />} />
                                    <Route path="/photoDetail" element={<PhotoDetail />} />
                                    <Route path="/photoSelect" element={<PhotoSelect />} />
                                    
                                    <Route path="/themeBook" element={<ThemeBookHome />} />
                                    <Route path="/themeBookDetailPage" element={<ThemeBookDetailPage />} />

                                    {
                                        whatIsMode().is_not_product_mode_safe
                                        &&  <Route path="/test_page" element={<TestPage />} />
                                    }

                                    <Route path='*' element={<Navigate replace to='/' />} />
                                </Routes>
                            </main>
                        </div>
                    </div>
                </commonContext.Provider>
        );
    }

    if(token && user_type === 'E'){
        return (
            <commonContext.Provider 
                value={{
                    userInfo,
                    windowSize,
                    activeRequestsManager,
                    history, 
                    historyManager
                }}
            >
                <div className={CSS.l_body}>
                    <Header
                        setIsOpenMenu={setIsOpenMenu}
                    />

                    <div 
                        className={CSS.l_container}
                        ref={containerRef}
                    >
                        <Navi 
                            isOpenMenu={isOpenMenu}
                            setIsOpenMenu={setIsOpenMenu}
                            navi_item_list={[  
                                {
                                    name: "홈",
                                    img_src: home_icon_disabled,
                                    activated_img_src: home_icon,
                                    navigate: "/",
                                    is_badge_on: false,
                                },                              
                                {
                                    name: "테마북",
                                    img_src: photo_icon,
                                    activated_img_src: photo_icon_activated,
                                    navigate: "/themeBook",
                                    is_badge_on: false,
                                },
                            ]}
                        />

                        <main className={CSS.l_main_contents}>
                            <Routes>
                                <Route path='/' element={<ThemeBookHome />} />
                                <Route path="/themeBook" element={<ThemeBookHome />} />
                                <Route path="/themeBookDetailPage" element={<ThemeBookDetailPage />} />
                                <Route path="/themeBookManagement" element={<ThemeBookManagement />} />
                                {
                                    whatIsMode().is_not_product_mode_safe
                                    &&  <Route path="/test_page" element={<TestPage />} />
                                }
                                <Route path='*' element={<Navigate replace to='/' />} />
                            </Routes>
                        </main>
                    </div>
                </div>
            </commonContext.Provider>
        )
    }

    // 로그인 페이지
    return (
        <div className='App g_body'>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path="/resetUserPassword" element={<ResetUserPassword />} />
                <Route path="/deleteM" element={<DeleteURL />} />
                {
                    whatIsMode().is_not_product_mode_safe
                    &&  <Route path="/test_page" element={<TestPage />} />
                }
                <Route path='*' element={<Navigate replace to='/' />} />
            </Routes>
        </div>
    );
}

export default App;

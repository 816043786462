import { forwardRef } from "react";
import Input from "../../../Component/Elements/Input/Input";

interface IDInputInterface{
    value: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
}

const IDInput = forwardRef<HTMLInputElement, IDInputInterface>(({
    value,
    onBlur,
    onChange,
    disabled,
}, ref) => {
    return (
        <Input
            type={"text"}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={30}
            ref={ref}
            disabled={disabled}
        />
    )
})

export default IDInput;

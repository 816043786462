import { useNavigate } from "react-router-dom";
import { scheduleProductInterface } from "../../../../../CustomerPage/PhotoSelect/static/interface/photoSelect.interface";
import CSS from "../../static/css/productCard.module.css";
import Button from "../../../../../Component/Elements/Button/Button";
import Text from "../../../../../Component/Elements/Text/Text";
import CardContainer from "../../../../../Component/Elements/CardContainer/CardContainer";

import Media from "../../../../../Component/Elements/Media/Media";

const ProductCard = (
    {
        schedule_product,
    }:{
        schedule_product: scheduleProductInterface | null;
    }
) => {
    const navigate = useNavigate();
    
    const btnHandler = () => {
        navigate("/photoSelect", {state: schedule_product});
    }

    return(
        <CardContainer
            class_name_list={[CSS.l_product_card_main]}
        >
            <div className={CSS.l_product_card__img_container}>
                <Media 
                    object_fit={"cover"}
                    src={schedule_product ? schedule_product.img_url.middle_url : ""}
                />
            </div>
            <div className={CSS.l_product_card__body_container}>
                <Text
                    class_name_list={[CSS.l_product_card__name_container]}
                    size={"size3"}
                    bold={true}
                >
                    {schedule_product ? schedule_product.product_name : "-"}
                </Text>
                <div className={CSS.l_product_card__btn_container}>
                    {
                        schedule_product
                        &&  <>
                                <Button
                                    class_name_list={["g_display_none_object", "mobile"]}
                                    size={"size1"}
                                    onClick={btnHandler}
                                > 
                                    사진 선택
                                </Button>

                                <Text
                                    class_name_list={["g_display_none_object", "web"]}
                                    size={"size1"}
                                > 
                                    제품 제작 신청은 웹 환경에서 진행 가능합니다.
                                </Text>
                            </>
                    }
                </div>
            </div>
        </CardContainer>
    )
}

export default ProductCard;
import { useEffect, useState } from "react";
import { dev_console } from "../../../Common/ts/commonTools";

const useUploadDate = (upload_date: string) => {
    const [uploadDate, setUploadDate] = useState<Date | null>(null);
    const [downloadExpirationDate, setDownloadExpirationDate] = useState<Date | null>(null);

    useEffect(() => {
        if (upload_date) {
            const upload_datetime = new Date(upload_date);
            upload_datetime.setHours(0, 0, 0, 0);
            if (isNaN(upload_datetime.getTime())) {
                dev_console.error("Invalid date format");
            } else {
                setUploadDate(upload_datetime);
                const expirationDate = new Date(upload_datetime);
                expirationDate.setHours(0, 0, 0, 0);
                expirationDate.setFullYear(expirationDate.getFullYear() + 1);
                expirationDate.setMonth(expirationDate.getMonth() + 6);
                setDownloadExpirationDate(expirationDate);
            }
        }
    }, [upload_date]);

    return { uploadDate, downloadExpirationDate };
}

export default useUploadDate;
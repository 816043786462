import { EXAMPLE_TEXT_LIST } from "../../../Common/ts/const";
import Textarea from "../../../Component/Elements/Textarea/Textarea";
import CSS from "../static/css/requestTextArea.module.css";
import InputTitle from "./InputTitle";

const RequestTextArea = (
    {
        request,
        setRequest,
        disabled,
    }:{
        request: string;
        setRequest: React.Dispatch<React.SetStateAction<string>>;
        disabled?: boolean;
    }
) => {

    return(
        <div className={CSS.l_request_text_area_main}>
            <InputTitle
                title="편집 요청사항"
                sub_title="(편집 요청사항이 없을경우 기본 보정이 적용됩니다.)"
                tooltip_node={
                    <div>
                        {
                            EXAMPLE_TEXT_LIST.map((item, index) => {
                                if(item === `\n`){
                                    return(<br></br>)
                                }else{
                                    return(
                                        <div
                                            key={index}
                                        >
                                            {item}
                                        </div>
                                    )
                                }

                            })
                        }
                    </div>
                }
            />
            <div className={`${CSS.l_request_text_area__text_area_container}`}>
                <Textarea
                    class_name_list={[CSS.l_request_text_area]} 
                    placeholder={disabled ? "" : EXAMPLE_TEXT_LIST.join(`\n`)}
                    onChange={(event) => {
                        setRequest(event.target.value);
                    }}
                    disabled={disabled}
                >
                    {request}
                </Textarea>
            </div>
        </div>
    )
}

export default RequestTextArea;
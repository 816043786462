import { useEffect, useState } from "react";
import CSS from "./static/css/mediaCard.module.css";
import Media from "../Media/Media";
import Badge from "../Badge/Badge";

// file_type = 1 : 이미지
// file_type = 2 : 이미지
// file_type = 3 : 이미지
// file_type = 그 외 숫자 : origin_src에서 확장자를 추출하여 자동으로 설정한다.

const MediaCard = (
    {
        origin_src, // file_type의 자동설정이 필요한 경우 경로를통해 자료형을 가져온다.
        src,
        id,
        class_name_list,
        alt,
        onClick,
        loading,
        ref,
        object_fit,
        height_standard,
        size,
        children,
        is_highlight,
        is_hover_action_activate,
        file_type, // file_type을 직접 설정하고싶은경우 사용
        children_container_class_name_list,
        is_print_badge,
        video_controls,
    }:{
        origin_src?: string;
        src?: string;
        id?: string;
        class_name_list?: string[];
        alt?: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>
        loading?: "eager" | "lazy";
        ref?: React.RefObject<HTMLDivElement>
        object_fit?: "cover" | "fill" | "contain" | "scale-down" | "none";
        height_standard?: boolean;
        size?: "small" | "middle" | "big" | "full" | "auto";
        children?: React.ReactNode;
        is_highlight?: boolean;
        is_hover_action_activate?: boolean;
        file_type?: string;
        children_container_class_name_list?: string[];
        is_print_badge?: boolean;
        video_controls?: boolean;
    }
) => {

    const [className, setClassName] = useState<string>("");
    const [childrenContainerClassName, setChildrenContainerClassName] = useState<string>("");
    
    useEffect(() => {

        const total_class_name_list: string[] = [];

        total_class_name_list.push("g_box_shadow");
        
        if(is_hover_action_activate){
            total_class_name_list.push("g_box_shadow_hover");
        }

        if(is_highlight){
            total_class_name_list.push("g_highlight");
        }

        if(onClick){
            total_class_name_list.push("g_click_event_item")
        }

        total_class_name_list.push(CSS.l_img_card_main);

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        if(size){
            total_class_name_list.push(CSS.size);
            total_class_name_list.push(CSS[size]);
        }

        setClassName(total_class_name_list.join(" "));

    }, [class_name_list, is_highlight, is_hover_action_activate, size, onClick])

    useEffect(() => {
        const class_name_list:string[] = [];

        if(children_container_class_name_list){
            for(const children_container_class_name of children_container_class_name_list){
                class_name_list.push(children_container_class_name);
            }
        }

        class_name_list.push(CSS.l_children_container)

        setChildrenContainerClassName(class_name_list.join(" "));
    }, [children_container_class_name_list])

    return (
        <div 
            className={className}
            onClick={onClick}
        >   
            {
                is_print_badge
                &&  <Badge
                        is_print_badge={is_print_badge}
                    />
            }
            <div 
                className={CSS.l_img_container}
                id={id}
                ref={ref}
            >
                <Media 
                    origin_src={origin_src}
                    src={src}
                    alt={alt}
                    loading={loading}
                    object_fit={object_fit}
                    height_standard={height_standard}
                    file_type={file_type}
                    video_controls={video_controls}
                />
            </div>
            {
                children
                &&  <div className={childrenContainerClassName}>
                        {children}
                    </div>  
            }
        </div>
    )
}

export default MediaCard;
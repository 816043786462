import { TermsOfUseInterface } from "../../../static/interface/MyContractPrintPage.interface";
import PartContainer from "../PartContainer/PartContainer";
import Tag from "../Tag/Tag";
import TextLine from "../TextLine/TextLine";
import TextLineContainer from "../TextLineContainer/TextLineContainer";
import CSS from "./static/css/ContractTermsOfUse.module.css";

const CONTRACT_HEADER: TermsOfUseInterface[][] = [
    [
        {
            text: "– 약관을 확인하지 않을 경우 발생하는 불이익에 대한 책임은 계약자에게 있습니다.",
            size: "small_medium",
            color: "red",
        }
    ],[
        {
            text: "☆ (주)파스텔 에서 계약하신 고객은 당사 모든 계열사(베이비파스텔, 달빛레코드, 모먼트캡슐, 기로연)를 상품에 따라 이용하실 수 있으며, 촬영에 대한 각종 안내와, 매달 진행하는 이벤트를 SMS, 카카오톡 및 기타 온라인 채널로 알려드리며, 계약은 수신 동의를 전제합니다.",
            size: "small_medium",
            color: "blue",
        }
    ]
]

const CONTRACT_TERMS_OF_USE: TermsOfUseInterface[][] = [
    [
        {
            text: "1. 만삭, 50일 등 무료 체험 촬영 후 계약 시, 원본 파일은 다운로드 혹은 이메일 수신의 방법으로 제공됩니다. 출고된 원본은 복제가 가능한 상품으로 간주되며, 전자상거래법 제17조 4항에의거 청약철회 및 반품이 불가능하여 원본 파일 비용이 차감됩니다. ※원본 파일 비용 : ⓐ만삭원본+영상 30만원 ⓑ50일원본+영상 30만원 ⓒ100일부터 1테마당 20만원(서비스촬영 포함) ⓓ기타 비용 책정된 제공 서비스",
            size: "small",
        }
    ],[
        {
            text: "2. 계약 취소 시, 당사에 발생한 손해 비용이 위약금으로 청구되며, 손해비용은 피계약자의 촬영을 진행하기 위해 발생한 부대비용과 피계약자의 스케줄로 인해 다른 고객을 촬영하지 못함으로 발생되는 기회비용입니다.",
            size: "small",
        },{
            text: "(위약금 : 할인 전 상품 총액의 10%이며, 계약서 작성 이후 촬영 진행 중 또는 촬영 후인 경우는 1번 항목 차감 후 잔금의 10%)",
            color: "red",
            size: "small",
        }
    ],[
        {
            text: "3. 고객에게 제공되는 사진 파일은 보정본이 아닌 원본 상태로만 제공됩니다(원본 수정 요청 시, 추가요금 발생).",
            size: "small",
        }
    ],[
        {
            text: "4. 원본 파일 보관은 한국소비자원 권고기준에 따라 다운로드, 저장매체 등으로 파일을 인도한 후 1년이며, 보관 기한 만료 시 보관하지 않습니다. 당사에 보관 중인 원본 파일이 1년 이내 손상 혹은 유실된 경우 재촬영을 원칙으로 하며, 촬영 불가 시 약관 1번 항목의 금액으로 배상합니다.",
            size: "small",
        },{
            text: "(기한 경과 후 파일 유실에 대한 책임은 계약자에게 있습니다).",
            size: "small",
            color: "red",
        }
    ],[
        {
            text: "5. 촬영된 사진 파일은 당사의 창작물이며 저작권은 당사에 있습니다. 촬영된 사진은 온라인, 오프라인 미디어 채널에 홍보용으로 게시될 수 있습니다(당사와의 계약은 사진 게시 동의를 포함합니다. 게시에 동의하지 않을 경우, 계약 시 별도로 요청하실 수 있습니다(※게시 후 게시중단 요청도 가능).",
            size: "small",
        }
    ],[
        {
            text: "6. 상품의 안정적 공급과 품질 유지를 위한 사진 선택 기간은, 단일 계약건의 최종 촬영일로부터 1년이며, 선택 지연에 대해 별도로 연락드리지 않습니다. 선택 지연으로 인한 단종 시, 동급의 상품으로 대체 제작됩니다. 1년 경과 시, 재료 혹은 제품 변경에 따른 추가 비용(1년×상품 총액의10%)이 청구될 수 있습니다. 제작 기간은 사진 선택일로부터 6주입니다. 완성품은 비대면 출고를 원칙으로 하며, 배송비는 고객 부담입니다. 미출고 상품 보관 기한은 최대 3개월이며, 기한 경과 후 상품 유실 및 훼손 시 당사는 책임지지 않습니다.",
            size: "small",
        }
    ],[
        {
            text: "7. 사진은 원본 상태의 수정 및 편집이 아닌, 액자 혹은 앨범 규격에 맞춘 후 수정으로 진행되며, 액자의 가로·세로 형태는 선택하신 사진에 따라 달라질 수 있습니다(원본 상태의 수정 요청 시, 추가 비용 발생). 움직임이 많은 아기 촬영 특성상, 소형 액자 제작에 문제가 없으나 대형 출력이 어려운 원본 파일을 수령하실 수 있습니다. 상품 제작 권장 범위를 초과할 경우, 대체 사진 선택이 요청될 수 있습니다.",
            size: "small",
        }
    ],[
        {
            text: "8. 촬영 테마 혹은 의상은 시즌에 따라 변경될 수 있으며, 변경된 테마 혹은 의상은 다른 테마 및 의상으로 대체될 수 있습니다.",
            size: "small",
        }
    ],[
        {
            text: "9. 예약시간에 늦으실 경우, 당일 촬영이 불가능할 수 있습니다. 예약 변경가능 기한은 촬영 전 3일까지입니다. 촬영 전 2일까지 촬영 확정(통화, 또는 문자 답변, 카카오톡 답변)이 되지 않을 경우 예약은 자동 취소되며, 새로운 일정을 예약하셔야 합니다.",
            size: "small",
        },{
            text: "(촬영 당일 취소로 인한 당사의 피해 유발 시 불이익이 발생할 수 있습니다)​",
            size: "small",
            color: "red",
        }
    ],[
        {
            text: "10. 재촬영 요청 시 촬영 후 1개월 이내 동일 테마, 동일 의상으로 1회 진행되며, 계약하신 전체 촬영 중 2테마까지만 가능합니다. 재촬영 시 테마를 변경할 경우 유료로 진행됩니다.",
            size: "small",
        }
    ],[
        {
            text: "11. 베이비 성장 상품의 촬영 유효기한은 생후 18개월까지를 원칙으로 합니다. 평일 이벤트 적용 상품은 모든 촬영이 평일에 진행되어야 제작됩니다.",
            size: "small",
        }
    ],[
        {
            text: "12. 당사는 피계약자 외 제3자에게 계약서 혹은 그 내용을 공개하지 않으며, 피계약자 또한 정보를 유출할 수 없습니다.",
            size: "small",
        },{
            text: "(유출 시 영업 방해에 따른 피해 보상이 청구될 수 있으며 계약 내용, 개인 정보 등의 공개로 인한 피해의 모든 책임은 공개자에게 있습니다)",
            size: "small",
            color: "red",
        }
    ]
];

const INPUT_TEXT: TermsOfUseInterface[][] = [
    [
        {
            text: "*촬영원본비용, 계약취소위약금, 평일이벤트등",
            color: "red",
            size: "medium_large",
            bold: true,
        },{
            text: "모든 약관 내용을 충분히 설명받고 이에 동의합니다.",
            size: "medium_large",
        }
    ]
]

const ContractTermsOfUse = () => {
    return(
        <PartContainer
            tag={
                <Tag 
                    title={"계약동의"}
                />
            }
            body={
                <div className={CSS.l_contract_terms_of_use_main}>
                    <TextLineContainer>
                        {
                            CONTRACT_HEADER.map((item, index) => {
                                return(<TextLine text={item} key={index}/>)
                            })
                        }
                    </TextLineContainer>
                    <TextLineContainer>
                        {
                            CONTRACT_TERMS_OF_USE.map((item, index) => {
                                return(<TextLine text={item} key={index}/>)
                            })
                        }
                    </TextLineContainer>
                    <div
                        className={CSS.l_contract_terms_of_use__input_container}
                    >
                        <input type={"checkbox"} checked disabled/>
                        <TextLineContainer>
                            {
                                INPUT_TEXT.map((item, index) => {
                                    return(<TextLine text={item} key={index}/>)
                                })
                            }
                        </TextLineContainer>
                    </div>
                </div>
            }
        />
    )
}

export default ContractTermsOfUse;
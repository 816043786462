import { callAxios, dev_console } from "../../../../../Common/ts/commonTools";

export const getDifferentImageUrls = (
    {
        origin,
        newContent,
        conditions
    }:{
        origin: string, 
        newContent: string, 
        conditions: "added" | "removed"
    }
) => {
    // HTML 문자열에서 이미지 URL을 추출하는 함수
    const extractImageUrls = (html: string): string[] => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imgTags = Array.from(doc.getElementsByTagName('img'));
        return imgTags.map(img => img.src);
    };
  
    // 원본과 새로운 콘텐츠에서 이미지 URL을 추출
    const originImageUrls = extractImageUrls(origin);
    const newImageUrls = extractImageUrls(newContent);
  
    if(conditions === "removed"){
        // 원본에는 있지만 새로운 콘텐츠에는 없는 URL을 찾음
        return originImageUrls.filter(url => !newImageUrls.includes(url));
    }else if(conditions === "added"){
        // 새로운 콘텐츠에는 있지만 원본에는 없는 URL을 찾음
        return newImageUrls.filter(url => !originImageUrls.includes(url));
    }else{
        return [];
    }
};

export const removeS3ImageList = async (images: string[]) => {
    for(const img of images){
        const config = {
            img_url: img,
        }

        const dummy_remove_img_url = "api/v3/customers/thema-book-content-img-delete";
        const result =  await callAxios.api({
            url: dummy_remove_img_url,
            method: "post",
            dataType: "json",
            data: config,
        })
        
        if(result){
            dev_console.log("삭제 성공!");
        }
    }
}
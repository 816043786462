import { TermsOfUseInterface } from "../../../static/interface/MyContractPrintPage.interface";
import CSS from "./static/css/TextLine.module.css";

const TextLine = (
    {
        text
    }:{
        text: TermsOfUseInterface[]
    }
) => {
    return(
        <div className={CSS.l_text_line_main}>
            {
                text.map((item, index) => {
                    const class_name_list: string[] = [CSS.l_text_line__text_block];

                    if(item.size){
                        class_name_list.push(CSS.size);
                        class_name_list.push(CSS[item.size]);
                    }

                    if(item.color){
                        class_name_list.push(CSS.color);
                        class_name_list.push(CSS[item.color]);
                    }

                    if(item.bold){
                        class_name_list.push(CSS.bold);
                    }

                    return(
                        <span 
                            key={index}
                            className={class_name_list.join(" ")}
                        >
                            {`${item.text}`}
                        </span>
                    )
                })
            }
        </div>
    )
}

export default TextLine;
import { useEffect, useRef, useState } from "react";
import noimg from "../static/img/noimage2.svg";
import CSS from "../static/css/img.module.css";

const Img = (
    {
        src,
        id,
        alt,
        onClick,
        onLoad,
        loading,
        title,
        object_fit,
        height_standard,
        class_name_list,
    }:{
        src?: string;
        id?: string;
        className?: string;
        alt?: string;
        onClick?: React.MouseEventHandler<HTMLImageElement>
        onLoad?: React.ReactEventHandler<HTMLImageElement>; 
        loading?: "eager" | "lazy";
        title?: string,
        object_fit?: "cover" | "fill" | "contain" | "scale-down" | "none";
        height_standard?: boolean;
        class_name_list?: string[];
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name)
            }
        }
        
        total_class_name_list.push(CSS.l_img_main)

        if(object_fit){
            total_class_name_list.push(CSS.object_fit);

            if(object_fit === "cover"){
                total_class_name_list.push(CSS.cover);
            }else if(object_fit === "fill"){
                total_class_name_list.push(CSS.fill);
            }else if(object_fit === "contain"){
                total_class_name_list.push(CSS.contain);
            }else if(object_fit === "scale-down"){
                total_class_name_list.push(CSS.scale_down);
            }else{
                total_class_name_list.push(CSS.none);
            }
        }

        if(height_standard){
            total_class_name_list.push(CSS.height_standard);
        }

        setTotalClassName(total_class_name_list.join(" "))

    }, [object_fit, height_standard, class_name_list])

    return(
        <img
            className={totalClassName}
            ref={imgRef}
            id={id}
            src={isError ? noimg : src}
            onLoad={(e) => {
                if(onLoad){
                    onLoad(e);
                }
            }}
            onError={() => {
                setIsError(true);
            }}
            alt={alt}
            onClick={onClick}
            loading={loading}
            title={title}
            draggable="false"
        />
    ) 
}

export default Img;
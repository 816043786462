import { useEffect, useState } from "react";
import Text from "../../Component/Elements/Text/Text";
import MoveBtn from "./components/MoveBtn";
import OrderBoard from "./components/OrderBoard";

import CSS from "./static/css/AdvertisingBanner.module.css";
import img1 from "./static/img/Group 17579@2x.png";
import img2 from "./static/img/Group 17616@2x.png";
import img3 from "./static/img/Group 17615@2x.png";
import img4 from "./static/img/Group 17613@2x.png";
import img5 from "./static/img/Group 17614@2x.png";

interface advertisingInterface{
    src: string;
    is_ready: boolean;
} 

const AdvertisingBanner = () => {
    const [imgIndex, setImgIndex] =  useState<number>(0);

    const img_list: advertisingInterface[] = [
        {
            src: img1,
            is_ready: false,
        },
        {
            src: img2,
            is_ready: false,
        },
        {
            src: img3,
            is_ready: false,
        },
        {
            src: img4,
            is_ready: false,
        },
        {
            src: img5,
            is_ready: false,
        },
    ]

    const moveBefore = () => {
        if(imgIndex > 0){
            setImgIndex((index) => index - 1);
        }else{
            setImgIndex(img_list.length - 1);
        }
    }

    const moveNext = () => {
        if(img_list.length > imgIndex + 1){
            setImgIndex((index) => index + 1);
        }else{
            setImgIndex(0);
        }
    }

    const leftClickHandler = () => {
        moveBefore();
    }

    const rightClickHandler = () => {
        moveNext();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            moveNext();
        }, 5000);

        return () => clearInterval(interval);
    }, [imgIndex])

    return(
        <div
            className={CSS.l_advertising_banner_main}
        >
            <div className={CSS.l_advertising_banner__order_container}>
                <OrderBoard 
                    order={imgIndex + 1}
                    maximum={img_list.length}
                />
            </div>
            <img 
                className={CSS.l_advertising_banner__img}
                src={img_list[imgIndex].src}
            />
            <div className={CSS.l_advertising_banner__move_btn_container}>
                <MoveBtn 
                    leftClickHandler={leftClickHandler}
                    rightClickHandler={rightClickHandler}
                />
            </div>
            {
                !img_list[imgIndex].is_ready
                &&  <div
                        className={CSS.l_advertising_banner__black_wrap}
                    >
                        <Text
                            size={"size5"}
                            color={"white"}
                        >
                            준비중입니다
                        </Text>
                    </div>
            }
        </div>
    )
}

export default AdvertisingBanner;
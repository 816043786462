import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataInfo from "../../Component/Elements/DataInfo/DataInfo";
import { progressScheduleInterface } from "./static/interface/reservationHome.interface";
import { callAxios, dev_console, isSuccess, isUseTestData, printStateMsg } from "../../Common/ts/commonTools";
import { contractScheduleReservationConfigInterface, selectedThemeListInterface, themaInterface, themaListInterface } from "./static/interface/selectTheme.interface";
import { defaultApiReaponseInterface } from "../../Common/interface/Common.interface";
import { commonContext } from "../../App";
import CSS from "./static/css/selectTheme.module.css";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import Button from "../../Component/Elements/Button/Button";
import ThemeListContainer from "./components/ThemeListContainer";
import TextFlexWrap from "../../Component/Elements/TextFlexWrap/TextFlexWrap";
import CardContainer from "../../Component/Elements/CardContainer/CardContainer";
import useReservationInfoData from "../../Component/Elements/DataInfo/hooks/useReservationInfoData.hook";

const SelectTheme = () => {

    // 로딩중
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // 읽기전용
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    // 테마정보(정제하기전 데이터)
    const themeDataRef = useRef<themaListInterface | null>(null);
    // 선택된 테마 정보(테마 선택을 위해 타입을 추가한 데이터)
    const [selectedThemeList, setSelectedThemeList] = useState<selectedThemeListInterface[]>([]);

    const [btnNode, setBtnNode] = useState<React.ReactNode | null>(null);
    
    const {
        infoDataList,
        setOfficeName,
        setScheduleData
    } = useReservationInfoData();

    const common_context = useContext(commonContext);

    const location = useLocation();
    const data = location.state as { schedule_info_for_select_theme: progressScheduleInterface };

    const navigator = useNavigate();

    useEffect(() => {

        if(!data.schedule_info_for_select_theme){
            navigator("/reservationHome");
        }else{
            setIsLoading(true);
            setIsReadOnly(data.schedule_info_for_select_theme.btn_type !== 0);

            let thema_list_url = `api/v3/customers/thema_list?schedule_id=${data.schedule_info_for_select_theme.schedule_id}&thema_id=${data.schedule_info_for_select_theme.detail?.thema_id_list}`;

            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.add(thema_list_url);
            }
            callAxios.api({
                url: thema_list_url,
                method: "get",
            })
            .then((response) => {
                dev_console.log(thema_list_url);
                dev_console.log(response);

                if(isSuccess(response)){

                    const data: defaultApiReaponseInterface<themaListInterface> = response.data;

                    themeDataRef.current = data.result;
                    setOfficeName(data.result.office_name);

                    const remove_empty_date = data.result.thema_list.filter((item) => item.list.length > 0 && parseInt(item.theme_num) > 0);
                    const collator = new Intl.Collator('ko-KR');
                    const theme_data = remove_empty_date.map((item) => {
                        const solt_list = item.list.sort((a, b) => collator.compare(a.thema_name, b.thema_name));

                        return({
                            ...item,
                            list: solt_list,
                            selected_theme_list: [],
                        })
                    })

                    setSelectedThemeList(theme_data);
                }else{
                    alert("테마 데이터 처리 과정에서 문제가 발생했습니다.");
                    printStateMsg(response);
                }
            })
            .catch((error) => {
                alert("테마 데이터 요청 과정에서 문제가 발생했습니다.");
                dev_console.error(error)
            })
            .finally(() => {
                if(common_context.activeRequestsManager){
                    common_context.activeRequestsManager.sub(thema_list_url);
                }
                setIsLoading(false);
            })

            return (() => {
                // 페이지에서 이탈할때 요청중인 api목록을 비운다.
                if(common_context.activeRequestsManager){
                    common_context.activeRequestsManager.clear();
                }
            })
        }

    }, [])

    useEffect(() => {
        if(data && data.schedule_info_for_select_theme){
            setScheduleData(data.schedule_info_for_select_theme);
        }else{
            setScheduleData(null);
        }
    }, [data])

    useEffect(() => {
        let state: number = 0;

        if(selectedThemeList.length === 0){
            state = -1;
        }else if(isReadOnly){
            state = 2;
        }else{
            state = 1;
        }

        switch(state){
            case 1:
                setBtnNode(
                    <Button
                        size={"size_full"}
                        onClick={onSubmit}
                        purpose={"save"}
                    >
                        선택 완료
                    </Button>
                );
                break;
            case 2:
                setBtnNode(
                    <TextFlexWrap
                        classNameList={["g_text_wrap", "warning", "g_text", "size1"]}
                        text_list={["테마를 변경하고 싶으시다면 지점에 직접 문의하시거나 촬영하는 날 촬영 시작 전에 말씀해주세요"]}
                    />
                );
                break;
            case -1:
                setBtnNode(
                    <Button
                        size={"size_full"}
                        purpose={"disabled"}
                    >
                        테마를 선택해야할 촬영상품이 없습니다.
                    </Button>
                );
                break;
            default:
                setBtnNode(null);
                ;
        }
    }, [isReadOnly, selectedThemeList])

    const onSubmit = () => {
        const config: contractScheduleReservationConfigInterface = {
            schedule_id: "0",
            shoot_type: 0,
            photo_concept: [],
            photo_id: [],
        }

        if(themeDataRef.current){
            config.schedule_id = `${themeDataRef.current.schedule_id}`;
            config.shoot_type = themeDataRef.current.shoot_type;
        }

        let photo_id_list:string[] = [];
        let thema_id_list:string[] = [];

        // 선택이 전혀 안된 촬영 목록
        const non_selected_photo_index_list: number[] = [];

        const alert_msg: string[] = ["선택하신 테마를 확인해주세요.", `\n`];
        for(let index = 0 ; index < selectedThemeList.length ; index++){
            const theme = selectedThemeList[index];

            let theme_alert_string: string = `${theme.photo_name}: `;
            const theme_alert_msg: string[] = [];

            if(theme.selected_theme_list.length === 0){
                non_selected_photo_index_list.push(index);
            }

            for(const selected_theme of theme.selected_theme_list){
                photo_id_list.push(theme.photo_id);
                thema_id_list.push(`${selected_theme.thema_id}`);
                theme_alert_msg.push(selected_theme.thema_name);
            }
            theme_alert_string += theme_alert_msg.join(", ");
            alert_msg.push(theme_alert_string)
        }

        if(non_selected_photo_index_list.length > 0){
            if(!window.confirm("테마를 선택하지 않은 촬영이 존재합니다.\테마를 선택하지 않고 이대로 완료 하시겠습니까?")){
                return ;
            }
        }

        // 완성된 안내 문장을 출력한다.
        alert(alert_msg.join(`\n`));

        config.photo_id = [...photo_id_list];
        config.photo_concept = [...thema_id_list];

        const confirm_msg: string[] = ["촬영 컨셉 선택을 완료하시겠습니까?", `\n`, "선택이 완료된 후에 컨셉 변경을 원하실경우", "촬영당일 현장에서 직접 요청하셔야합니다."];
        if(!window.confirm(confirm_msg.join(`\n`))){
            return ;
        }

        selectedThemeUpdate(config);
    }

    const selectedThemeUpdate = (
        config: contractScheduleReservationConfigInterface
    ) => {
        if(common_context.activeRequestsManager && common_context.activeRequestsManager.status()){
            dev_console.error("다른 요청이 아직 완료되지 않은 상태에서 신규 요청을 시도함")
            return ;
        }

        setIsLoading(true);
        const contract_schedule_reservation_url =  "api/v3/customers/contract_schedule_reservation";
        if(common_context.activeRequestsManager){
            common_context.activeRequestsManager.add(contract_schedule_reservation_url);
        }
        callAxios.api({
            url: contract_schedule_reservation_url,
            method: "post",
            dataType: "json",
            data: config,
        })
        .then((response) => {
            dev_console.log(contract_schedule_reservation_url);
            dev_console.log(response);

            if(response.status === 200){
                sessionStorage.removeItem("schedule_info_for_select_theme");
                alert("테마가 선택되었습니다.");
                navigator("/reservationHome");
            }else{
                printStateMsg(response);
                alert("선택한 테마를 저장하는 과정에서 문제가 발생했습니다."); 
            }
        })
        .catch((error) => {
            dev_console.log(error);
            alert("선택한 테마를 저장요청하는 과정에서 문제가 발생했습니다.");
        })
        .finally(() => {
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.sub(contract_schedule_reservation_url);
            }
            setIsLoading(false);
        })
    }

    return (
        <main className={`${CSS.l_select_theme_main}`}>
            <div className="g_title">테마 선택</div>
            <div className={`${CSS.l_theme_main_contents}`}>
                <CardContainer
                    class_name_list={[CSS.l_card_outline, CSS.l_theme_left_wrap]}
                >
                    <ThemeListContainer 
                        isReadOnly={isReadOnly}
                        selectedThemeList={selectedThemeList}
                        setSelectedThemeList={setSelectedThemeList}
                    />
                </CardContainer>

                <div className={`${CSS.l_theme_right_wrap}`}>
                    <div className={CSS.l_theme_right_wrap__top}>
                        <DataInfo 
                            infoData={infoDataList}
                        />
                    </div>
                    <div className={CSS.l_theme_right_wrap__bottom}>
                        {btnNode}
                    </div>
                </div>

            </div>
            <LoopAnimationModal
                isModalOpen={isLoading}
            />
        </main>
    )

}

export default SelectTheme

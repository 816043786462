import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainButton from "./components/MainButton";
import CSS from "./static/css/navi.module.css";

const Navi = (
    {
        isOpenMenu, 
        setIsOpenMenu,
        navi_item_list,
    }:{
        isOpenMenu: boolean;
        setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
        navi_item_list: {
            name: string;
            img_src: string;
            activated_img_src: string;
            navigate: string;
            badge?: string;
            is_badge_on: boolean;
        }[] 
    }
) => {

    const [activatedIndex, setActivatedIndex] = useState<number>(0);

    const location = useLocation();

    const navigate = useNavigate();

    useEffect(()=>{

        let pathname = location.pathname;

        const result_index = navi_item_list.findIndex((item) => item.navigate === pathname);
        setActivatedIndex(result_index + 1);

    }, [location, navi_item_list])

    return (
        <nav
            className={
                isOpenMenu
                ?   CSS.l_navi
                :   `${CSS.l_navi} ${CSS.navi_close}`
            }
        >
            {
                navi_item_list.map((item, index) => {
                    return (
                        <MainButton
                            key={index}
                            name={item.name}
                            img_src={item.img_src}
                            activated_img_src={item.activated_img_src}
                            onClickFc={()=>{
                                navigate(item.navigate);
                                setIsOpenMenu(false);
                            }}
                            is_activated={activatedIndex === index + 1}
                            is_print_badge={item.is_badge_on}
                        />
                    )
                })
            }
        </nav>
    )
}

export default Navi;
import { queryParameterInterface } from "../../../../Common/interface/Common.interface";
import { setQueryParameter } from "../../../../Common/ts/commonTools";
import { themaBookPhotoListInterface } from "../interface/themeBook.interface";

export const setThemaBookPhotoListQueryParameter = ({selected_photo_product, theme_id, office_id,}:{selected_photo_product: themaBookPhotoListInterface, theme_id?: number, office_id?: number}) => {
    const query_parameter_list: queryParameterInterface[] = [];

    // 전체촬영의 경우, photo_id는 빈값어야하고, office_id는 빈값이어도 상관없으며, type은 3이어야 한다.
    query_parameter_list.push({
        key: "type",
        value: `${selected_photo_product.type || ""}`,
    });
    query_parameter_list.push({
        key: "office_id",
        value: `${selected_photo_product.office_id || office_id || ""}`,
    });
    query_parameter_list.push({
        key: "photo_id",
        value: `${selected_photo_product.photo_id || ""}`,
    });


    if(theme_id){

        query_parameter_list.push({
            key: "thema_id",
            value: `${theme_id}`,
        });

    }else{

        query_parameter_list.push({
            key: "thema_id",
            value: "",
        });

    }

    return setQueryParameter(query_parameter_list);
}
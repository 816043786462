import { createContext, useContext, useEffect, useRef, useState } from "react";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import ProductBlock from "./components/ProductBlock";
import { callAxios, dev_console, isAbleAccesser, isSuccess } from "../../Common/ts/commonTools";
import Modal from "../../Component/Elements/Modal/Modal";
import WideCard from "../../Component/Elements/WideCard/WideCard";
import { commonContext } from "../../App";
import TextFlexWrap from "../../Component/Elements/TextFlexWrap/TextFlexWrap";
import UploadInputBtnContainer from "./components/UploadInputBtnContainer";
import { scheduleProductInterface } from "./static/interface/photoSelect.interface";
import LoopAnimation from "../../Component/Elements/LoopAnimation/LoopAnimation";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import CSS from "./static/css/photoSelect.module.css";
import { isNeedToSeletItem } from "./static/ts/photoSelectTools";
import { defaultApiReaponseInterface } from "../../Common/interface/Common.interface";
import { themaBookDetailListInterface } from "../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { useLocation } from "react-router-dom";
import Flex from "../../Component/Elements/Flex/Flex";
import ProductBlockDetail from "./components/ProductBlockDetail";

interface photoSelectContextInterface{
    isUploading: boolean;
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    isOptionLoading: boolean;
    isOptionError: boolean;
    selectedProductOptionDataList: themaBookDetailListInterface[];
}

const photoSelectContextDefaultValue: photoSelectContextInterface = {
    isUploading: false,
    setIsUploading: () => {},
    isOptionLoading: false,
    isOptionError: false,
    selectedProductOptionDataList: [],
}

export const photoSelectContext = createContext<photoSelectContextInterface>(photoSelectContextDefaultValue);

const PhotoSelect = () => {

    // 데이터를 요청할 때 상태
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // 사진을 업로드할 때 상태
    const [isUploading, setIsUploading] = useState<boolean>(false);
    //  옵션데이터를 요청할 때 상태
    const [isOptionLoading, setIsOptionLoading] = useState<boolean>(false);
    const [isOptionError, setIsOptionError] = useState<boolean>(false);

    const [selectedProduct, setSelectedProduct] = useState<scheduleProductInterface | null>(null);

    const [productList, setProductList] = useState<scheduleProductInterface[]>([]);
    const [viewRange, setViewRange] = useState<number>(0);

    const [isUploadedImgModalOpen, setIsUploadedImgModalOpen] = useState<boolean>(false);

    const [printBadgeList, setPrintBadgeList] = useState<boolean[]>([]);

    const [mobileAnnouncement, setMobileAnnouncement] = useState<React.ReactNode | null>(null);

    const [selectedProductOptionDataList, setSelectedProductOptionDataList] = useState<themaBookDetailListInterface[]>([]);
    const [selectedOptionList, setSelectedOptionList] = useState<string[]>([]);

    const uploadInputRef = useRef<HTMLInputElement>(null);

    const common_context = useContext(commonContext);

    const location = useLocation();

    const productOnClickHandler = (item: scheduleProductInterface) => {
        if(isOptionLoading){
            return ;
        }

        setSelectedProduct(item)
    }

    useEffect(() => {

        isAbleAccesser(["C"]);
        
        if(common_context.updateScheduleProductData){
            common_context.updateScheduleProductData()
            .finally(() => {
                if(common_context.productList){
                    const product_list_data = common_context.productList;
                    const badge_point: boolean[] = [];
                    badge_point.push(false);
                    if(product_list_data.some((item) => isNeedToSeletItem(item))){
                        badge_point.push(product_list_data.some((item) => isNeedToSeletItem(item)));
                    }else if(product_list_data.filter((item) => item.final_select === "Y").length > 0){
                        badge_point.push(false);
                    }else{
                        badge_point.push(false);
                    }
                    badge_point.push(false);
                    setPrintBadgeList(badge_point);
                }

                const data = location.state as scheduleProductInterface;
                setSelectedProduct(data);
            })
        }
    }, [])

    useEffect(() => {
        if(common_context.productList){
            if(viewRange === 0){
                setProductList(common_context.productList);
            }else if(viewRange === 1){
                setProductList(common_context.productList.filter((item) => item.final_select === "N" && item.photo_select_pre_yn === 1));
            }else if(viewRange === 2){
                setProductList(common_context.productList.filter((item) => item.final_select === "Y"));
            }
        }else{
            setProductList([]);
        }
    }, [viewRange, common_context.productList])

    useEffect(() => {
        setSelectedOptionList([]);
        setSelectedProductOptionDataList([]);

        if(selectedProduct){
            // 선택된 제품의 옵션정보 호출
            setIsOptionLoading(true);
            setIsOptionError(false);
            const thema_book_detail_list_url = `api/v3/customers/thema_book_detail_list?type=1&office_id=${selectedProduct.office_id}&photo_id=${selectedProduct.detail_product_id}&thema_id=`;
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.add(thema_book_detail_list_url);
            }
            callAxios.api({
                url: thema_book_detail_list_url,
                method: "get",
            })
            .then((response) => {
                dev_console.log(thema_book_detail_list_url);
                dev_console.log(response);

                if(isSuccess(response)){
                    const row_response: defaultApiReaponseInterface<themaBookDetailListInterface[]> = response.data;
                    setSelectedProductOptionDataList(row_response.result)
                }else{
                    setIsOptionError(true);
                }
            })
            .catch((error) => {
                dev_console.error(error);
                setIsOptionError(true);
            })
            .finally(() => {
                setIsOptionLoading(false);
                if(common_context.activeRequestsManager){
                    common_context.activeRequestsManager.sub(thema_book_detail_list_url);
                }
            })

            if(selectedProduct.final_select === "N"){
                setMobileAnnouncement(
                    <TextFlexWrap 
                        classNameList={["g_text_wrap", "warning", "g_text", "size1"]}
                        text_list={["사진 선택은 PC환경에서 가능합니다."]}
                        text_list_justify_content={"center"}
                    />
                )
            }else{
                if(selectedProduct.staff_download_at.length > 0){
                    if(selectedProduct.goout === "Y"){
                        setMobileAnnouncement(
                            <TextFlexWrap 
                                classNameList={["g_text_wrap", "success", "g_text", "size1"]}
                                text_list={["출고가 완료되었습니다."]}
                                text_list_justify_content={"center"}
                            />
                        )
                    }else{
                        setMobileAnnouncement(
                            <TextFlexWrap 
                                classNameList={["g_text_wrap", "disabled", "g_text", "size1"]}
                                text_list={["상품 제작이 진행중입니다."]}
                                text_list_justify_content={"center"}
                            />
                        )
                    }
                }else{
                    setMobileAnnouncement(
                        <TextFlexWrap 
                            classNameList={["g_text_wrap", "warning", "g_text", "size1"]}
                            text_list={["사진 재선택은 PC환경에서 가능합니다."]}
                            text_list_justify_content={"center"}
                        />
                    )
                }
            }
        }else{
            setMobileAnnouncement(null);
        }
    }, [selectedProduct])

    return (
        <photoSelectContext.Provider 
            value={{
                isUploading,
                setIsUploading,
                isOptionLoading,
                isOptionError,
                selectedProductOptionDataList,
            }}
        >
            <div className={CSS.l_photo_select_main}>
                <div className="g_title">제품 제작 신청</div>
                
                <div className={CSS.l_contents}>
                    <div className={`g_card ${CSS.l_select_product_container}`}>
                        <TitleBtnWrap<number> 
                            valueState={viewRange}
                            setValueState={setViewRange}
                            value_list={[0, 1, 2]}
                            innerText_list={["전체", "제품 제작 신청", "신청 완료"]}
                            is_activated={true}
                            is_print_badge_list={printBadgeList}
                        />
                        {
                            isLoading
                            ?   <WideCard>
                                    <LoopAnimation />
                                </WideCard>
                            :   productList.length > 0
                                ?   <Flex>
                                        {
                                            productList.map((item, index) => {
                                                let is_print_badge = isNeedToSeletItem(item);

                                                return (
                                                    <ProductBlock 
                                                        key={index}

                                                        selectedProduct={item}
                                                        onClick={productOnClickHandler.bind(null, item)}

                                                        is_highlight={item.contract_product_id === selectedProduct?.contract_product_id}
                                                        is_hover_action_activate={true}
                                                        is_print_badge={is_print_badge}
                                                    />
                                                )
                                            })
                                        }
                                    </Flex>
                                :   <WideCard>
                                        제품 정보가 없습니다.
                                    </WideCard>
                        }
                    </div>
                    {
                        selectedProduct
                        &&  <div className={`g_display_none_object mobile flex ${CSS.l_product_info}`}>
                                <ProductBlockDetail
                                    selectedProduct={selectedProduct}

                                    is_highlight={false}
                                    is_hover_action_activate={false}
                                />                                
                                {
                                    (selectedProduct.detail && selectedProduct.detail.length > 0)
                                    &&   <UploadInputBtnContainer 
                                            selectedProduct={selectedProduct}
                                            uploadInputRef={uploadInputRef}
                                            isUploadedImgModalOpen={isUploadedImgModalOpen}
                                            setIsUploadedImgModalOpen={setIsUploadedImgModalOpen}
                                            selectedOptionList={selectedOptionList}
                                            setSelectedOptionList={setSelectedOptionList}
                                        />
                                }
                            </div>
                    }
                </div>
                
                {/* 모바일 환경에서 제품을 클릭했을 때 상세 제품 정보가 나오는 모달 */}
                {/* {
                    selectedProduct
                    &&  <Modal
                            className="g_display_none_object web"
                            isModalOpen={selectedProduct !== null}
                        >
                            <ProductBlockDetail 
                                selectedProduct={selectedProduct}
                                is_highlight={false}
                                is_hover_action_activate={false}
                            />                                   
                            {mobileAnnouncement}
                        </Modal>
                } */}
                {/* 이미지 업로드 동작이 진행될때 로딩 모달 */}
                <LoopAnimationModal
                    isModalOpen={isUploading}
                />       
            </div>
        </photoSelectContext.Provider>
    )
}

export default PhotoSelect;
import { useState } from "react";
import Button from "../../Button/Button"
import Text from "../../Text/Text"
import RadioBtnWrap from "../../RadioBtnWrap/RadioBtnWrap";
import Input from "../../Input/Input";
import CSS from "../static/css/SettingImgWidth.module.css";
import CardContainer from "../../CardContainer/CardContainer";

const SettingImgWidth = (
    {
        imageHandler,
        closeFC,
    }:{
        imageHandler: (width: string) => Promise<void>;
        closeFC: () => void;
    }
) => {
    const [radioValue, setRadioValue] = useState<string>("4");
    const [customWidthUnit, setCustomWidthUnit] = useState<string>("px");
    const [customWidth, setCustomWidth] = useState<number>(400);

    const setWdithHandler = () => {
        const radio_value_number = parseInt(radioValue);

        let width_value: string = "";
        switch(radio_value_number){
            case 1:
                width_value = "200px";
                break;
            case 2:
                width_value = "400px";
                break;
            case 3:
                width_value = "600px";
                break;
            case 4:
                width_value = "100%";
                break;
            case 5:
                width_value = `${customWidth}${customWidthUnit}`;
                break;
            default:
                ;
        }

        imageHandler(width_value);
    }

    const uploadHandler = () => {
        setWdithHandler()
    }

    const closeBtnHandler = () => {
        closeFC();
    }

    return(
        <CardContainer class_name_list={[CSS.l_setting_img_width_main]}>
            <div className={CSS.l_setting_img_width__input_container}>
                <div className={CSS.l_setting_img_width__input_container__input_block}>
                    <Text>
                        크기
                    </Text>
                    <RadioBtnWrap 
                        item_list={[
                            {
                                label: "소(200px)",
                                value: "1",
                            },{
                                label: "중(400px)",
                                value: "2",
                            },{
                                label: "대(600px)",
                                value: "3",
                            },{
                                label: "전체(100%)",
                                value: "4",
                            },{
                                label: "사용자 정의",
                                value: "5",
                            }
                        ]}
                        value={`${radioValue}`}
                        name={"width_size_input"}
                        onChange={(event) => {
                            setRadioValue(event.target.value);
                        }}
                    />
                </div>
                <div className={CSS.l_setting_img_width__input_container__input_block}>
                    <Text>
                        사용자 정의
                    </Text>
                    <RadioBtnWrap 
                        item_list={[
                            {
                                label: "px(최대 1014)",
                                value: "px",
                            },{
                                label: "%(최대 100)",
                                value: "%",
                            }
                        ]}
                        value={customWidthUnit}
                        name={"width_size_unit"}
                        onChange={(event) => {
                            setCustomWidthUnit(event.target.value);

                            const unit = event.target.value;
                            if(unit === "px"){
                                if (customWidth < 0) {
                                    setCustomWidth(0);
                                } else if (customWidth > 1014) {
                                    setCustomWidth(1014);
                                }                    
                            }else{
                                if (customWidth < 0) {
                                    setCustomWidth(0);
                                } else if (customWidth > 100) {
                                    setCustomWidth(100);
                                } 
                            }
                        }}
                        is_disabled={radioValue !== "5"}
                    />
                    <Input 
                        type="number"
                        value={customWidth}
                        onChange={(event) => {
                            let value = parseInt(event.target.value);
                            if(customWidthUnit === "px"){
                                if (value < 0) {
                                    value = 0;
                                } else if (value > 1014) {
                                    value = 1014;
                                }                    
                            }else{
                                if (value < 0) {
                                    value = 0;
                                } else if (value > 100) {
                                    value = 100;
                                } 
                            }
                            setCustomWidth(value); // 최대값을 초과하면 600으로 설정
                        }}
                        disabled={radioValue !== "5"}
                        min={0}
                        max={600}
                    />
                </div>
            </div>
            <div className={CSS.l_setting_img_width__btn_container}>
                <Button
                    size={"size1"}
                    onClick={uploadHandler}
                    purpose={"save"}
                >
                    사진 업로드
                </Button>
                <Button
                    size={"size1"}
                    onClick={() => {
                        closeBtnHandler();
                    }}
                    purpose={"close"}
                >
                    닫기
                </Button>
            </div>
        </CardContainer>
    )
}

export default SettingImgWidth;
import { useNavigate, useSearchParams } from "react-router-dom";
import TextEditerQuill from "../../Component/Elements/TextEditer/TextEditerQuill";
import CSS from "./static/css/themeBookDetailPage.module.css";
import { themaBookDetailListInterface } from "./static/interface/themeBook.interface";
import { useContext, useEffect, useState } from "react";
import { commonContext } from "../../App";
import ThemeCardBlock from "./components/ThemeCardBlock";
import { callAxios, dev_console, isAbleAccesser, isSuccess, printStateMsg, setQueryParameter } from "../../Common/ts/commonTools";
import { getCookie } from "../../Common/ts/appTools";
import { defaultApiReaponseInterface } from "../../Common/interface/Common.interface";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import Button from "../../Component/Elements/Button/Button";

const ThemeBookDetailPage = () => {
    
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [themeInfo, setThemeInfo] = useState<themaBookDetailListInterface | null>(null);

    const common_context = useContext(commonContext);
    
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const type: string = searchParams.get("type") || "";
    const office_id: string = searchParams.get("office_id") || "";
    const photo_id: string = searchParams.get("photo_id") || "";
    const thema_id: string = searchParams.get("thema_id") || "";

    const user_type_session = getCookie("user_type");

    useEffect(() => {
        isAbleAccesser(["C", "E"]);

        // photo_id는 값이 없는 경우도 있으므로 검증하지 않는다.
        if(!type || !office_id || !thema_id){
            navigate("/themeBook");
        }

        const thema_book_detail_list_endpoint:string = "api/v3/customers/thema_book_detail_list";
        const query_parameter_string = setQueryParameter([
            {
                key: "type",
                value: type,
            },{
                key: "office_id",
                value: office_id,
            },{
                key: "photo_id",
                value: photo_id,
            },{
                key: "thema_id",
                value: thema_id,
            }
        ])

        setIsLoading(true);
        const thema_book_detail_list_url = thema_book_detail_list_endpoint.concat("?", query_parameter_string);
        if(common_context.activeRequestsManager){
            common_context.activeRequestsManager.add(thema_book_detail_list_url);
        }
        callAxios.api({
            url: thema_book_detail_list_url,
            method: "get",
        })
        .then((response) => {
            dev_console.log(thema_book_detail_list_url);
            dev_console.log(response);

            if(isSuccess(response)){
                const row_response: defaultApiReaponseInterface<themaBookDetailListInterface[]> = response.data;
                if(row_response.result && row_response.result.length > 0){
                    setThemeInfo(row_response.result[0]);
                }else{
                    alert("테마 정보가 없습니다.");
                    navigate("/themeBook");
                }
            }else{
                alert("테마 정보를 불러오는데 실패했습니다.");
                navigate("/themeBook");
            }
        })
        .catch(() => {
            alert("테마 정보를 불러오는 과정에서 문제가 발생했습니다.");
            navigate("/themeBook");
        })
        .finally(() => {
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.sub(thema_book_detail_list_url);
            }
            setIsLoading(false);
        })

    }, [])

    // 편집
    const handleOnEdit = () => {
        if(themeInfo){
            sessionStorage.setItem("selectedSubTheme", JSON.stringify(themeInfo));
            navigate("/ThemeBookManagement");
        }
    };

    // 삭제
    const handleOnDelete = () => {
        if(themeInfo){
            if(window.confirm("게시글이 삭제됩니다. 삭제를 진행하시겠습니까?")){
                const config = {
                    type: themeInfo.type,
                    content: "",
                    thema_id: themeInfo.thema_id
                }
        
                setIsLoading(true);
                const thema_book_content_update = "api/v3/customers/thema-book-content-update";
                if(common_context.activeRequestsManager){
                    common_context.activeRequestsManager.add(thema_book_content_update);
                }
                callAxios.api({
                    url: thema_book_content_update,
                    method: "post",
                    dataType: "json",
                    data: config,
                })
                .then((response) => {
                    dev_console.log(thema_book_content_update);
                    dev_console.log(response);
                    
                    if(isSuccess(response)){
                        navigate("/themeBook");
                    }else{
                        printStateMsg(response);
                        alert("게시글 삭제에 실패했습니다.")
                    }
                })
                .catch((error) => {
                    dev_console.error(error);
                    alert("게시글 삭제에 실패했습니다.")
                })
                .finally(() => {
                    if(common_context.activeRequestsManager){
                        common_context.activeRequestsManager.sub(thema_book_content_update);
                    }
                    setIsLoading(false);
                })
            }
        }
    };
    
    if(themeInfo){
        return (
            <div className={CSS.l_theme_book_detail_page_main}>
                <div className={CSS.l_left_wrap}>
                    <TextEditerQuill 
                        read_only={true}
                        content={themeInfo.content || ""}
                    />
                </div>
                <div className={`${CSS.l_right_wrap} ${CSS.size} ${CSS.big}`}>          
                    <div className={CSS.l_info_container}>
                        <ThemeCardBlock
                            img_src={themeInfo.thema_thumbnail.middle_url}
                            theme_name={themeInfo.photo_name}
                            title={themeInfo.thema_name}
                            office_name={themeInfo.office_name}
                            size={"big"}
                        />
                        {
                            user_type_session === "E"
                            &&  <div className="g_btn_wrap">
                                    <Button 
                                        onClick={() => {
                                            handleOnEdit();
                                        }}
                                    >
                                        수정
                                    </Button>
                                    <Button 
                                        purpose={"delete"}
                                        onClick={() => {
                                            handleOnDelete();
                                        }}
                                    >
                                        삭제
                                    </Button>
                                </div>
                        }
                    </div>
                </div>
                <LoopAnimationModal
                    isModalOpen={isLoading}
                >
                </LoopAnimationModal>
            </div>
        )
    }

    return (<></>)
}

export default ThemeBookDetailPage;
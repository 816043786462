import { callAxios, dev_console } from "../../../Common/ts/commonTools";
import Badge from "../../../Component/Elements/Badge/Badge";
import Button from "../../../Component/Elements/Button/Button";

const ImageZipDownload = (
    {
        download_type,
        file_name,
        s3_url,
        isDownloading,
        setIsDownloading,
        is_print_badge
    }:{
        download_type: number;
        file_name: string;
        s3_url: string;
        isDownloading: boolean;
        setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>;
        is_print_badge: boolean;
    }
) => {

    const isKakaoBrowser = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes('kakaotalk');
    };

    const handleDownload = () => {
        if(isKakaoBrowser()){
            alert("카카오톡 환경에서는 다운로드 진행이 어렵습니다.\n다른 브라우저(ex. 구글 크롬)이나 PC환경에서 진행해주세요.");            
            return ;
        }
        
        if(!window.confirm("사진들을 다운로드 하시겠습니까?")){
            return ;
        }

        setIsDownloading(true);

        const config = {
            s3_url: s3_url,
            download_type: download_type,/* 1: 원본, 편집본 등 파스텔에서 올린 img, 2: 사용자 select 파일, 3: 사용자 후기 파일*/
        }

        const photo_download_url = "api/v3/customers/photo-download";
        callAxios.api({
            url: photo_download_url,
            method: "post",
            dataType: "json",
            data: config,
            responseType: "blob",
            timeOut: 180000,
        })
        .then((response) => {
            dev_console.log(photo_download_url);
            dev_console.log(config);
            dev_console.log(response);

            // Blob을 URL로 변환
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            // 파일 이름을 지정
            link.setAttribute('download', `${file_name}.zip`);

            // 링크를 클릭하여 다운로드 트리거
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => {
            dev_console.error(error);
            alert("다운로드에 실패했습니다.")
        })
        .finally(() => {
            setIsDownloading(false);
        })
    }

    if(isDownloading){
        return (
            <Button
                purpose={"disabled"}
                size={"size_full"}
            >
                다운로드 진행중입니다. 잠시만 기다려주세요.
            </Button>
        )
    }else{
        return(
            <Button
                onClick={handleDownload}
                disabled={isDownloading}
                size={"size_full"}
                purpose={"dev"}
            >
                <Badge
                    is_print_badge={is_print_badge}
                >
                    다운로드
                </Badge>
            </Button>
        )
    }

}

export default ImageZipDownload;
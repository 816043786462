import { useEffect, useRef, useState } from "react";
import Hammer from "hammerjs";

import CSS from "./static/css/PhotoListSwipeLine.module.css"

import left_move_arrow from "../../../Common/image/button/left_move_arrow.svg";
import right_move_arrow from "../../../Common/image/button/right_move_arrow.svg";

const PhotoListSwipeLine = (
    {
        children_list,
        selectedIndex,
        setSelectedIndex,
        arrow_size,
    }:{
        children_list: React.ReactNode[];
        selectedIndex: number,
        setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
        arrow_size?: "small" | "middel" | "big";
    }
) => {    
    const [isOverFlow, setIsOverFlow] = useState<boolean>(false);
    const [arrowClassName, setArrowClassName] = useState<string>("");

    const translateXValueRef = useRef<number>(0);

    const mainRef = useRef<HTMLDivElement>(null); 
    const viewRef = useRef<HTMLDivElement>(null);
    const dataRef = useRef<HTMLDivElement>(null);

    const intervalId = useRef<number | null>(null);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "ArrowLeft") {
                beforeArrowBtnHandler();
            } else if (event.key === "ArrowRight") {
                nextArrowBtnHandler();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const viewRef_current = viewRef.current;
        const dataRef_current = dataRef.current;
        if(viewRef_current && dataRef_current && isOverFlow){
            const hammer = new Hammer(viewRef_current);

            let new_translateX = translateXValueRef.current;
            hammer.on("pan", (event) => {
                new_translateX = translateXValueRef.current + event.deltaX;

                const max_translateX = 0;
                const min_translateX = viewRef_current.offsetWidth - dataRef_current.scrollWidth;

                if(new_translateX > max_translateX){
                    new_translateX = max_translateX;
                }else if(new_translateX < min_translateX){
                    new_translateX = min_translateX;
                }
                
                dataRef_current.style.transform = `translateX(${new_translateX}px)`;
            });

            hammer.on("panend", () => {
                translateXValueRef.current = new_translateX;
            });

            return () => {
                hammer.destroy(); // Clean up Hammer instance on component unmount
            };
        }
    }, [isOverFlow])

    useEffect(() => {
        scrollToIndex(selectedIndex)
    }, [selectedIndex])

    useEffect(() => {
        if(dataRef.current && viewRef.current){
            const item_line_height = dataRef.current.offsetHeight
            viewRef.current.style.height = `${item_line_height + 40}px`;

            const isOverflow = dataRef.current.scrollWidth > viewRef.current.offsetWidth;
            setIsOverFlow(isOverflow);
        }
    }, [children_list])

    useEffect(() => {
        const arrow_class_name_list: string[] = [CSS.l_arrow_img, CSS.size, CSS.web];

        if(arrow_size){
            arrow_class_name_list.push(CSS[arrow_size]);
        }else{
            arrow_class_name_list.push(CSS.big);
        }

        setArrowClassName(arrow_class_name_list.join(" "))
    }, [arrow_size])

    const scrollToIndex = (index: number) => {
        const dataRef_current = dataRef.current;
        const viewRef_current = viewRef.current;

        if(dataRef_current && viewRef_current && index >= 0 && dataRef_current.children.length > index && isOverFlow){
            const selected_item = dataRef_current.children[index] as HTMLElement;
            const selected_item_offsetLeft = selected_item.offsetLeft;

            const view_width = viewRef_current.offsetWidth;
            const selected_item_width = selected_item.offsetWidth;

            const new_translateX =
                -(selected_item_offsetLeft - (view_width / 2 - selected_item_width / 2));

            const max_translateX = 0;
            const min_translateX = view_width - dataRef_current.scrollWidth;

            if (new_translateX > max_translateX) {
                translateXValueRef.current = max_translateX;
            } else if (new_translateX < min_translateX) {
                translateXValueRef.current = min_translateX;
            } else {
                translateXValueRef.current = new_translateX;
            }

            dataRef_current.style.transform = `translateX(${translateXValueRef.current}px)`;
        }
    }

    const beforeArrowBtnHandler = () => {
        setSelectedIndex((index) => {
            if (index > 0) {
                return index - 1;
            } else {
                return 0; // 이미 첫 번째 항목일 때는 0으로 유지
            }
        });
    }

    const beforeMouseHoldStartHandler = () => {
        intervalId.current = window.setInterval(() => {
            beforeArrowBtnHandler();
        }, 100);
    }

    const nextArrowBtnHandler = () => {
        if (children_list.length === 0) {
            // children_list가 비어있으면 아무 작업도 하지 않음
            return;
        }
    
        setSelectedIndex((index) => {
            // selectedIndex가 범위를 초과하지 않도록 보장
            const nextIndex = index + 1;
            if (nextIndex < children_list.length) {
                return nextIndex;
            } else {
                return children_list.length - 1; // 마지막 인덱스에서 멈춤
            }
        });
    }

    const nextMouseHoldStartHandler = () => {
        intervalId.current = window.setInterval(() => {
            nextArrowBtnHandler();
        }, 100);
    }

    const mouseHoldEndHandler = () => {
        if (intervalId.current !== null) {
            clearInterval(intervalId.current); // 타이머 해제
            intervalId.current = null; // intervalId 초기화
        }
    }

    return (
        <div 
            ref={mainRef}
            className={CSS.l_swipe_line_main}
        >
            {
                isOverFlow
                &&  <div
                        className={`${CSS.l_move_btn_container} ${CSS.left}`}
                        onClick={beforeArrowBtnHandler}
                        onMouseDown={beforeMouseHoldStartHandler}
                        onMouseUp={mouseHoldEndHandler}
                        onMouseLeave={mouseHoldEndHandler}
                    >
                        <img
                            className={arrowClassName}
                            src={left_move_arrow}
                        />
                    </div>
            }
            <div 
                ref={viewRef}
                className={CSS.l_swipe_view}
            >
                <div
                    ref={dataRef}
                    className={CSS.l_swipe_data}
                >
                    {
                        children_list.map((item, index) => {
                            return(
                                <div key={index} className={CSS.l_swipe_line__item_container}>
                                    {item}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                isOverFlow
                &&  <div
                        className={`${CSS.l_move_btn_container} ${CSS.right}`}
                        onClick={nextArrowBtnHandler}
                        onMouseDown={nextMouseHoldStartHandler}
                        onMouseUp={mouseHoldEndHandler}
                        onMouseLeave={mouseHoldEndHandler}
                    >
                        <img 
                            className={arrowClassName}
                            src={right_move_arrow}
                        />
                    </div>
            }
        </div>
    )
}

export default PhotoListSwipeLine
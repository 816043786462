import { useEffect, useState } from "react";
import { selectedThemeListInterface, themaInterface } from "../../static/interface/selectTheme.interface";
import WideCard from "../../../../Component/Elements/WideCard/WideCard";
import Grid from "../../../../Component/Elements/Grid/Grid";
import ThemeItemBlock from "./ThemeItemBlock";
import CSS from "../../static/css/ThemeList.module.css";
import Badge from "../../../../Component/Elements/Badge/Badge";
import Text from "../../../../Component/Elements/Text/Text";
import ThemaDetailPageModal from "../../../../PublicPage/ThemeBook/components/ThemaDetailPageModal";
import Flex from "../../../../Component/Elements/Flex/Flex";

const ThemeList = (
    {
        isReadOnly,
        selectedTheme,
        setSelectedThemeList,
    }:{
        isReadOnly: boolean;
        selectedTheme: selectedThemeListInterface;
        setSelectedThemeList: React.Dispatch<React.SetStateAction<selectedThemeListInterface[]>>;
    }
) => {

    const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
    const [detailTheme, setDetailTheme] = useState<themaInterface | null>(null);
    const [themeListNode, setThemeListNode] = useState<React.ReactNode | null>(null);
    const [themeMax, setThemeMax] = useState<number>(0);
    const [label, setLabel] = useState<string>("");

    const detailModalOpenHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item:themaInterface) => {
        event.stopPropagation();
        setDetailTheme(item);
        setIsDetailModalOpen(true);
    }

    const detailModalCloseHandler = () => {
        setIsDetailModalOpen(false);
    }

    const selectThemeHander = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, selected_item: themaInterface ) => {
        event.stopPropagation();

        if(isReadOnly){
            return ;
        }

        if(themeMax <= 0){
            alert(`선택할 수 있는 테마의 수가 정해지지 않았습니다.\n계약한 지점으로 문의해주세요.`);
            return ;
        }

        let selected_theme_list = [...selectedTheme.selected_theme_list];

        if(selected_theme_list.some((selected_theme_item) => selected_theme_item.thema_id === selected_item.thema_id)){
            selected_theme_list = selected_theme_list.filter((selected_theme_item) => selected_theme_item.thema_id !== selected_item.thema_id);
        }else{            
            if(selected_theme_list.length >= themeMax){
                alert(`${selectedTheme.photo_name}는 테마를 최대 ${themeMax}개 선택할 수 있습니다.(현재 ${selected_theme_list.length}개)`);
                return ;
            }else{
                selected_theme_list.push(selected_item);
            }
        }

        setSelectedThemeList((selected_theme_item) => 
            selected_theme_item.map((map_item) => {
                if(map_item.photo_id === selectedTheme.photo_id){
                    return {
                        ...map_item,
                        selected_theme_list: selected_theme_list,
                    }
                }else{
                    return map_item;
                }
            })
        )
    }

    useEffect(() => {

        let label_text:string = selectedTheme.photo_name;
        const theme_max = parseInt(selectedTheme.theme_num);

        if(isNaN(theme_max)){
            setThemeMax(0);
        }else{
            setThemeMax(theme_max);
        }

        let state: number = 0;
        if(selectedTheme.list && selectedTheme.list.length > 0){
            state = 1;
        }else{
            state = 2;
        }

        if(isReadOnly){
            if(state === 2){
                state = 3;
            }
        }else{
            label_text += ` (${selectedTheme.selected_theme_list.length} / ${themeMax})`
        }

        setLabel(label_text);

        switch(state){
            case 1:
                setThemeListNode(
                    <div className={CSS.l_theme_list__grid_container}>
                        <Flex>
                            {
                                selectedTheme.list.map((item, index) => {

                                    const selected_theme_list: themaInterface[] = [...selectedTheme.selected_theme_list];

                                    const mainClickHander = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                        selectThemeHander(event, item);
                                    }

                                    const iconButtonOnCheck = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                        detailModalOpenHandler(event, item);
                                    }

                                    let is_checked: boolean = false;
                                    if(selected_theme_list.some((seleted_theme_item) => seleted_theme_item.thema_id === item.thema_id)){
                                        is_checked = true;
                                    }

                                    let is_have_content: boolean = false;
                                    if(item.content.length > 0){
                                        is_have_content = true
                                    }

                                    if(isReadOnly){
                                        return(
                                            <ThemeItemBlock
                                                key={index}
                                                img_src={item.thema_thumbnail.middle_url}
                                                theme_name={item.thema_name}
                                                is_checked={true}
                                                iconButtonOnCheck={is_have_content ? iconButtonOnCheck : undefined}
                                                size={"small"}
                                            />
                                        )
                                    }else{
                                        return(
                                            <ThemeItemBlock
                                                key={index}
                                                img_src={item.thema_thumbnail.middle_url}
                                                theme_name={item.thema_name}
                                                is_checked={is_checked}
                                                iconButtonOnCheck={is_have_content ? iconButtonOnCheck : undefined}
                                                onClickMain={mainClickHander}
                                                size={"small"}
                                            />
                                        )
                                    }

                                })
                            }
                    </Flex>
                </div>
                )

                break;
            case 2:
                setThemeListNode(
                    <div className={CSS.l_theme_list__wide_card_container}>
                        <WideCard>
                            선택가능한 테마 정보가 없습니다. 지점으로 문의해주세요.
                        </WideCard>
                    </div>
                )
                break;
            case 3:
                setThemeListNode(
                    <div className={CSS.l_theme_list__wide_card_container}>
                        <WideCard>
                            선택된 테마 정보가 없습니다.
                        </WideCard>
                    </div>
                )
                break;
            default :
                ;
        }
    }, [selectedTheme, isReadOnly, themeMax])

    return (
        <div className={CSS.l_theme_list_main}>
            <div className={CSS.l_expandable_component_main}>
                <div className={CSS.l_button_container}>
                    <Badge
                        is_print_badge={!isReadOnly && selectedTheme.selected_theme_list.length === 0}
                    >
                        <Text
                            size={"size6"}
                        >
                            {label}
                        </Text>
                    </Badge>
                </div>
                <div 
                    className={CSS.l_expanded_container}
                >
                    {themeListNode}
                </div>
            </div>

            <ThemaDetailPageModal
                isModalOpen={isDetailModalOpen}
                modalCloseHandler={detailModalCloseHandler}
                content={detailTheme?.content || ""}
                title={detailTheme?.thema_name || ""}
                branch_name={detailTheme?.office_name || ""}
                photo_name={detailTheme?.photo_name || ""}
            />
        </div>
    )
}

export default ThemeList;
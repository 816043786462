import { useState } from "react";
import CSS from "./static/css/DragAndDrop.module.css";
import WideCard from "../WideCard/WideCard";

const DragAndDrop = (
    {
        onDropHandler,
        children,
        innerText,
    }:{
        onDropHandler?: (event: React.DragEvent<HTMLDivElement>) => void;
        children?: React.ReactNode;
        innerText?: string;
    }
) => {

    const [totalClassName, setTotalClassName] = useState<string>(CSS.l_drag_and_drop_main);

    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const class_name_list: string[] = [CSS.l_drag_and_drop_main, CSS.l_drag_and_drop__on_drag_enter];
        setTotalClassName(class_name_list.join(" "));
    }
    
    const onDrageLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const class_name_list: string[] = [CSS.l_drag_and_drop_main];
        setTotalClassName(class_name_list.join(" "));
    }

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if(onDropHandler){
            onDropHandler(event);
        }

        const class_name_list: string[] = [CSS.l_drag_and_drop_main];
        setTotalClassName(class_name_list.join(" "));
    }

    return(
        <div 
            className={totalClassName}
            onDragEnter={onDragEnter}
            onDragLeave={onDrageLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            {
                children
                ?   children
                :   <WideCard>
                        <div>
                            {innerText}
                        </div>
                    </WideCard>
            }
        </div>
    )
}

export default DragAndDrop;
import { useContext, useEffect, useState } from "react";
import Button from "../../../Component/Elements/Button/Button"
import { commonContext } from "../../../App";
import { requestEventApplicationHandler } from "../static/ts/PhotoListBlock.tools";
import useEventApplication from "../hooks/useEventApplication.hook";
import { joinedEventType } from "../static/interface/PhotoList.interface";
import { btnSizeType } from "../../../Component/Elements/Button/static/interface/Button.interface";

const EventApplication = (
    {
        schedule_id,
        upload_date,
        joined_event,
        size
    }:{
        schedule_id: string;
        upload_date: string;
        joined_event: joinedEventType;
        size?: btnSizeType;
    }
) => {

    const {
        state,
        setUploadDate,
        setJoinedEvent,
    } = useEventApplication({        
        upload_date: upload_date,
        joined_event: joined_event,
    });
    
    const [eventApplicationBtnNode, setEventApplicationBtnNode] = useState<React.ReactNode | null>(null);

    const common_context = useContext(commonContext);

    useEffect(() => {
        setUploadDate(upload_date);
        setJoinedEvent(joined_event);
    }, [upload_date, joined_event])

    const eventApplicationBtnHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if(schedule_id){
            requestEventApplicationHandler(event, schedule_id);
        }
    }


    useEffect(() => {
        switch(state.state){
            case 1:
                setEventApplicationBtnNode(
                    <Button
                        onClick={eventApplicationBtnHandler}
                        size={size || "size_full"}
                        purpose={"save"}
                    >
                        후기 이벤트 신청
                    </Button>
                );
                break;
            case -1:
                setEventApplicationBtnNode(
                    <Button
                        size={size || "size_full"}
                        purpose={"disabled"}
                    >
                        후기 이벤트 신청 완료
                    </Button>
                );
                break ;
            default:
                setEventApplicationBtnNode(null);
                break;
        }
    }, [state])

    return(
        <>
            {eventApplicationBtnNode}
        </>
    )
}

export default EventApplication;
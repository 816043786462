import AlbumInput from "./AlbumInput";
import CSS from "../static/css/albumProductInputContainer.module.css";
import { useContext } from "react";
import InputTitle from "./InputTitle";
import { uploadInputBtnContainerContext } from "./UploadInputBtnContainer";

const AlbumProductInputContainer = (
    {
        disabled,
    }:{
        disabled?: boolean;
    }
) => {

    /**
     * 영문이름
     * 생년월일
     * 키
     * 몸무게
     * 태어난시간
     */
    
    const upload_input_btn_container_context = useContext(uploadInputBtnContainerContext);

    const handleBabyName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = event.target.value;

        // const koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        // if(koreanPattern.test(input_value)){
        //     alert("한글은 입력할 수 없습니다.");
        // }else{
        //     upload_input_btn_container_context.setAlbumData((data) => {
        //         return({
        //             ...data,
        //             baby_name: input_value,
        //         })
        //     });
        // }

        upload_input_btn_container_context.setAlbumData((data) => {
            return({
                ...data,
                baby_name: input_value,
            })
        });

    }

    const handleBabyBirth = (event: React.ChangeEvent<HTMLInputElement>) => {
        upload_input_btn_container_context.setAlbumData((data) => {
            return({
                ...data,
                baby_birth: event.target.value,
            })
        });
    }

    const handleTall = (event: React.ChangeEvent<HTMLInputElement>) => {
        upload_input_btn_container_context.setAlbumData((data) => {
            return({
                ...data,
                tall: event.target.value,
            })
        });
    }

    const handleWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
        upload_input_btn_container_context.setAlbumData((data) => {
            return({
                ...data,
                weight: event.target.value,
            })
        });
    }

    const handleBabyBirthTime = (event: React.ChangeEvent<HTMLInputElement>) => {

        upload_input_btn_container_context.setAlbumData((data) => {
            return({
                ...data,
                baby_birth_time: event.target.value,
            })
        });
    }

    return (
        <div className={CSS.l_album_product_input_container_main}>
            <InputTitle 
                title="앨범 입력 정보"
                sub_title="(아이정보를 입력하지 않을경우 빈 값으로 사용됩니다.)"
            />
            <div className={CSS.l_album_product_input_container__input_container}>
                <AlbumInput
                    label="아이 이름(영문)"
                    value={upload_input_btn_container_context.albumData.baby_name}
                    onChange={handleBabyName}
                    disabled={disabled}
                />
                <AlbumInput 
                    label="아이 생일"
                    value={upload_input_btn_container_context.albumData.baby_birth}
                    onChange={handleBabyBirth}
                    type={"date"}
                    disabled={disabled}
                />
                <AlbumInput 
                    label="키(cm)"
                    value={upload_input_btn_container_context.albumData.tall}
                    onChange={handleTall}
                    type={"number"}
                    disabled={disabled}
                    min={0}
                />
                <AlbumInput 
                    label="몸무게(kg)"
                    value={upload_input_btn_container_context.albumData.weight}
                    onChange={handleWeight}
                    type={"number"}
                    disabled={disabled}
                    min={0}
                />
                <AlbumInput 
                    label="출생시간"
                    value={upload_input_btn_container_context.albumData.baby_birth_time}
                    onChange={handleBabyBirthTime}
                    type={"time"}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default AlbumProductInputContainer;
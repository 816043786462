import { useEffect, useState } from "react";
import MediaCard from "../../../../Component/Elements/MediaCard/MediaCard";
import CSS from "../../static/css/themeItemBlock.module.css"
import IconButtonCheck from "../../../../Component/Elements/Icon/IconButtonCheck/IconButtonCheck";
import IconButtonCheckCheckMark from "../../../../Component/Elements/Icon/IconButtonCheck/IconButtonCheckCheckMark";
import select_check_mark from "../../../../Common/image/icon/select check mark@2x.png";
import Text from "../../../../Component/Elements/Text/Text";

const ThemeItemBlock = (
    {
        img_src,
        theme_name,
        is_checked,
        iconButtonOnCheck,
        onClickMain,
        size,
    }:{
        img_src: string;
        theme_name: string;
        is_checked: boolean;
        iconButtonOnCheck?: React.MouseEventHandler<HTMLDivElement>;
        onClickMain?: React.MouseEventHandler<HTMLDivElement>;
        size?: "small" | "middle" | "big" | "full" | "auto";
    }
) => {

    const [textClassName, setTextClassName] = useState<string>("");

    useEffect(() => {
        const text_class_name_list: string[] = ["g_text", "bold"];

        if(size && size === "small"){
            text_class_name_list.push("size1");
        }else{
            text_class_name_list.push("size6");
        }

        text_class_name_list.push(CSS.l_theme_info);

        setTextClassName(text_class_name_list.join(" "));
    }, [size])

return (
    <div className={CSS.l_theme_item_block_main}>
        {/* 선택버튼 */}
        {
            is_checked
            &&  <div className={CSS.l_theme_item_block__select_icon}>
                    <IconButtonCheckCheckMark 
                        src={select_check_mark}
                        onClick={onClickMain}
                        size={"small"}
                    />
                </div>
        }
        <MediaCard
            src={img_src}
            onClick={onClickMain}
            size={size}
            is_highlight={is_checked}
            is_hover_action_activate={true}
        >
            <div 
                className={textClassName}
                onClick={onClickMain}
            >
                {theme_name}
            </div>

            {/* 상세보기 페이지 버튼 */}
            {
                iconButtonOnCheck
                &&  <div 
                        className={`${CSS.l_check_icon_container}`}
                        onClick={iconButtonOnCheck}
                    >
                        <Text
                            size={"size1"}
                        >
                            상세보기
                        </Text>
                    </div>
            }
        </MediaCard>
    </div>
    )
}

export default ThemeItemBlock;
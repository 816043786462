import { flagInterface } from "../interface/confirmEvent.Interface";
import { customerConfirmedType, managerConfirmedType } from "../interface/PhotoList.interface";

/**
 * order 주체
 * C : 고객
 * M : 매니저
 */

/**
 * result 처리상태
 * Y: 승인
 * N: 거절
 * A: 자동승인상태
 */

export const isCanConfrom = (
    {
        customer_confirmed,
        manager_confirmed
    }:{
        customer_confirmed: customerConfirmedType;
        manager_confirmed: managerConfirmedType;
    }
) => {
    let config: flagInterface = {
        flag: false,
        order: "",
        result: "",
    };

    if(manager_confirmed === "N"){
        if(customer_confirmed === "1"){
            config.flag = true;
            config.order = "C";
            config.result = "";
        }else{
            config.flag = false;
            config.order = "C";

            if(customer_confirmed === "2"){
                config.result = "Y";
            }else if(customer_confirmed === "3"){
                config.result = "A";
            }else if(customer_confirmed === "4"){
                config.result = "N";
            }
        }
    }else if(manager_confirmed === "Y"){
        config.flag = false;
        config.order = "M"
        config.result = "Y";
    }else if(manager_confirmed === "C"){
        config.flag = false;
        config.order = "M"
        config.result = "N";
    }else{
        config.flag = false;
        config.order = ""
        config.result = "";
    }

    return config;
}
import { useContext, useEffect, useState } from "react"
import { dev_console, isAbleAccesser } from "../../Common/ts/commonTools";
import DataInfo from "../../Component/Elements/DataInfo/DataInfo";
import { progressScheduleInterface } from "./static/interface/reservationHome.interface";
import ScheduleList from "./components/ScheduleList";
import { commonContext } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import CSS from "./static/css/reservationHome.module.css"
import Button from "../../Component/Elements/Button/Button";
import { setProgressScheduleBtn } from "./static/ts/reservation.tool";
import TitleBtnWrap_vr2 from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap_vr2";
import useTitleBtnWrapHook from "../../Component/Elements/TitleBtnWrap/hooks/useTitleBtnWrapHook.hook";
import { dataListInterface } from "../../Component/Elements/TitleBtnWrap/interface/TitleBtnWrap.interface";
import useReservationInfoData from "../../Component/Elements/DataInfo/hooks/useReservationInfoData.hook";

const ReservationHome = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modeList, setModeList] = useState<dataListInterface<number>[]>([]);
    const [moveBtnNode, setMoveBtnNode] = useState<React.ReactNode | null>(null);
    const [progressSchedule, setProgressSchedule] = useState<progressScheduleInterface[]>([]);
    const [selectedSchedule, setSelectedSchedule] = useState<progressScheduleInterface | null>(null);
    const { selectedIndex, setSelectedIndex, setDataList, selectedData } = useTitleBtnWrapHook<dataListInterface<number>>([]);

    const {
        infoDataList,
        setOfficeName,
        setScheduleData
    } = useReservationInfoData();

    const common_context = useContext(commonContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        isAbleAccesser(["C"]);

        if(common_context.updateProgressSchedule){
            setIsLoading(true);
            common_context.updateProgressSchedule()
            .finally(() => {
                const mode_list: dataListInterface<number>[] = [
                    {
                        title: "전체보기",
                        value: 0,
                        is_badge: false,
                    },{
                        title: "미완료일정",
                        value: 1,
                        is_badge: false,
                    },{
                        title: "완료일정",
                        value: 2,
                        is_badge: false,
                    }
                ]

                if(common_context.progressSchedule){
                    const schedule_data = common_context.progressSchedule
                    if(schedule_data.filter((item) => item.status_contents === 0).length > 0){
                        mode_list[1].is_badge = true;
                        setSelectedIndex(1);
                    }else if(schedule_data.filter((item) => item.status_contents === 1).length > 0){
                        mode_list[2].is_badge = true;
                        setSelectedIndex(2);
                    }else{
                        mode_list[0].is_badge = false;
                        setSelectedIndex(0);
                    }
                }

                setModeList(mode_list);
                setDataList(mode_list);

                const data = location.state as progressScheduleInterface;
                setSelectedSchedule(data); 

                setIsLoading(false);
            })
        }
        
    }, [])

    useEffect(() => {
        if(common_context.progressSchedule){
            setProgressSchedule([]);
            if(selectedData?.value === 1){
                setProgressSchedule(common_context.progressSchedule.filter((item) => item.status_contents === 0));
            }else if(selectedData?.value === 2){
                setProgressSchedule(common_context.progressSchedule.filter((item) => item.status_contents === 1));
            }else{
                setProgressSchedule(common_context.progressSchedule);
            }
        }else{
            setProgressSchedule([]);
        }
    }, [selectedData, common_context.progressSchedule])

    useEffect(() => {
        if(selectedSchedule){
            setScheduleData(selectedSchedule);
            setOfficeName(selectedSchedule.detail?.office_name || "");
            const result = setProgressScheduleBtn({item: selectedSchedule, navigate});

            setMoveBtnNode(
                <Button
                    onClick={result.onClick_btn}
                    size={"size_full"}
                >
                    {result.btn_title}
                </Button>
            )
        }else{
            setScheduleData(null);
            setOfficeName("");
            setMoveBtnNode(null);
        }
    }, [selectedSchedule])

    return (
        <div className={CSS.l_reservation_home_main}>
            <div className="g_title">촬영 일정</div>
            <div className={`g_card_wrap ${CSS.l_contents}`}>
                <div className={`g_card ${CSS.l_schedule_card}`}>
                    <TitleBtnWrap_vr2 
                        select_data_list={modeList}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        is_activated={true}
                    />
                    <ScheduleList 
                        progressSchedule={progressSchedule}
                        selectedSchedule={selectedSchedule}
                        setSelectedSchedule={setSelectedSchedule}
                        isLoading={isLoading}
                    />
                </div>
                {
                    selectedSchedule
                    &&  <div className={`g_display_none_object mobile flex ${CSS.l_right_card}`}>
                            <div className={CSS.l_theme_right_wrap__top}>
                                <DataInfo
                                    infoData={infoDataList}
                                />
                            </div>
                            <div
                                className={CSS.l_move_btn}
                            >
                                {moveBtnNode}
                            </div>
                        </div>
                }
            </div>
            {/* {
                selectedSchedule
                ?   <Modal 
                        className="g_display_none_object web flex"
                        isModalOpen={selectedSchedule !== null}
                    >
                        <DataInfo 
                            infoData={
                                [
                                    {
                                        title: "촬영명",
                                        value: selectedSchedule.photo_name || "",
                                    },
                                    {
                                        title: "촬영 일정",
                                        value: selectedSchedule.schedule_date_format || "",
                                    },
                                    {
                                        title: "촬영 시간",
                                        value: `${selectedSchedule.detail?.photo_start || ""} - ${selectedSchedule.detail?.photo_end || ""}`,
                                    },
                                    {
                                        title: "촬영 지점",
                                        value: selectedSchedule.detail?.office_name || "",
                                    },
                                    {
                                        title: "촬영 예정 테마",
                                        value: `${selectedSchedule.detail?.thema_list_name || ""}`,
                                    },
                                    {
                                        title: "패키지명",
                                        value: selectedSchedule.package_name || "",
                                    },
                                    {
                                        title: "계약번호",
                                        value: `${selectedSchedule.detail?.contract_id || ""}`,
                                    },
                                    {
                                        title: "전달사항",
                                        value: selectedSchedule.detail?.memo || "",
                                        is_bold: true,
                                        is_textarea: true,
                                    },
                                ]
                            }
                        />
                        <div 
                            className="g_icon l_close_icon g_display_none_object web g_click_event_item"
                            onClick={() => {
                                setSelectedSchedule(null); 
                            }}    
                        >
                            <img src={left_arrow_icon} />
                        </div>
                    </Modal>
                :   null
            } */}
        </div>
    )

}

export default ReservationHome
import { useEffect, useState } from "react";

type cardLevelType = "level0" | "level1" | "level2";

const CardContainer = (
    {
        children,
        class_name_list,
        level,
    }:{
        children?: React.ReactNode;
        class_name_list?: string[];
        level?: cardLevelType;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = ["g_card"];

        if(level){
            total_class_name_list.push(level);
        }

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        setTotalClassName(total_class_name_list.join(" "));        
    }, [class_name_list, level])

    return(
        <div className={totalClassName}>
            {children}
        </div>
    )
}

export default CardContainer;
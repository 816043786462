import { useEffect, useState } from "react";
import CSS from "../static/css/toggleIconBtn.module.css";

const ToggleIconBtn = (
    {
        enable_img_url,
        disable_img_url,
        onClick,
        is_enable,
    }:{
        enable_img_url: string;
        disable_img_url: string;
        onClick: React.MouseEventHandler<HTMLDivElement>; 
        is_enable: boolean;
    }
) => {

    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = [];

        total_class_name_list.push(CSS.l_toggle_icon_btn_main);

        setTotalClassName(total_class_name_list.join(" "));
    }, [])

    return (
        <img 
            className={totalClassName}
            src={
                is_enable
                ?   enable_img_url
                :   disable_img_url
            } 
            title={
                is_enable
                ?   "비밀번호 보임"
                :   "비밀번호 숨김"
            } 
            onClick={onClick}
        />
    )
}

export default ToggleIconBtn;
import CSS from "./static/css/PartContainer.module.css";

const PartContainer = (
    {
        tag,
        body
    }:{
        tag: React.ReactNode;
        body: React.ReactNode;
    }
) => {
    return(
        <div className={CSS.l_part_container_main}>
            <div className={CSS.l_part_container__tag_container}>
                {tag}
            </div>
            <div className={CSS.l_part_container__body_container}>
                {body}
            </div>
        </div>
    )
}

export default PartContainer;
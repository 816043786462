import { useEffect, useState } from "react";

const useExpirationDate = (date: Date | null) => {
    const [dateData, setDateData] = useState<Date | null>(null);
    const [isExpirationDate, setIsExpirationDate] = useState<boolean>(false);

    useEffect(() => {
        setDateData(date);
    }, [date])

    useEffect(() => {
        if(dateData){
            const today = new Date();
            // 오늘 날짜를 자정으로 맞추기
            today.setHours(0, 0, 0, 0);
            const expirationDate = new Date(dateData);
            expirationDate.setHours(0, 0, 0, 0);

            setIsExpirationDate(today > expirationDate);
        }
    }, [dateData])

    return { isExpirationDate, setDateData };
}

export default useExpirationDate;
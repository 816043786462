import Button from "../../Component/Elements/Button/Button";
import CSS from "./static/css/MyContractPrintPage.module.css";
import CustomerInfo from "./components/MyContractPrintPage/CustomerInfo/CustomerInfo";
import ContractTermsOfUse from "./components/MyContractPrintPage/ContractTermsOfUse/ContractTermsOfUse";
import Signature from "./components/MyContractPrintPage/Signature/Signature";
import ContractPay from "./components/MyContractPrintPage/ContractPay/ContractPay";
import ContractProductInfo from "./components/MyContractPrintPage/ContractProductInfo/ContractProductInfo";
import useContractDataREST from "./hooks/useContractDataREST";
import TextLine from "./components/MyContractPrintPage/TextLine/TextLine";
import { useEffect, useRef } from "react";
import { dev_console, isAbleAccesser, whatIsMode } from "../../Common/ts/commonTools";
import WideCard from "../../Component/Elements/WideCard/WideCard";
import LoopAnimation from "../../Component/Elements/LoopAnimation/LoopAnimation";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";

const MyContractPrintPage = () => {

    const printAreaRef = useRef<HTMLDivElement>(null);

    // 현재 URL의 경로를 가져옴
    const path = window.location.pathname;
    // 예: /myContract/print/123에서 contract_id 추출
    const pathSegments = path.split('/');
    let contract_id = pathSegments[pathSegments.length - 1];

    const {
        babyName,
        babyBrith,
        momName,
        momPhone,
        dadName,
        dadPhone,
        counselor,
        photographer,
        address,
        firstBirthdayParty,
        oopsId,
        contractDate,
        customerNo,
        total,
        downPayment,
        interimPayment,
        balance,
        productTitle,
        productInfoList,
        date,
        signImgUrl,
        customerSignImgUrl,
        isLoading,
    } = useContractDataREST(contract_id || "");

    const handlePrintBtn = () => {
        window.print();
    }

    useEffect(() => {
        isAbleAccesser(["C"]);

        if(!contract_id){
            dev_console.error("인자로 전달된 계약번호가 없음");
            alert("계약번호가 없습니다.");
            window.close();
        }
    }, [contract_id])
    
    if(isLoading){
        return(<LoopAnimationModal isModalOpen={isLoading}/>)
    }

    return(
        <div className={CSS.l_my_contract_print_page_main}>
            <div
                className={`g_no_print ${CSS.l_my_contract_print_page__btn_container}`}
            >
                <Button 
                    onClick={handlePrintBtn}
                    size={"size1"}
                >
                    인쇄
                </Button>
            </div>
            <div ref={printAreaRef}>                
                <div className={CSS.l_my_contract_print_page__title_continaer}>
                    <div className={CSS.l_my_contract_print_page__title_container}>
                        <TextLine 
                            text={[
                                {
                                    text: "촬영 계약서",
                                    size: "large",
                                }
                            ]}
                        />
                    </div>
                    <div className={CSS.l_my_contract_print_page__title_container}>
                        <TextLine 
                            text={[
                                {
                                    text: "(고객 출력용)",
                                    color: "gray",
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className={CSS.l_my_contract_print_page__print_page_container}>
                    <CustomerInfo 
                        baby_name={babyName}
                        baby_brith={babyBrith}
                        mom_name={momName}
                        mom_phone={momPhone}
                        dad_name={dadName}
                        dad_phone={dadPhone}
                        counselor={counselor}
                        photographer={photographer}
                        address={address}
                        first_birthday_party={firstBirthdayParty}
                        oops_id={oopsId}
                        contract_date={contractDate}
                        customer_no={customerNo}
                    />
                    <ContractPay 
                        total={total}
                        down_payment={downPayment}
                        interim_payment={interimPayment}
                        balance={balance}
                    />
                    <ContractProductInfo 
                        product_title={productTitle}
                        product_info_list={productInfoList}
                    />
                    <ContractTermsOfUse />
                    <Signature 
                        date={date}
                        sign_img_url={signImgUrl}
                        customer_sign_img_url={customerSignImgUrl}
                    />
                </div>
            </div>
        </div>
    )
}

export default MyContractPrintPage;
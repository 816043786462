import { callAxios, dev_console } from "../../../../Common/ts/commonTools";

export const requestEventApplicationHandler = ( event: React.MouseEvent<HTMLButtonElement, MouseEvent>, schedule_id: string ) => {
    event.stopPropagation();

    if(!window.confirm("후기 이벤트에 참여하시겠습니까?")){
        return ;
    }

    const config = {
        schedule_id,
    }

    const event_check = "api/v3/customers/event-check";
    callAxios.api({
        url: event_check,
        method: "post",
        dataType: "json",
        data: config,
    })
    .then((response) => {

        dev_console.log(event_check);
        dev_console.log(response);
        if(response.status === 200 && response.data.status === "OK"){
            alert("신청이 완료되었습니다.");
            window.location.reload();
        }else{
            alert("신청과정에서 문제가 발생했습니다.");
        }
    })
    .catch((error) => {
        dev_console.error(error);
        alert("신청과정에서 문제가 발생했습니다.");
    })
}
import { productInofoInterface } from "../../../static/interface/MyContractPrintPage.interface";
import InfoBlock from "../InfoBlock/InfoBlock";
import PartContainer from "../PartContainer/PartContainer";
import Tag from "../Tag/Tag";
import ProductInfoListView from "./components/ProductInfoListView/ProductInfoListView";
import CSS from "./static/css/ContractProductInfo.module.css";

const ContractProductInfo = (
    {
        product_title,
        product_info_list,
    }:{
        product_title: string;
        product_info_list: productInofoInterface[];
    }
) => {
    return(
        <PartContainer 
            tag={
                <Tag 
                    title={"상품정보"}
                />
            }
            body={
                <div className={CSS.l_contract_product_info_main}>
                    <InfoBlock 
                        title="상품"
                        body={[
                            [
                                {
                                    text: product_title,
                                    size: "large"
                                }
                            ]
                        ]}
                    />

                    <div className={CSS.l_contract_product_info__split_bar} />

                    <InfoBlock 
                        title="상품내역"
                    />

                    <div className={`${CSS.l_contract_product_info__split_bar} ${CSS.color} ${CSS.silver}`} />

                    <ProductInfoListView 
                        product_info_list={product_info_list}
                    />
                </div>
            }
        />
    )
}

export default ContractProductInfo;
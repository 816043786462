import PartContainer from "../PartContainer/PartContainer";
import Tag from "../Tag/Tag";
import TextLine from "../TextLine/TextLine";
import CSS from "./static/css/Signature.module.css";
import Img from "../../../../../Component/Elements/Media/components/Img";

const Signature = (
    {
        date,
        sign_img_url,
        customer_sign_img_url,
    }:{
        date: string;
        sign_img_url: string;
        customer_sign_img_url: string;
    }
) => {
    return(
        <PartContainer 
            tag={
                <Tag
                    title={"고객서명"}
                />
            }
            body={
                <div className={CSS.l_signature_main}>
                    <div className={CSS.l_signature__left_contianer}>
                        <TextLine 
                            text={[
                                {
                                    text: date,
                                    bold: true,
                                    size: "large",
                                }
                            ]}
                        />
                    </div>
                    <div className={CSS.l_signature__right_contianer}>
                        <div className={CSS.l_signature__img_container}>
                            <TextLine 
                                text={[
                                    {
                                        text: "담당자서명/인"
                                    }
                                ]}
                            />
                            <div className={CSS.l_signature__img_container__img}>
                                {/* <Img 
                                    src={sign_img_url}
                                    object_fit={"contain"}
                                /> */}
                            </div>
                        </div>
                        <div className={CSS.l_signature__img_container}>
                        <TextLine 
                            text={[
                                {
                                    text: "계약자서명/인"
                                }
                            ]}
                        />
                        <div className={CSS.l_signature__img_container__img}>
                            {/* <Img
                                src={customer_sign_img_url} 
                                object_fit={"contain"}
                            /> */}
                        </div>
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default Signature;
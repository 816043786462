import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBackPath } from "../static/ts/Header.tools";
import Text from "../../../Component/Elements/Text/Text";
import back_arrow_img from "../static/img/Path 4407.svg";
import CSS from "../static/css/HistoryBackBtn.module.css";

const HistoryBackBtn = () => {
    
    const [path, setPath] = useState<string>("");
    
    const location = useLocation();
    const navigate = useNavigate()

    const historyBackHandler = () => {
        navigate(path);
    }

    useEffect(() => {
        const result = getBackPath(location.pathname);
        if(result){
            setPath(result.parents);
        }else{
            setPath("");
        }
    }, [location.pathname])
    
    return (
        <div 
            className={CSS.l_history_back_btn_main}
            onClick={historyBackHandler}
        >
            <div className={CSS.l_history_back_btn__img_container}>
                <img 
                    className={CSS.l_history_back_btn__img_container__img}
                    src={back_arrow_img}
                />
            </div>
            <Text
                size={"size4"}
                class_name_list={["g_display_none_object", "mobile"]}
            >
                {"뒤로가기"}
            </Text>
        </div>
    )
}

export default HistoryBackBtn;
import { useEffect, useState } from "react";
import { contractDataInterface } from "./static/interface/MyContractList.interface";
import { callAxios, formatDate } from "../../Common/ts/commonTools";
import CSS from "./static/css/MyContractList.module.css";
import Table from "../../Component/Elements/Table/Table";
import Button from "../../Component/Elements/Button/Button";
import TextFlexWrap from "../../Component/Elements/TextFlexWrap/TextFlexWrap";
import useContractListREST from "./hooks/useContractListREST";
import WideCard from "../../Component/Elements/WideCard/WideCard";
import LoopAnimation from "../../Component/Elements/LoopAnimation/LoopAnimation";

const MyContractList = () => {

    const {
        contractList,
        isLoading
    } = useContractListREST();

    const modalOpenHandler = (
        {
            contract_id
        }:{
            contract_id: number;
        }
    ) => {
        const url = `/myContract/print/${contract_id}`;
        const window_features = "width=794,height=1123"; // 일반적인 상황에서 a4용지의 크기로 켜짐

        window.open(url, "contract_print_pop_up", window_features);
    }

    return (
        <main className={CSS.l_my_contract_main}>
            <div className="g_title">
                내 계약
            </div>
            <div className={CSS.l_my_contract__main_container}>
                {
                    isLoading
                    ?   <WideCard>
                            <LoopAnimation />
                        </WideCard>
                    :   contractList && contractList.length > 0
                        ?   <Table
                                header_item_list={["계약번호", "계약일", "패지키 명", ""]}
                                body_item_list={
                                    contractList.map((contract_item) => {
                                        return([
                                            <TextFlexWrap
                                                text_list={[`${contract_item.contract_id}`]}
                                                text_list_justify_content={"center"}
                                            />,
                                            <TextFlexWrap
                                                text_list={[`${contract_item.contract_date}`]}
                                                text_list_justify_content={"center"}
                                            />,
                                            <TextFlexWrap
                                                text_list={[`${contract_item.package_name}`]}
                                                text_list_justify_content={"center"}
                                            />,
                                            <Button 
                                                class_name_list={[CSS.l_my_contract__main_container__table__btn_container]}
                                                onClick={modalOpenHandler.bind(null, {contract_id: contract_item.contract_id})}
                                            >
                                                계약서 확인
                                            </Button>
                                        ])
                                    })
                                }
                            />
                        :   <WideCard>
                                계약서 정보가 없습니다.
                            </WideCard>
                }
            </div>
        </main>
    )
}

export default MyContractList;
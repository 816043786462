import { forwardRef } from "react";
import Input from "../../../Component/Elements/Input/Input";

interface PhoneNumberInputInterface{
    value: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputInterface>(({
    value,
    onBlur,
    onChange,
    disabled,
}, ref) => {
    return (
        <Input
            type={"text"}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={14}
            ref={ref}
            disabled={disabled}
        />
    )
})

export default PhoneNumberInput;
import { getTypeName } from "../../../../../../../Common/ts/commonTools";
import { productInofoInterface, TermsOfUseInterface } from "../../../../../static/interface/MyContractPrintPage.interface";
import TextLine from "../../../TextLine/TextLine";
import CSS from "./static/css/ProductInfoListView.module.css";

const ProductInfoListView = (
    {
        product_info_list
    }:{
        product_info_list: productInofoInterface[];
    }
) => {
    return(
        <div className={CSS.l_product_info_list_view_main}>
            {
                product_info_list.map((item, index)=>{
                    const type_name = getTypeName(item.product_type);

                    return(
                        <div
                            key={index}
                            className={CSS.l_product_info_list_view__line_container}
                        >
                            <div className={CSS.l_product_info_list_view__line_container__type_name_container}>
                                <TextLine 
                                    text={[
                                        {
                                            text: item.product_type || ""
                                        }
                                    ]}
                                />
                            </div>
                            <div className={CSS.l_product_info_list_view__line_container__product_name_container}>
                                {
                                    item.product_name_list.map((item_name, index) => {
                                        const text_list: TermsOfUseInterface[] = [
                                            {
                                                text: `(${type_name}) ${item_name}`
                                            }
                                        ]

                                        return(
                                            <TextLine text={text_list} key={index}/> 
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ProductInfoListView;
import InputWrap from "../../../Component/Elements/InputWrap/InputWrap";
import CSS from "../static/css/passwordInputWrap.module.css";

const PasswordInputWrap = (
    {
        label,
        value,
        onChange,
        disabled,
        maxLength,
        children,
        name,
    }:{
        label?: string;
        value?: string;
        onChange?: React.ChangeEventHandler<HTMLInputElement>;
        disabled?: boolean;
        maxLength?: number;
        children?: React.ReactNode;
        name?: string,
    }
) => {
    return (
        <div className={CSS.l_password_input_wrap_main}>
            <InputWrap 
                class_name_list={[CSS.l_input_wrap]}
                label={label}
                value={value}
                onChange={onChange}
                type={"password"}
                is_disabled={disabled}
                name={name}
                maxLength={maxLength}
                input_size={"size_full"}
                flex_direction={"column"}
            />
            {children}
        </div>
    )
}

export default PasswordInputWrap
// 컴포넌트 시뮬레이션을을 위한 테스트페이지
import CSS from "./static/css/testPage.module.css";
import SwipeLine from "../Component/Elements/PhotoListSwipeLine/PhotoListSwipeLine";
import { useState } from "react";

const list: number[] = [];
for(let index = 0 ; index < 10 ; index++){
    list.push(index);
}

const TestPage = () => {

    const [selectedIndex, setSelectedIndex] = useState<number>(10);

    return (
        <div className={CSS.l_test_page_main}>
            <SwipeLine 
                children_list={
                    list.map((item, index) => {
                        return(
                            <div key={index}>
                                {item}
                            </div>
                        )
                    })
                }
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
            />
        </div>
    )
}

export default TestPage
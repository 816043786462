import { useContext, useEffect, useRef, useState } from "react";
import { queryParameterInterface, regionInterface, regionOfficeInterface } from "../../../Common/interface/Common.interface";
import { commonContext } from "../../../App";
import { getCookie } from "../../../Common/ts/appTools";
import { callAxios, dev_console, isSuccess, setQueryParameter } from "../../../Common/ts/commonTools";
import { REGION_LIST } from "../../../Common/ts/const";
import { themaBookDetailListInterface, themaBookPhotoListInterface } from "../static/interface/themeBook.interface";
import { setThemaBookPhotoListQueryParameter } from "../static/ts/themeBook";
import { useQuery } from '@tanstack/react-query';
import { commonResponseInterface } from "../../../Common/interface/App.interface";

const all_item: themaBookPhotoListInterface = {
    office_id: 0,
    photo_id: 0,
    photo_name: "전체",
    type: 3,
}

const useOffiecListFromRegionREST = () => {

    const [selectedRegion, setSelectedRegion] = useState<regionInterface | null>(null);
    const [selectedOfficeId, setSelectedOfficeId] = useState<number>(0);
    const [selectedPhotoProduct, setSelectedPhotoProduct] = useState<themaBookPhotoListInterface | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const { 
        data: officeList,
        error: officeListError,
        isLoading: isOfficeListLoading,
    } = useQuery({
        queryKey: ["theme_book_office", selectedRegion?.name, ],
        queryFn: callOfficeList,
        enabled: !!selectedRegion,  // selectedRegion이 null이 아니면 쿼리 실행
        select: (data) => {
            if(data){
                const response_data: commonResponseInterface<regionOfficeInterface[]> = data.data;
                if(isSuccess(data)){
                    return response_data.result;
                }else{
                    return [];
                }
            }else{
                return [];
            }
        },
    });

    useEffect(() => {
        if (officeList && officeList.length > 0) {
            setSelectedOfficeId(officeList[0].office_id);
        } else {
            setSelectedOfficeId(0);
        }
    }, [officeList]);

    const {
        data: photoProductList, 
        error: photoProductError, 
        isLoading: isPhotoProductLoading,
    } = useQuery({
        queryKey: ["theme_book_photo_product", selectedOfficeId],
        queryFn: callPhotoProductList,
        enabled: !!selectedOfficeId,  // selectedOfficeId이 0이 아니면 쿼리 실행
        select: (data) => {
            if(data){
                const response_data: commonResponseInterface<themaBookPhotoListInterface[]> = data.data;
                if(isSuccess(data)){
                    // type이 2인것이 테마정보다.
                    return [all_item, ...response_data.result];
                }else{
                    return [];
                }
            }else{
                return [];
            }
        },
    });

    useEffect(() => {
        if (photoProductList && photoProductList.length > 0) {
            setSelectedPhotoProduct(photoProductList[0]);
        } else {
            setSelectedPhotoProduct(null);
        }
    }, [photoProductList]);

    const {
        data: themeList,
        error: themeListError,
        isLoading: isThemeListLoading,
    } = useQuery({
        queryKey: ["theme_book_office", selectedPhotoProduct, selectedOfficeId],
        queryFn: callThemeList,
        enabled: !!selectedPhotoProduct,  // selectedPhotoProduct이 null이 아니면 쿼리 실행        
        select: (data) => {
            if(data){
                const response_data: commonResponseInterface<themaBookDetailListInterface[]> = data.data;
                if(isSuccess(data)){
                    return response_data.result;
                }else{
                    return [];
                }
            }else{
                return [];
            }
        },
    });

    const common_context = useContext(commonContext);

    const user_type = getCookie("user_type");

    useEffect(() => {
        // 관리자 계정일때는 해당 관리자의 지점으로 고정한다.
        if(user_type === "E"){
            const office_id_session = getCookie("office_id");
            let office_id = 0;
            if(office_id_session){
                office_id = parseInt(office_id_session);
                if(!isNaN(office_id)){
                    setSelectedOfficeId(office_id);
                }
            }
        }else if(user_type === "C"){
            if(REGION_LIST.length > 0){
                setSelectedRegion(REGION_LIST[0]);
            }else{
                setSelectedRegion(null);
            }
        }
    }, [])

    // 고객이 지역에 있는 지점 정보들을 서버에 요청하는 함수
    async function callOfficeList(){
        if(selectedRegion){
            let region_office_url = "api/v3/customers/region_office";
            const query_list: queryParameterInterface[] = [];
            
            query_list.push({
                key: "region",
                value: selectedRegion.value,
            })

            const query_string: string = setQueryParameter(query_list);
    
            region_office_url = `${region_office_url}?${query_string}`

            const response = await callAxios.api({
                url: region_office_url,
                method: "get"
            })
            
            dev_console.log(region_office_url);
            dev_console.log(response);

            return response;
        }else{
            return null;
        }
    }

    // 고객이 지역에 있는 지점 정보들을 서버에 요청하는 함수
    async function callPhotoProductList(){
        if(!!selectedOfficeId){
            // office_id가 없는경우엔 전 지점에 대한 데이터를 가져온다.
            const thema_book_photo_list_url = `api/v3/customers/thema_book_photo_list?office_id=${selectedOfficeId}`;
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.add(thema_book_photo_list_url);
            }

            const response = await callAxios.api({
                url: thema_book_photo_list_url,
                method: 'get',
            })

            dev_console.log(thema_book_photo_list_url);
            dev_console.log(response);

            return response;
        }else{
            return null;
        }
    }

    // 테마 목록을 서버에 요청하는 함수
    async function callThemeList(){        
        if(selectedPhotoProduct){
            const thema_book_detail_list_endpoint:string = "api/v3/customers/thema_book_detail_list";
            const query_parameter_string = setThemaBookPhotoListQueryParameter({selected_photo_product: selectedPhotoProduct, office_id: selectedOfficeId});
            const thema_book_detail_list_url = thema_book_detail_list_endpoint.concat("?", query_parameter_string);
    
            const response = await callAxios.api({
                url: thema_book_detail_list_url,
                method: "get",
            })

            dev_console.log(thema_book_detail_list_url);
            dev_console.log(response);

            return response;
        }else{
            return null;
        }
    }

    useEffect(() => {
        setIsLoading(isOfficeListLoading || isPhotoProductLoading || isThemeListLoading);
    }, [isOfficeListLoading, isPhotoProductLoading, isThemeListLoading])

    useEffect(() => {
        setIsError(!!officeListError || !!photoProductError || !!themeListError);
        if(officeListError){
            alert("지점 정보를 요청하는 과정에서 문제가 발생했습니다.");
            dev_console.error(officeListError);
        }

        if(photoProductError){
            alert("촬영상품 정보를 요청하는 과정에서 문제가 발생했습니다.");
            dev_console.error(photoProductError);
        }

        if(themeListError){
            alert("테마 정보를 요청하는 과정에서 문제가 발생했습니다.");
            dev_console.error(themeListError);
        }
    }, [officeListError, photoProductError, themeListError])

    return({
        REGION_LIST,
        selectedRegion,
        setSelectedRegion,
        officeList,
        selectedOfficeId,
        setSelectedOfficeId,
        photoProductList,
        selectedPhotoProduct,
        setSelectedPhotoProduct,
        themeList,
        isLoading,
        isError,
    })
}

export default useOffiecListFromRegionREST;
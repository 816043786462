import { useContext, useEffect, useState } from "react";
import CSS from "../static/css/mainSchedule.module.css";
import { commonContext } from "../../../../App";
import { progressScheduleInterface } from "../../../../CustomerPage/Reservation/static/interface/reservationHome.interface";
import { isProgressingScheduledItem, setProgressScheduleBtn } from "../../../../CustomerPage/Reservation/static/ts/reservation.tool";
import InfoBlock from "./MainSchedule/InfoBlock";
import { formatDate, formatTime, isUseTestData } from "../../../../Common/ts/commonTools";
import { useNavigate } from "react-router-dom";
import Img from "../../../../Component/Elements/Media/components/Img";
import { setProgressScheduleBtnResultInterface } from "../../../../CustomerPage/Reservation/static/interface/reservationTool.interface";
import Text from "../../../../Component/Elements/Text/Text";

const MainSchedule = () => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    const [todaysSchedule, setTodaysSchedule] = useState<progressScheduleInterface | null>(null);
    const [scheduleDateString, setScheduleDateString] = useState<string>("");
    const [stateNode, setStateNode] = useState<React.ReactNode | null>(null);
    const [progressScheduleBtnResult, setProgressScheduleBtnResult] = useState<setProgressScheduleBtnResultInterface | null>(null);

    const common_context = useContext(commonContext);
    const navigate = useNavigate();

    useEffect(() => {
        let status:number = 0;

        let todays_schedule: progressScheduleInterface | null = null;
        if(common_context.progressSchedule){
            if(common_context.progressSchedule.length > 0){
                const filted_progress_schedule = common_context.progressSchedule.filter((filter_item) => isProgressingScheduledItem(filter_item));
                if(filted_progress_schedule.length > 0){
                    todays_schedule = filted_progress_schedule[0];
                    status = 1;
                }else{
                    status = -1;
                }
            }else{
                status = -1;
            }
        }else{
            status = -1;
        }

        switch(status){
            case 1:
                if(todays_schedule){
                    const todays_schedule_date = new Date(todays_schedule.schedule_date);
                    const todays_schedule_date_string = formatDate(todays_schedule_date, "yyyy년 MM월 dd일")
                    const start: string = formatTime(todays_schedule.detail?.photo_start || "");
                    const end: string = formatTime(todays_schedule.detail?.photo_end || "");
                    setTodaysSchedule(todays_schedule);
                    setScheduleDateString(`${todays_schedule_date_string} ${start} ~ ${end}`)
                }
                break;
            case -1:
                setTodaysSchedule(null);
                break;
        }

    }, [common_context.progressSchedule])

    useEffect(() => {
        const class_name_list: string[] = ["g_card"];
        const state_class_name_list: string[] = [CSS.l_main_schedule__schedule_info_container__state_text_container];

        if(todaysSchedule){
            const reuslt = setProgressScheduleBtn({item: todaysSchedule, navigate});
            setProgressScheduleBtnResult(reuslt);
            
            class_name_list.push("g_click_event_item");
            state_class_name_list.push(CSS[reuslt.state_class_name]);

            setStateNode(
                <Text
                    size={"size5"}
                    color={"white"}
                    class_name_list={state_class_name_list}
                >
                    {reuslt.btn_title}
                </Text>
            )
        }else{
            setStateNode(null);
        }

        class_name_list.push(CSS.l_main_schedule_main);

        setTotalClassName(class_name_list.join(" "));

    }, [todaysSchedule])

    return(
        <div 
            className={totalClassName}
            onClick={() => {
                if(progressScheduleBtnResult){
                    progressScheduleBtnResult.onClick_btn()
                }
            }}
        >
            <div className={CSS.l_main_schedule__main_photo_container}>
                <Img 
                    src={todaysSchedule ? todaysSchedule.detail?.thema_url?.middle_url : ""}
                    object_fit={"cover"}
                />
            </div>
            <div className={CSS.l_main_schedule__schedule_info_container}>
                <Text
                    size={"size6"}
                    bold={true}
                    class_name_list={[CSS.l_main_schedule__schedule_info_container__title]}
                >
                    Next Schedule
                </Text>

                <div className={CSS.l_main_schedule__schedule_info_container__info_container}>
                    <Text
                        size={"size5"}
                        bold={true}
                    >
                        {todaysSchedule ? todaysSchedule.photo_name : "예정된 촬영 일정이 없습니다."}
                    </Text>
                    <Text
                        size={"size1"}
                    >
                        {
                            todaysSchedule
                            &&  todaysSchedule.detail?.thema_list_name.join(", ")
                        }
                    </Text>
                </div>
                
                <div className={CSS.l_main_schedule__schedule_info_container__detail_info_container}>
                    <div className={CSS.l_main_schedule__schedule_info_container__detail_info__item}>
                        <InfoBlock 
                            mode={"date"} 
                            data={scheduleDateString}
                        />
                    </div>
                    <div className={CSS.l_main_schedule__schedule_info_container__detail_info__item}>
                        <InfoBlock 
                            mode={"necessary_item"} 
                            data={todaysSchedule?.detail?.memo || ""}
                            use_tooltip={true}
                        />
                    </div>
                </div>
                
                {stateNode}
            </div>
        </div>
    )
}

export default MainSchedule;
import { useContext, useEffect, useState } from "react";
import { isAbleAccesser, isAdminAccount } from "../../Common/ts/commonTools";
import ThemeBook from "./ThemeBook";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import { commonContext } from "../../App";
import CSS from "./static/css/themeBookHome.module.css";
import useOffiecListFromRegionREST from "./hooks/useOffiecListFromRegionREST.hook";
import { regionInterface } from "../../Common/interface/Common.interface";

const ThemeBookHome = () => {

    //  공용으로 사용하는 state
    const [isAdminMode, setIsAdminMode] = useState<boolean>(false);

    const {
        REGION_LIST,
        selectedRegion,
        setSelectedRegion,
        officeList,
        selectedOfficeId,
        setSelectedOfficeId,
        photoProductList,
        selectedPhotoProduct,
        setSelectedPhotoProduct,
        themeList,
        isLoading,
        isError,
    } = useOffiecListFromRegionREST();

    const common_context = useContext(commonContext);

    // 접속이 가능한 계정인지 검증
    isAbleAccesser(["C", "E"]);

    
    useEffect(() => {
        // 매니저계정이라면 편집모드를 활성화시킨다.
        setIsAdminMode(isAdminAccount()); 

        return (() => {
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.clear();
            }
        })
    }, [])

    if(isAdminMode){
        return (
            <main className={CSS.l_theme_book_home_main}>
                <ThemeBook 
                    photoProductList={photoProductList || []}
                    selectedPhotoProduct={selectedPhotoProduct}
                    setSelectedPhotoProduct={setSelectedPhotoProduct}
                    themeList={themeList || []}
                    isLoading={isLoading}
                />
            </main>
        )
    }

    return (
        <main className={CSS.l_theme_book_home_main}>
            <ThemeBook
                photoProductList={photoProductList || []}
                selectedPhotoProduct={selectedPhotoProduct}
                setSelectedPhotoProduct={setSelectedPhotoProduct}
                themeList={themeList || []}
                isLoading={isLoading}
            >
                <div className={CSS.l_theme_book_customer_btn_wrap_main}>
                    <TitleBtnWrap<regionInterface | null>
                        valueState={selectedRegion}
                        setValueState={setSelectedRegion}
                        value_list={REGION_LIST}
                        innerText_list={REGION_LIST.map((item) => item.name)}
                        is_activated={true}
                        is_print_badge_list={[]}
                        is_loading={isLoading}
                    />
                    <TitleBtnWrap<number>
                        valueState={selectedOfficeId}
                        setValueState={setSelectedOfficeId}
                        value_list={!!officeList ? officeList.map((item) => item.office_id) : []}
                        innerText_list={!!officeList ? officeList.map((item) => `${item.office_name}`) : []}
                        is_activated={true}
                        is_print_badge_list={[]}
                        is_loading={isLoading}
                    />
                </div>
            </ThemeBook>
        </main>
    )
}

export default ThemeBookHome;
import SymbolText from "../../../Component/Elements/SymbolText/SymbolText";

const PasswordConfimMsgCard = (
    {
        symbol,
        msg_list
    }:{
        symbol: string;
        msg_list: string[];
    }
) => {
    return (
        <div
            className="g_card inner"
        >
            {
                msg_list.map((item) => {
                    return (
                        <SymbolText 
                            symbol={symbol}
                            text={item}
                        />
                    )
                })
            }
        </div>
    )
}

export default PasswordConfimMsgCard;
import CSS from "./static/css/InfoBlockContainer.module.css";

const InfoBlockContainer = (
    {
        children
    }:{
        children?: React.ReactNode;
    }
) => {
    return(
        <div className={CSS.l_info_block_container_main}>
            {children}
        </div>
    )
}

export default InfoBlockContainer;
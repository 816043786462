import { formatDate } from "../../../../../Common/ts/commonTools";
import { progressScheduleInterface } from "../../../../../CustomerPage/Reservation/static/interface/reservationHome.interface";
import { fullCalendarEventInterface } from "../../interface/CustomFullCalendar.interface";

export const transforEventList = (progressSchedule: progressScheduleInterface[]): fullCalendarEventInterface[] => {
    return progressSchedule.map((item) => {
        const schedule_data = new Date(item.schedule_date);

        let schedule_string = "";
        if(!isNaN(schedule_data.getTime())){
            schedule_string = formatDate(schedule_data, "yyyy-MM-dd");
        }

        return{
            title: item.photo_name,
            start: schedule_string,
        }
    })
}
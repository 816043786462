import CSS from "./static/css/symbolText.module.css";

const SymbolText = (
    {
        symbol,
        text
    }:{
        symbol: string;
        text: string;
    }
) => {
    return (
        <div className={CSS.l_symbol_text_main}>
            <div className={CSS.l_symbol_text_main__symbol}>
                {symbol}
            </div>
            <div className={CSS.l_symbol_text_main__text}>
                {text}
            </div>
        </div>
    )
}

export default SymbolText;
import Tooltip from "../../../Component/Elements/Tooltip/Tooltip";
import CSS from "../static/css/inputTitle.module.css";

const InputTitle = (
    {
        title,
        sub_title,
        tooltip_node,
    }:{
        title: string;
        sub_title?: string;
        tooltip_node?: React.ReactNode;
    }
) => {
    return(
        <div className={CSS.l_input_title_main}>
            <div className="g_text size6 bold">
                {title}
            </div>
            {
                tooltip_node
                &&  <Tooltip
                        tooltip_node={tooltip_node}
                        isUseIcon={true}
                    >
                    </Tooltip>
            }
            {
                sub_title
                &&  <div className="g_text size1 color mint">
                        {sub_title}
                    </div>
            }
        </div>
    )
}

export default InputTitle;
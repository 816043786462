import { useContext, useEffect, useState } from "react";
import { indexSignatureInterface, userInfoInterface } from "../../../Common/interface/App.interface";
import InputWrap from "../../../Component/Elements/InputWrap/InputWrap";
import InfoButtonWrap from "./InfoButtonWrap";
import { getCookie, logout } from "../../../Common/ts/appTools";
import { myInfoContext } from "../MyInfo";
import { callAxios, dev_console, getGenderCode, validateEmail } from "../../../Common/ts/commonTools";
import { commonContext } from "../../../App";
import { AxiosResponse } from "axios";
import RadioBtnWrap from "../../../Component/Elements/RadioBtnWrap/RadioBtnWrap";
import CSS from "../static/css/userProfileInfoCard.module.css";
import Text from "../../../Component/Elements/Text/Text";

const UserProfileInfoCard = (
    {
        isEditMode,
        setIsEditMode,
        setNewProfileImgSrc,
        profileImgFile,
        setProfileImgFile,
        originUserInfo,
    }:{
        isEditMode: boolean;
        setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
        setNewProfileImgSrc: React.Dispatch<React.SetStateAction<string>>;
        profileImgFile: File | null;
        setProfileImgFile: React.Dispatch<React.SetStateAction<File | null>>;
        originUserInfo: userInfoInterface | null;
    }
) => {
    const [email, setEmail] = useState<string>("");
    const [babyName, setBabyName] = useState<string>("");
    const [userGneder, setUserGender] = useState<number>(0);
    const [babyGender, setBabyGender] = useState<number>(0);

    const myInfo_context = useContext(myInfoContext);
    const common_context = useContext(commonContext);

    const initEditData = () => {
        setNewProfileImgSrc("");
        setProfileImgFile(null);
        setEmail(originUserInfo?.email || "")
        setBabyName(originUserInfo?.baby_name || "")
        setBabyGender(getGenderCode(originUserInfo?.baby_gender || ""))
        setIsEditMode(false);
    }

    const setUpdateConfig = () => {

        const config:indexSignatureInterface = {}

        if(originUserInfo?.email !== email){
            if(validateEmail(email)){
                config.email = email;
            }else{
                alert("e-mail형식을 지켜주세요.")
                return null;
            }
        }

        if(originUserInfo?.baby_name !== babyName){
            if(babyName.length > 0){
                config.baby_name = babyName;
            }else{
                alert("아이의 이름을 입력해주세요.")
                return null;
            }
        }

        const origin_baby_gender_code: number =  getGenderCode(originUserInfo?.baby_gender || "");
        if(origin_baby_gender_code !== babyGender){
            if(babyGender === 1){
                config.baby_gender = "남"
            }else if(babyGender === 2){
                config.baby_gender = "여"
            }else{
                alert("아기의 성별을 선택해주세요.");
                return null;
            }
        }

        return config;
    }

    const handleOnSave = async () => {

        // 프로필 사진 업데이트
        const mypage_profile_img_url = "api/v3/customers/mypage";
        
        // 유저 개인 정보 업데이트
        const mypage_user_info_url = "api/v1/customers/mypage/";

        const endpoint_url_list: string[] = [];
        const result_list: Promise<AxiosResponse<any, any>>[] = [];

        try{

            const reuslt = setUpdateConfig();
            if(reuslt){
                endpoint_url_list.push(mypage_user_info_url);
                result_list.push(
                    callAxios.api({
                        url: mypage_user_info_url,
                        method: "post",
                        dataType: "json",
                        data: reuslt,
                    })
                )
            }

            if(profileImgFile){
                const formdata = new FormData();
                formdata.append("file_field", profileImgFile)
                endpoint_url_list.push(mypage_profile_img_url);
                result_list.push(
                    callAxios.api({
                        url: mypage_profile_img_url,
                        method: "post",
                        dataType: "formdata",
                        data: formdata,
                    })
                )
            }

            
            if(result_list.length > 0){
                if(!window.confirm("저장하시겠습니까?")){
                    return ;
                }

                endpoint_url_list.forEach((url) => {
                    if(common_context.activeRequestsManager){
                        common_context.activeRequestsManager.add(url);
                    }
                })

                const results = await Promise.allSettled(result_list)

                let anyFulfilled = false;

                results.forEach((result) => {
                    if (result.status === "fulfilled") {
                        anyFulfilled = true;
                        dev_console.log(result.value.data);
                    } else {
                        dev_console.error(result.reason);
                    }
                })

                if (results.every(result => result.status === "rejected")) {
                    alert("수정중에 문제가 발생했습니다.");
                } else {
                    if (anyFulfilled) {
                        window.location.reload();
                        initEditData();
                    }
                }
            }
            
        }catch(error){
            dev_console.error(error);
            alert("저장과정에서 문제가 발생했습니다.");
        }finally{
            endpoint_url_list.forEach((url) => {
                if(common_context.activeRequestsManager){
                    common_context.activeRequestsManager.sub(url);
                }
            })
        }
    }

    const handleOnCancel = () => {
        if(window.confirm("저장되지 않은 정보들은 초기화 됩니다.")){
            initEditData();
        }
    }

    useEffect(() => {
        if(originUserInfo){
            setEmail(originUserInfo.email)
            setBabyName(originUserInfo.baby_name)
            setBabyGender(getGenderCode(originUserInfo.baby_gender))
            setUserGender(getGenderCode(originUserInfo.gender))
        }
    }, [originUserInfo])

    return (
        <div className={CSS.l_user_profile_info_card_main}>
            <div className={CSS.l_user_info}>
                
                <div 
                    className={CSS.l_title_container}
                >
                    <div className={CSS.l_title_container__user_name_container}>
                        <Text
                            size={"size7"}
                            bold={true}
                        >
                            {getCookie("name") || ""}
                        </Text>
                        <Text
                            size={"size5"}
                        >
                            님 안녕하세요!
                        </Text>
                    </div>
                </div>
                
                <div className={CSS.l_input_wrap}>
                    <InputWrap 
                        label={"ID"}
                        value={originUserInfo?.login_id || ""}
                        is_disabled={true}
                        justify_content={"space_between"}
                        input_size={"size2"}
                    />
                    <RadioBtnWrap
                        main_label={"성별"}
                        item_list={[
                            {
                                label: "남자",
                                value: 1,
                            },
                            {
                                label: "여자",
                                value: 2,
                            },
                        ]}
                        name={"gender"}
                        value={userGneder}
                        is_disabled={true}
                        justify_content={"space_between"}
                    />
                    <InputWrap 
                        label={"전화번호"}
                        value={originUserInfo?.phone || ""}
                        is_disabled={true}
                        justify_content={"space_between"}
                        input_size={"size2"}
                    />
                    <InputWrap 
                        label={"E-Mail"}
                        value={email}
                        name="email"
                        onChange={(e) => {
                            setEmail(e.target.value)}
                        } 
                        type={"email"}
                        is_disabled={!isEditMode}
                        justify_content={"space_between"}
                        input_size={"size2"}
                    />
                </div>
            </div>

            <InfoButtonWrap 
                isEditMode={isEditMode}
                handleOnSave={() => {
                    handleOnSave()
                    .then(() => {
                        window.location.reload();
                    })
                }}
                handleOnCancel={handleOnCancel}
                handleOnChangePW={() => {
                    myInfo_context.setIsPasswordChageMode(true);
                }}
                handleOnInfoFix={() => {
                    setIsEditMode(true);
                }}
                handleOnLogOut={() => {
                    logout();
                }}
            />
        </div>
    )
}

export default UserProfileInfoCard
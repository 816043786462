import { useState } from "react";

/**
 * 앱스토어 심사를 위해 사용되는 페이지
 */

const DeleteURL = () => {
  
  const [userURL, setUserURL] = useState('');
  
  return (
        <div>
            <div>
                사용자 계정 삭제
            </div>
        <br />
        <div>
            <div>
                개발자 정보<br />
                ∙ 정종훈<br />
                ∙ sto@luvmom2020.com<br />
            </div>
            <br />
            <div>
                계정 삭제를 위해 다음의 단계를 따라주세요.<br />
                1. 삭제하려는 계정의 ID를 입력해주세요.<br />
                2. 아래의 신청 버튼을 클릭하세요.<br />
            </div>
            <br />
            <div>
                <input value={userURL} onChange={(e)=>{setUserURL(e.target.value)}}/>
            </div>
            <br />
            <div>
                -안내-<br />
                ∙ 데이터는 1년간 보관됩니다.<br />
                ∙ 계정ID, password, 고객 개인정보(이름, 전화번호, 주소)의 자료가 삭제되며, 계정ID 삭제를 진행하실경우 복구가 어렵습니다.<br />
            </div>
            <br />
        </div>
            <button 
                onClick={()=>{
                    if(window.confirm("-주의- 삭제가 진행될 경우 복구가 어렵습니다. 진행하시겠습니까?")){
                        alert("삭제신청이 완료되었습니다.");
                        setUserURL('');
                    }
                }}
            >
                신청
            </button>
        </div>
    )
}

export default DeleteURL;
import { useEffect, useState } from "react";
import { TermsOfUseInterface } from "../../../static/interface/MyContractPrintPage.interface";
import TextLine from "../TextLine/TextLine";
import TextLineContainer from "../TextLineContainer/TextLineContainer";
import CSS from "./static/css/InfoBlock.module.css";

const InfoBlock = (
    {
        title,
        titleBold,
        body,
        body_background_color,
        horizontal_division_ratio,
    }:{
        title?: string;
        titleBold?: boolean;
        body?: TermsOfUseInterface[][];
        body_background_color?: "same_title";
        horizontal_division_ratio?: "symmetry";
    }
) => {

    const [titleClassName, setTitleClassName] = useState<string>("");
    const [bodyClassName, setBodyClassName] = useState<string>("");

    useEffect(() => {
        const title_class_name_list: string[] = [CSS.l_info_block__title_container];

        if(!body){
            title_class_name_list.push(CSS.size);
            title_class_name_list.push(CSS.full);
        }

        if(horizontal_division_ratio){
            title_class_name_list.push(CSS.horizontal_division_ratio);
            title_class_name_list.push(CSS[horizontal_division_ratio]);
        }

        setTitleClassName(title_class_name_list.join(" "));
    }, [body, horizontal_division_ratio])

    useEffect(() => {
        const body_class_name_list: string[] = [CSS.l_info_block__body_container];

        if(body_background_color){
            body_class_name_list.push(CSS.body_background_color)
            body_class_name_list.push(CSS[body_background_color]);
        }

        if(horizontal_division_ratio){
            body_class_name_list.push(CSS.horizontal_division_ratio);
            body_class_name_list.push(CSS[horizontal_division_ratio]);
        }

        setBodyClassName(body_class_name_list.join(" "))
    }, [body_background_color, horizontal_division_ratio])

    return(
        <div
            className={CSS.l_info_block_main}
        >
            {
                title
                &&  <div className={titleClassName}>
                        <TextLine 
                            text={[
                                {
                                    text: title,
                                    bold: titleBold || false
                                }
                            ]}
                        />
                    </div>
            }
            {
                body
                &&  <div className={bodyClassName}>
                        <TextLineContainer>
                            {
                                body.map((item, index) => {
                                    return(
                                        <TextLine 
                                            text={item}
                                            key={index}
                                        />
                                    )
                                })
                            }
                        </TextLineContainer>
                    </div>
            }
        </div>
    )
}

export default InfoBlock;
import { useEffect, useState } from "react";
import CSS from "../static/css/cardInfoTextWrap.module.css"

const CardInfoTextWrap = (
    {
        text_list,
        type,
    }:{
        text_list: string[];
        type: "normal" | "title"
    }
) => {
    return (
        <div className={CSS.l_card_info_text_wrap_main}>
            {
                text_list.map((item, index) => {
                    return (
                        <div
                            className={
                                type === "title"
                                ?   `g_text size7 bold ${CSS.l_text}`
                                :   `g_text ${CSS.l_text}`
                            }
                            key={index}
                        >
                            {item}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CardInfoTextWrap;
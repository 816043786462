// 촬영 스케줄의 상태코드를 계산하여 리턴한다.
// 1: 테마선택 미진행
// 2: 테마선택완료, 촬영 미진행

import { NavigateFunction } from "react-router-dom";
import { progressScheduleInterface } from "../interface/reservationHome.interface";
import { setProgressScheduleBtnResultInterface, stateClassNameType } from "../interface/reservationTool.interface";
import { btnPurposeType } from "../../../../Component/Elements/Button/static/interface/Button.interface";

// 3: 촬영완료
export const getStatusContentsCode = (
    {
        status_contents,
        thema_list_name,
    }:{
        status_contents: number;
        thema_list_name: string[] | undefined;
    }
): number => {
    let status_contents_code: number = 0;

    if(status_contents === 0){
        if(thema_list_name){
            if(thema_list_name.length > 0 && thema_list_name[0].length > 0){
                status_contents_code = 2;
            }else{
                status_contents_code = 1;
            }
        }
    }else if(status_contents === 1){
        status_contents_code = 3;
    }

    return status_contents_code;
}

export const isProgressingScheduledItem = (item: progressScheduleInterface) => {
    let result: boolean = false

    if(item.status_contents === 0){
        result = true;
    }

    return result;
} 

// 버튼 문구, 기능 설정 함수
export const setProgressScheduleBtn = (
    {
        item,
        navigate,
    }:{
        item: progressScheduleInterface;
        navigate: NavigateFunction;
    }
): setProgressScheduleBtnResultInterface => {
    let btn_title = "";
    let onClick_btn = null;
    let state_class_name: stateClassNameType = "";
    let purpose: btnPurposeType = "";

    // 버그픽스라기보단 임시방변
    if(item.btn_type === 0){
        btn_title = "테마 선택 필요"
        onClick_btn = () => {
            navigate("/selectTheme", {
                state: {
                    schedule_info_for_select_theme: item
                }
            });
        }

        state_class_name = "before_select_theme";
        purpose = "save";
    }else if(item.btn_type === 1){
        btn_title = "테마 선택 완료"
        onClick_btn = () => {
            navigate("/selectTheme", {
                state: {
                    schedule_info_for_select_theme: item,
                }
            });
        }

        state_class_name = "before_photo";
    }else{
        btn_title = "사진 보기"
        onClick_btn = () => {
            
            if(item.detail){                    
                navigate(
                    "/photoDetail", 
                    {
                        state: {
                            selected_photo_upload_id: item.detail.photo_upload_id,
                            main_type: "1",  //  여기에 출력되는 데이터는 무조건 1의 상태를 가지는 값이므로 고정시킨다.
                            photo_type: "1", //  여기에 출력되는 데이터는 무조건 2의 상태를 가지는 값이므로 고정시킨다.
                        }
                    }
                );
            }else{      
                navigate("/photoList");
            }
        }

        state_class_name = "end_photo";
        purpose = "dev";
    }

    return {btn_title, onClick_btn, state_class_name, purpose};
}
import { DayHeaderContentArg } from "@fullcalendar/core";

const DayHeader = (dayHeaderCellInfo: DayHeaderContentArg) => {

    const day_header_list: string[] = ["일", "월", "화", "수", "목", "금", "토"];

    return(
        <span className="g_text size3 bold">
            {day_header_list[dayHeaderCellInfo.dow]}
        </span>
    )
}

export default DayHeader;
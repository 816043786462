import { useEffect } from "react";
import { contractDataInterface } from "../static/interface/MyContractList.interface";
import { callAxios, dev_console, isSuccess } from "../../../Common/ts/commonTools";
import { commonResponseInterface } from "../../../Common/interface/App.interface";
import { useQuery } from "@tanstack/react-query";

const useContractListREST = () => {

    const {
        data: contractList, 
        error, 
        isLoading
    } = useQuery({
        queryKey: ["contractList"],
        queryFn: callContractList,
        select: (data) => {
            dev_console.log("api/v3/customers/contract/list");
            dev_console.log(data);
            if(data){
                const response_data: commonResponseInterface<contractDataInterface[]> = data.data;
                if(isSuccess(data)){
                    return response_data.result;
                }else{
                    return [];
                }
            }else{
                return [];
            }
        },
    })

    async function callContractList() {
        const contract_list_url = "api/v3/customers/contract/list";
        return await callAxios.api({
            url: contract_list_url,
            method: "get"
        })
    }

    useEffect(() => {
        if(error){
            alert("계약서 정보를 요청하는 과정에서 문제가 발생했습니다.");
        }
    }, [error])

    return(
        {
            contractList,
            isLoading
        }
    )
}

export default useContractListREST;
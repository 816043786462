import { regionInterface } from "../interface/Common.interface";

export const VIEW_SIEZ = {
    sd: {
        px: {
            width: 854,
            height: 480,
            unit: "px",
        }

    },

    hd: {
        px: {
            width: 1280,
            height: 720,
            unit: "px",
        }
    },

    fhd: {    
        px: {
            width: 1920,
            height: 1080,
            unit: "px",
        }
    },
    
    uhd: {
        px: {
            width: 3840,
            height: 2160,
            unit: "px",
        }
    }
    
}


// // 아직 오피스 정보가 없는 지역들
// const region_list_emplty_office = ["강원도", "경상도", "제주도"];

// 오피스 정보를 출력 가능한 지역
export const REGION_LIST: regionInterface[] = [
    {
        id: 1,
        name: '서울',
        value: '서울',
    },
    {
        id: 2,
        name: '경기도', 
        value: '경기',
    },
    {
        id: 3,
        name: '충청도', 
        value: '충청',
    },
    {
        id: 4,
        name: '강원도', 
        value: '강원',
    },
    {
        id: 5,
        name: '전라도', 
        value: '전라',
    },
    {
        id: 6,
        name: '경상도', 
        value: '경상',
    },
    {
        id: 7,
        name: '제주도',
        value: '제주',
    },
];

// 청크사이즈
export const CHUNK_SIZE = 1024 * 1024; // 1MB

// 편집 요청 예시
export const EXAMPLE_TEXT_LIST: string[] = ["-요청 예시-", "(ex. 사진들의 전체적인 톤을 밝게 해주세요.)", "(ex. 아이의 입에 침 자국을 지워주세요.)", `\n`, "-주의사항-", `"OO번째 사진을 편집해주세요." 같이 사진의 순서로 지목하시면`, "잘못된 사진에 편집이 진행될 가능성이 있습니다.", "사진파일 이름으로 지목해주세요.", "(ex. 아이사진.jpg의 톤을 보정해주세요.)"];
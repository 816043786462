import { useEffect, useState } from "react";
import CSS from "./static/Text.module.css";

const Text = (
    {
        children,
        size,
        style,
        color,
        class_name_list,
        onClick,
        bold,
        is_container_highlight
    }:{
        children?: React.ReactNode;
        size?: "size1" | "size2" | "size3" | "size4" | "size5" | "size6" | "size7";
        style?: "italic";
        color?: "dark" | "white" | "red" | "mint";
        class_name_list?: string[];
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        bold?: boolean;
        is_container_highlight?: boolean;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = ["g_text"];

        if(size){
            total_class_name_list.push(size);
        }

        if(color){
            total_class_name_list.push("color");
            total_class_name_list.push(color);
        }

        if(bold){
            total_class_name_list.push("bold");
        }

        if(style){
            total_class_name_list.push(style);
        }

        if(is_container_highlight){
            total_class_name_list.push(CSS.is_container_highlight);
        }

        if(onClick){
            total_class_name_list.push("g_click_event_item");
        }

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_text_main);

        setTotalClassName(total_class_name_list.join(" "));
    }, [size, color, style, class_name_list, onClick, is_container_highlight])

    return(
        <div 
            className={totalClassName}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default Text;
import _ from "lodash";
import SwipeLine from "../SwipeLine/SwipeLine";
import SwipeTextItem from "../SwipeLine/components/SwipeTextItem";
import CSS from "./static/css/titleBtnWrap.module.css";

const TitleBtnWrap = <T, >(
    {
        valueState,
        setValueState,
        value_list,
        innerText_list,
        is_activated,
        is_print_badge_list,
        is_loading,
        additional_function,
    }:{
        valueState: T;
        setValueState: React.Dispatch<React.SetStateAction<T>>;
        value_list: T[];
        innerText_list: string[];
        is_activated: boolean;
        is_print_badge_list: boolean[];
        is_loading?: boolean;
        additional_function?: Function;
    }
) => {

    const textBtnOnClickHandler = (index: number) => {
        if(is_activated && value_list.length > index && !is_loading){
            setValueState(value_list[index]);
            if(additional_function){
                additional_function(value_list[index]);
            }
        }
    }

    return (
        <div className={CSS.l_title_btn_wrap_main}>
            <SwipeLine>
                {
                    innerText_list.map((item, index) => {
                        return (
                            <SwipeTextItem
                                key={index}
                                text={item}
                                onClick={textBtnOnClickHandler.bind(null, index)}
                                is_highlight={_.isEqual(value_list[index], valueState)}
                                is_print_badge={is_print_badge_list[index]}
                                disabled={is_loading}
                            />
                        )
                    })
                }
            </SwipeLine>
        </div>
    )
}

export default TitleBtnWrap;
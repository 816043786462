import MediaCard from "../../../Component/Elements/MediaCard/MediaCard";
import Text from "../../../Component/Elements/Text/Text";
import CSS from "../static/css/imageBlock.module.css";

const ImageBlock = (
    {
        origin_src,
        src,
        onClick,
        is_selectd,
        file_type,
        file_name,
    }:{
        origin_src: string;
        src: string;
        onClick: React.MouseEventHandler<HTMLDivElement>
        is_selectd: boolean,
        file_type: string;
        file_name: string;
    }
) => {
    return (
        <div
            className={
                is_selectd
                ?   `${CSS.l_image_block_main} ${CSS.is_selectd} l_swipe_item`
                :   `${CSS.l_image_block_main} l_swipe_item`
            }
            onClick={onClick}
        >
            <MediaCard
                origin_src={origin_src}
                file_type={file_type}
                src={src}
                size={"full"}
                is_hover_action_activate={true}
                video_controls={false}
            />
            <Text
                size={"size1"}
                class_name_list={[CSS.l_image_block__file_name_container]}
            >
                {file_name}
            </Text>
        </div>
    )
}

export default ImageBlock
import { useContext } from "react";
import SwipeLine from "../SwipeLine/SwipeLine";
import SwipeTextItem from "../SwipeLine/components/SwipeTextItem";
import { dataListInterface } from "./interface/TitleBtnWrap.interface";
import CSS from "./static/css/titleBtnWrap.module.css";
import { commonContext } from "../../../App";

const TitleBtnWrap_vr2 = <T, >(
    {
        select_data_list,
        selectedIndex,
        setSelectedIndex,
        is_activated,
        is_multi_request_locking_on,
    }:{
        select_data_list: dataListInterface<T>[];
        selectedIndex: number;
        setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
        is_activated: boolean;
        is_multi_request_locking_on?: boolean;
    }
) => {

    const common_context = useContext(commonContext);

    const onClickHandler = (index: number) => {
        if(is_multi_request_locking_on){
            if(common_context.activeRequestsManager){
                if(common_context.activeRequestsManager.status()){
                    if(common_context.activeRequestsManager.status()){
                        alert("잠시 기다려주세요.");
                        return ;
                    }
                }
            }
        }

        if(is_activated){
            setSelectedIndex(index);
        }
    }

    return (
        <div className={CSS.l_title_btn_wrap_main}>
            <SwipeLine>
                {
                    select_data_list.map((item, index) => {
                        return (
                            <SwipeTextItem
                                key={index}
                                text={item.title}
                                onClick={() => {
                                    onClickHandler(index)
                                }}
                                is_highlight={index === selectedIndex}
                                is_print_badge={item.is_badge}
                            />
                        )
                    })
                }
            </SwipeLine>
        </div>
    )
}

export default TitleBtnWrap_vr2;
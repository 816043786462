import CSS from "./static/css/Tag.module.css";

import img1 from "./static/img/고객정보.gif";
import img2 from "./static/img/계약금액.gif";
import img3 from "./static/img/상품정보.gif";
import img4 from "./static/img/계약동의.gif";
import img5 from "./static/img/고객서명.gif";

const Tag = (
    {
        title
    }:{
        title: "고객정보" | "계약금액" | "상품정보" | "계약동의" | "고객서명";
    }
) => {
    let img: string = "";
    if(title === "고객정보"){
        img = img1;
    }else if(title === "계약금액"){
        img = img2;
    }else if(title === "상품정보"){
        img = img3;
    }else if(title === "계약동의"){
        img = img4;
    }else if(title === "고객서명"){
        img = img5;
    }

    return(
        <div className={CSS.l_tag_main}>
            <img 
                className={CSS.l_tag__img}
                src={img} 
            />
        </div>
    )
}

export default Tag;
import CSS from "../../static/css/textWrap.module.css";

const TextWrap = (
    {
        label,
        text,
    }:{
        label?: string;
        text: string;
    }
) => {
    return(
        <div className={CSS.l_text_wrap_main}>
            {
                label
                &&  <div className={`g_text size1 bold ${CSS.l_text_wrap__label}`}>
                        {label}
                    </div>
            }
            <div className={`g_text size1 ${CSS.l_text_wrap__text}`}>
                {text}
            </div>
        </div>
    )
}

export default TextWrap;
import { useId } from "react";
import CSS from "../static/css/albumInput.module.css";
import Input from "../../../Component/Elements/Input/Input";

const AlbumInput = (
    {
        label,
        value,
        onChange,
        type,
        disabled,
        min,
        max,
    }:{
        label?: string;
        value: string | number;
        onChange?: React.ChangeEventHandler<HTMLInputElement>
        type?: React.HTMLInputTypeAttribute;
        disabled?: boolean;
        min?: number;
        max?: number;
    }
) => {
    const id = useId();

    return (
        <div className={`g_input_wrap ${CSS.l_album_input_main}`}>
            {
                label
                &&  <label htmlFor={id}>
                        {label}
                    </label>
            }
            <Input 
                value={value}
                id={id}
                onChange={onChange}
                type={type}
                disabled={disabled}
                size={"size1"}
                min={min}
                max={max}
            />
        </div>
    )
}

export default AlbumInput;
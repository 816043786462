import { progressScheduleInterface } from "../../static/interface/reservationHome.interface";
import CSS from "../../static/css/scheduleBlock.module.css";
import { useEffect, useState } from "react";
import Button from "../../../../Component/Elements/Button/Button";
import TextWrap from "./TextWrap";
import Dday from "./Dday";

const ScheduleBlock = (
    {
        onClick,
        btn_title,
        onClick_btn,
        is_highlight,
        schedule_data,
        d_day_number,
    }:{
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        btn_title: string;
        onClick_btn: React.MouseEventHandler<HTMLButtonElement>;
        is_highlight?: boolean;
        schedule_data: progressScheduleInterface;
        d_day_number: number;
    }
) => {

    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {

        const class_name_list: string[] = ["g_card", "g_click_event_item"];

        if(is_highlight){
            class_name_list.push("g_highlight");
        }

        class_name_list.push(CSS.l_card_item);

        setTotalClassName(class_name_list.join(" "));

    }, [is_highlight])

    return (
        <div 
            className={totalClassName}
        >
            <div 
                className={
                    is_highlight
                    ?   `g_text bold ${CSS.l_info_text_wrap}`
                    :   `g_text ${CSS.l_info_text_wrap}`
                } 
                onClick={onClick}
            >
                <div className={CSS.l_text_box}>
                    <Dday
                        d_day={d_day_number}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap
                        label="촬영 일자" 
                        text={schedule_data.schedule_date}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="촬영 시간" 
                        text={`${schedule_data.detail?.photo_start || ""} - ${schedule_data.detail?.photo_end || ""}`}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="계약번호" 
                        text={schedule_data.detail?.contract_id || "-"}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="촬영 명"
                        text={schedule_data.detail?.schedule_title || "-"}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="테마"
                        text={`${schedule_data.detail?.thema_list_name || "-"} "테마"`}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="상태"
                        text={schedule_data.status_text || "-"}
                    />
                </div>
                <Button
                    size={"size1"}
                    onClick={onClick_btn}
                >
                    {btn_title}
                </Button>
            </div>
        </div>
    )
}

export default ScheduleBlock;
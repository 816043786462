import InfoBlock from "../InfoBlock/InfoBlock";
import InfoBlockContainer from "../InfoBlockContainer/InfoBlockContainer";
import PartContainer from "../PartContainer/PartContainer";
import Tag from "../Tag/Tag";
import CSS from "./static/css/CustomerInfo.module.css";

const CustomerInfo = (
    {
        baby_name,
        baby_brith,
        mom_name,
        mom_phone,
        dad_name,
        dad_phone,
        counselor,
        photographer,
        address,
        first_birthday_party,
        oops_id,
        contract_date,
        customer_no,
    }:{
        baby_name: string;
        baby_brith: string;
        mom_name: string;
        mom_phone: string;
        dad_name: string;
        dad_phone: string;
        counselor: string;
        photographer: string;
        address: string;
        first_birthday_party: string;
        oops_id: string;
        contract_date: string;
        customer_no: string;
    }
) => {
    return(
        <PartContainer
            tag={
                <Tag 
                    title={"고객정보"}
                />
            }
            body={
                <div className={CSS.l_customer_info_main}>
                    <div className={CSS.l_customer_info__left_contianer}>
                        <InfoBlockContainer>
                            <InfoBlock 
                                title="아기이름"
                                body={[
                                    [
                                        {
                                            text: baby_name,
                                            bold: true
                                        }
                                    ]
                                ]}
                            />
                            <InfoBlock 
                                title="생년월일"
                                body={[
                                    [
                                        {
                                            text: baby_brith,
                                        }
                                    ]
                                ]}
                            />
                        </InfoBlockContainer>

                        <InfoBlockContainer>
                            <InfoBlock 
                                title="엄마이름"
                                body={[
                                    [
                                        {
                                            text: mom_name,
                                        }
                                    ]
                                ]}
                            />
                            <InfoBlock 
                                title="엄마폰"
                                body={[
                                    [
                                        {
                                            text: mom_phone,
                                        }
                                    ]
                                ]}
                            />
                        </InfoBlockContainer>

                        <InfoBlockContainer>
                            <InfoBlock 
                                title="아빠"
                                body={[
                                    [
                                        {
                                            text: dad_name,
                                        }
                                    ]
                                ]}
                            />
                            <InfoBlock 
                                title="아빠폰"
                                body={[
                                    [
                                        {
                                            text: dad_phone,
                                        }
                                    ]
                                ]}
                            />
                        </InfoBlockContainer>

                        <InfoBlockContainer>
                            <InfoBlock 
                                    title="상담자"
                                    body={[
                                        [
                                            {
                                                text: counselor,
                                            }
                                        ]
                                    ]}
                            />
                            <InfoBlock 
                                title="촬영자"
                                body={[
                                    [
                                        {
                                            text: photographer,
                                        }
                                    ]
                                ]}
                            />
                        </InfoBlockContainer>

                        <InfoBlockContainer>
                            <InfoBlock 
                                title="주소"
                                body={[
                                    [
                                        {
                                            text: address,
                                        }
                                    ]
                                ]}
                            />
                        </InfoBlockContainer>

                        <InfoBlockContainer>
                            <InfoBlock 
                                title="돌잔치"
                                body={[
                                    [
                                        {
                                            text: first_birthday_party,
                                        }
                                    ]
                                ]}
                            />
                            <InfoBlock 
                                title="OOPS ID"
                                body={[
                                    [
                                        {
                                            text: "아이디",
                                        }
                                    ],[
                                        {
                                            text: oops_id,
                                            color: "blue",
                                        }
                                    ],[
                                        {
                                            text: "-",
                                            color: "white",
                                        }
                                    ],[
                                        {
                                            text: "홈페이지 주소"
                                        }
                                    ],[
                                        {
                                            text: "https://oops.lmedrp.com/",
                                            color: "blue"
                                        }
                                    ]
                                ]}
                            />
                        </InfoBlockContainer>
                    </div>
                    <div className={CSS.l_customer_info__right_contianer}>
                        <InfoBlockContainer>
                            <InfoBlock 
                                title="계약일"
                                body={[
                                    [
                                        {
                                            text: contract_date,
                                        }
                                    ]
                                ]}
                            />
                            <InfoBlock 
                                title="고객번호"
                                body={[
                                    [
                                        {
                                            text: customer_no,
                                        }
                                    ]
                                ]}
                            />
                        </InfoBlockContainer>
                        <InfoBlock 
                            body={[
                                [
                                    {
                                        text: "★ 당일취소 혹은 당일일정 변경시 불이익이 발생합니다.",
                                        color: "mint",
                                    }
                                ],[
                                    {
                                        text: "★ 촬영안내전화 혹은 카톡확인이 안될시 촬영이 취소됩니다.",
                                        color: "mint",
                                    }
                                ],[
                                    {
                                        text: "★ 모든 촬영이 끝난 후 상품은 착불로 택배발송 됩니다.",
                                        color: "mint",
                                    }
                                ]
                            ]}
                        />
                    </div>
                </div>
            }
        />
    )
}

export default CustomerInfo;
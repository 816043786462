import { ChangeEvent, useRef, useState } from 'react';
import { addHyphens, dev_console, validateInput } from '../../Common/ts/commonTools';
import PhoneNumberInput from './components/PhoneNumberInput';
import NameInput from './components/NameInput';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../../Common/image/EDRP-OOPS-02.png';
import IDInput from './components/IDInput';

import CSS from "./static/css/resetUserPassword.module.css";
import Button from '../../Component/Elements/Button/Button';

const ResetUserPassword = () => {

    const [userId, setUserId] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [userPhone, setUserPhone] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [resultData, setResultData] = useState<{msg:string, sign: boolean} | null>(null);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const idInputRef = useRef<HTMLInputElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const phoneInputRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const handleIDInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setErrorMsg("");
        setResultData(null);
        setUserId(event.target.value);
    }

    const handleNameInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setErrorMsg("");
        setResultData(null);
        setUserName(event.target.value)
    }

    const handlePhoneInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setErrorMsg("");
        setResultData(null);
        const formattedPhone = addHyphens(event.target.value);
        setUserPhone(formattedPhone);
    }

    const handleTempPasswordDivOnClick = () => {
        if(resultData){

            navigator.clipboard.writeText(resultData.msg)
            .then(() => {
                alert("클립보드에 복사되었습니다.");
            })
            .catch(() => {
                alert("클립보드 복사에 실패했습니다.");
            })
        }
    }

    const onSubmite = () => {

        if(isLoading){
            return ;
        }

        if(userId.length === 0){
            idInputRef.current?.focus();
            alert("ID를 입력해주세요.");
            return ;
        }else if(!validateInput(userId)){
            setUserId("");
            idInputRef.current?.focus();
            alert("사용할수 없는 문자가 감지되었습니다.");
            return ;
        }

        if(userName.length === 0){
            nameInputRef.current?.focus();
            alert("이름을 입력해주세요.");
            return ;
        }else if(!validateInput(userName, true)){
            setUserName("");
            nameInputRef.current?.focus();
            alert("사용할수 없는 문자가 감지되었습니다.");
            return ;
        }

        if(userPhone.length === 0){
            phoneInputRef.current?.focus();
            alert("연락처를 입력해주세요.");
            return ;
        }else if(!validateInput(userPhone)){
            setUserPhone("")
            phoneInputRef.current?.focus();
            alert("사용할수 없는 문자가 감지되었습니다.");
            return ;
        }

        if(!window.confirm("비밀번호를 초기화 하시겠습니까?")){
            return ;
        }

        setIsLoading(true);
        setResultData(null);

        const data = {
            username: userId,
            phone: userPhone,
            name: userName,
        }

        const url = `${process.env.REACT_APP_API_URL}/api/v1/accounts/new_password/`
        axios.post(url, data)
        .then((response) => {
            dev_console.log(url);
            dev_console.log(response);

            if(response.status === 200){
                setResultData({
                    msg: `${response.data.password}`,
                    sign: true,
                });
            }else{
                setErrorMsg("입력하신 정보를 확인해주세요.");
            }

        })
        .catch((error) => {
            dev_console.error(error);
            
            if(error.response.status === 400){
                if(error.response.data.msg === "user info error"){
                    setErrorMsg("입력하신 정보를 확인해주세요.");
                }
            }else{
                setErrorMsg("요청중에 문제가 발생했습니다. 다시 시도해주세요.");
            }
        })
        .finally(() => {
            setIsLoading(false);
        })

    }

    return (
        <main className={CSS.l_reset_user_password_main}>
            <div className={CSS.l_reset_user_password__contents_container}>
                <div
                    className={CSS.l_logo_container}
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <img
                        className={CSS.l_logo_img}
                        src={logo}
                    />
                </div>
                {
                    resultData
                    ?   <div
                            className={CSS.l_result_contents_container}
                        >
                            <div
                                className={CSS.l_temp_pass_text_container}
                            >
                                <div
                                    className={`g_text size7 g_click_event_item ${CSS.l_temp_pass_text_container}`}
                                    onClick={handleTempPasswordDivOnClick}
                                >
                                    {resultData.msg}
                                </div>
                            </div>
                            <div>
                                임시비밀번호로 로그인 하신 후 비밀번호를 변경해주세요.
                            </div>
                            <div className='g_btn_wrap'>
                                <Button
                                    size={"size1"}
                                    onClick={() => {
                                        navigate("/")
                                    }}
                                >
                                    홈으로
                                </Button>
                            </div>
                        </div>
                    :   <div
                            className={CSS.l_result_contents_container}
                        >
                            <div
                                className={CSS.l_input_wrap_container}
                            >
                                <div>
                                    <div>
                                        ID
                                    </div>
                                    <IDInput 
                                        value={userId}
                                        onChange={handleIDInputOnChange}
                                        ref={idInputRef}
                                    />
                                </div>

                                <div>
                                    <div>
                                        이름
                                    </div>
                                    <NameInput 
                                        value={userName}
                                        onChange={handleNameInputOnChange}
                                        ref={nameInputRef}
                                    />
                                </div>

                                <div>
                                    <div>전화번호</div>
                                    <div>
                                        <PhoneNumberInput 
                                            value={addHyphens(userPhone)} 
                                            onChange={handlePhoneInputOnChange} 
                                            ref={phoneInputRef}
                                        />
                                    </div>
                                </div>
                            </div>

                            {
                                errorMsg
                                &&  <div
                                        className={`g_text size1 ${CSS.l_error_text}`}
                                    >
                                        {errorMsg}
                                    </div>
                            }

                            <div className='g_btn_wrap'>
                                <Button
                                    size={"size1"}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >
                                    뒤로가기
                                </Button>

                                <Button
                                    size={"size1"}
                                    purpose={"save"}
                                    onClick={onSubmite}
                                >
                                    비밀번호 초기화
                                </Button>
                            </div>
                        </div>
                }
            </div>
        </main>
    );
}

export default ResetUserPassword;

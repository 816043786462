import { useEffect, useState } from "react";
import CSS from "./static/css/Textarea.module.css";

const Textarea = (
    {
        children,
        onChange,
        placeholder,
        class_name_list,
        readOnly,
        disabled,
    }:{
        children?: string;
        onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
        placeholder?: string;
        class_name_list?: string[];
        readOnly?: boolean;
        disabled?: boolean;
    }
) => {
    const [totalClassNameList, setTotalClassNameList] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_text_area_main)

        setTotalClassNameList(total_class_name_list.join(" "))
    }, [class_name_list])

    return(
        <textarea
            className={totalClassNameList}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
        >
            {children}
        </textarea>
    )
}

export default Textarea;
import Text from "../../../Component/Elements/Text/Text";
import CSS from "../static/css/photoCardInfoTextWrap.module.css";

const PhotoCardInfoTextWrap = (
    {
        title,
        date,
        children,
    }:{
        title: string;
        date: string;
        children?: React.ReactNode;
    }
) => {
    return (
        <div className={CSS.l_photo_card_info_text_wrap_main}>
            <Text
                size={"size5"}
                class_name_list={[CSS.l_title]}
                bold={true}
            >
                {title}
            </Text>

            <div className={CSS.l_alert_msg_continaer}>

                {children}
                
                <Text
                    size={"size1"}
                    style={"italic"}
                    class_name_list={[CSS.l_date]}
                >
                    {date}
                </Text>
            </div>
        </div>
    )
}

export default PhotoCardInfoTextWrap;
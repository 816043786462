import { useContext, useEffect, useState } from "react";
import ThemeCardBlock from "./components/ThemeCardBlock";
import WideCard from "../../Component/Elements/WideCard/WideCard";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import { themaBookPhotoListInterface, themaBookDetailListInterface } from "./static/interface/themeBook.interface";
import { useNavigate } from "react-router-dom";
import RadioBtnWrap from "../../Component/Elements/RadioBtnWrap/RadioBtnWrap";
import Grid from "../../Component/Elements/Grid/Grid";
import CSS from "./static/css/themeBook.module.css";
import LoopAnimation from "../../Component/Elements/LoopAnimation/LoopAnimation";
import ThemaDetailPageModal from "./components/ThemaDetailPageModal";
import ThemeDetailModalBtnContainer from "./components/ThemeDetailModalBtnContainer";
import CardContainer from "../../Component/Elements/CardContainer/CardContainer";
import { getCookie } from "../../Common/ts/appTools";

const ThemeBook = (
    {
        photoProductList,
        selectedPhotoProduct,
        setSelectedPhotoProduct,
        themeList,
        isLoading,
        children,
    }:{
        photoProductList: themaBookPhotoListInterface[];
        selectedPhotoProduct: themaBookPhotoListInterface | null;
        setSelectedPhotoProduct: React.Dispatch<React.SetStateAction<themaBookPhotoListInterface | null>>
        themeList: themaBookDetailListInterface[];
        isLoading: boolean;
        children?: React.ReactNode;
    }
) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modelTheme, setModelTheme] = useState<themaBookDetailListInterface | null>(null);
    const [viewMode, setViewMode] = useState<number>(0);
    const [filtedSubThemeList, setFiltedSubThemeList] = useState<themaBookDetailListInterface[]>([]);

    const navigate = useNavigate();

    const user_type = getCookie("user_type");

    useEffect(() => {
        if(user_type === "E"){
            if(viewMode === 0){
                setFiltedSubThemeList(themeList);
            }else if(viewMode === 1){
                setFiltedSubThemeList(themeList.filter((item) => item.content));
            }else if(viewMode === 2){
                setFiltedSubThemeList(themeList.filter((item) => !item.content));
            }
        }else if(user_type === "C"){
            setFiltedSubThemeList(themeList.filter((item) => item.content));
        }
    }, [themeList, viewMode, user_type])

    // 관리자 계정일 때 신규 글 작성버튼 온클릭 이벤트
    const handleOnNewContent = (selected_sub_theme: themaBookDetailListInterface) => {
        sessionStorage.setItem("selectedSubTheme", JSON.stringify(selected_sub_theme));
        navigate("/ThemeBookManagement");
    };

    const handleOnGoToDetailPage = (selected_sub_theme: themaBookDetailListInterface) => {
        setModelTheme(selected_sub_theme);
        setIsModalOpen(true);
    }

    const modalCloseHandler = () => {
        setIsModalOpen(false);
    }

    return (
        <main className={CSS.l_theme_book_main}>
            <div className="g_title">테마북</div>
            {children}
            <div className={CSS.l_theme_book__contents}>
                <CardContainer class_name_list={[CSS.l_theme_selecet_contents]}>
                    {
                        photoProductList.length > 0
                        ?   <TitleBtnWrap<themaBookPhotoListInterface | null>
                                valueState={selectedPhotoProduct}
                                setValueState={setSelectedPhotoProduct}
                                value_list={photoProductList}
                                innerText_list={photoProductList.map((item) => item.photo_name)}
                                is_activated={true}
                                is_print_badge_list={[]}
                                is_loading={isLoading}
                            />
                        :   null
                    }
                    
                    {
                        user_type === "E"
                        &&  <RadioBtnWrap 
                                item_list={[
                                    {
                                        label: "전체",
                                        value: 0,
                                    },
                                    {
                                        label: "내용있음",
                                        value: 1,
                                    },
                                    {
                                        label: "미작성",
                                        value: 2,
                                    },
                                ]}
                                name={"view_mode"}
                                onChange={(e) => {setViewMode(parseInt(e.target.value))}}
                                value={viewMode}
                            />
                    }

                    <div className={`g_card ${CSS.l_theme_list_container}`}>
                        {
                            isLoading
                            ?   <WideCard>
                                    <LoopAnimation />
                                </WideCard>
                            :   filtedSubThemeList.length > 0
                                ?   <Grid>
                                        {
                                            filtedSubThemeList.map((item, index) => {
                                                if(item.content && item.content.length > 0){
                                                    return (
                                                        <ThemeCardBlock 
                                                            key={index}
                                                            img_src={item.thema_thumbnail ? item.thema_thumbnail.middle_url : ""}
                                                            theme_name={item.photo_name}
                                                            title={item.thema_name}
                                                            office_name={item.office_name}
                                                            onClick={handleOnGoToDetailPage.bind(null, item)}
                                                            is_empty_content={false}
                                                        />
                                                    )
                                                }else{
                                                    if(user_type === "E"){
                                                        return (
                                                            <ThemeCardBlock 
                                                                key={index}
                                                                img_src={item.thema_thumbnail ? item.thema_thumbnail.middle_url : ""}
                                                                theme_name={item.photo_name}
                                                                title={item.thema_name}
                                                                office_name={item.office_name}
                                                                onClick={handleOnNewContent.bind(null, item)}
                                                                is_empty_content={true}
                                                            />
                                                        );
                                                    }else{
                                                        return null;
                                                    }
                                                }
                                            })
                                        }
                                    </Grid>
                                :   <WideCard>
                                        테마 정보가 없습니다.
                                    </WideCard>
                        }
                    </div>
                </CardContainer>
            </div>
            <ThemaDetailPageModal 
                isModalOpen={isModalOpen}
                modalCloseHandler={modalCloseHandler}
                photo_name={modelTheme?.photo_name || ""}
                title={modelTheme?.thema_name || ""}
                branch_name={modelTheme?.office_name || ""}
                content={modelTheme?.content || ""}
            >
                {
                    user_type === "E"
                    &&  <div className={CSS.l_theme_selecet_contents__btn_container}>
                            <ThemeDetailModalBtnContainer 
                                themeData={modelTheme}
                                isInEditPage={false}
                                closeModalHandler={modalCloseHandler}
                            />
                        </div>
                }
            </ThemaDetailPageModal>
        </main>
    )
}

export default ThemeBook;
import CSS from "./static/css/TextLineContainer.module.css";

const TextLineContainer = (
    {
        children,
    }:{
        children?: React.ReactNode;
    }
) => {
    return(
        <div className={CSS.l_text_line_container_main}>
            {children}
        </div>
    )
}

export default TextLineContainer;
import { useContext, useEffect, useState } from "react";
import { commonContext } from "../../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { imageDataInterface, locationStateDataInterface, photoDetailDataInterface } from "../static/interface/PhotoDetail.interface";
import { callAxios, dev_console } from "../../../Common/ts/commonTools";
import useDateString from "../../../Common/hooks/useDateString.hook";

const usePhotoDetailDataREST = () => {
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<photoDetailDataInterface | null>(null);
    const [seletedData, setSeletedData] = useState<imageDataInterface |null>(null);
    const [isPrintDownloadBtn, setIsPrintDownloadBtn] = useState<boolean>(false);
    const [isPrintDownloadBtnBadge, setIsPrintDownloadBtnBadge] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const { dateString, setOriginDateData } = useDateString(null);
    const { dateString: uploadDateString, setOriginDateData: setUploadOriginDateData } = useDateString(null);

    const common_context = useContext(commonContext);
    const navigate = useNavigate();

    const location = useLocation();
    const location_state_data = location.state as locationStateDataInterface;

    if(!location_state_data || !location_state_data.selected_photo_upload_id || !location_state_data.main_type){
        navigate("/photoList");
    }

    let download_type_code = 0;
    if(parseInt(location_state_data.main_type) === 1){
        download_type_code = 1;
    }else if(parseInt(location_state_data.main_type) === 2){
        download_type_code = 3;
    }

    let photo_title: string = location_state_data.viewRange_type === "1" ? "촬영명" : "상품명";

    let isEventApplication: boolean = false;
    if(location_state_data.main_type === "1" && location_state_data.viewRange_type === "1"){
        isEventApplication = true;
    }

    useEffect(() => {
        setIsLoading(true);
        const photo_detail_url = `api/v3/customers/photo_detail?photo_upload_id=${location_state_data.selected_photo_upload_id}&main_type=${location_state_data.main_type}`;
        if(common_context.activeRequestsManager){
            common_context.activeRequestsManager.add(photo_detail_url);
        }
        callAxios.api({
            url: photo_detail_url,
            method: "get",
        })
        .then((response) => {
            dev_console.log(photo_detail_url);
            dev_console.log(response);

            if(response.status === 200){
                if(response.data.datas){
                    const response_data:photoDetailDataInterface[] = response.data.datas;
                    if(response_data.length > 0){
                        const data = response_data[0];
                        setData(data);
                        setOriginDateData(data.download_at);
                        setUploadOriginDateData(data.upload_date);

                        if(data && data.img_list && data.img_list.length > 0){
                            setSeletedData(data.img_list[0]);
                        }

                        let state: number = 0;
                        if(!data.download_at || data.download_at.length === 0){
                            if(location_state_data.main_type === "1"){
                                if(location_state_data.viewRange_type === "1" || location_state_data.viewRange_type === "3"){
                                    state = 1;
                                }
                            }else if(location_state_data.main_type === "2"){
                                state = 1;
                            }
                        }

                        switch(state){
                            case 1:
                                setIsPrintDownloadBtnBadge(true);
                                break;
                            default:
                                ;
                        }

                        let is_print_download_btn: boolean = false;
                        if(data && data.img_list && data.img_list.length > 0 && data.s3_url){
                            is_print_download_btn = true;
                        }
                        setIsPrintDownloadBtn(is_print_download_btn)

                    }else{
                        setData(null);
                    }
                }else{
                    setData(null);
                }
            }else{
                alert("촬영 사진의 데이터 요청 처리중에 문제가 발생했습니다.");
            }
        })
        .catch((error) => {
            dev_console.error(error);
            alert("촬영 사진의 데이터 요청 중 문제가 발생했습니다.");
            sessionStorage.removeItem("selected_photo_upload_id");
            navigate("/photoList");
        })
        .finally(() => {
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.sub(photo_detail_url);
            }
            setIsLoading(false);
        })

        return (() => {
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.clear();
            }

            sessionStorage.removeItem("selected_photo_upload_id");
            sessionStorage.removeItem("main_type");
        })
    }, [])

    useEffect(() => {
        if(data && data.img_list && data.img_list.length > 0){
            setSeletedData(data.img_list[selectedIndex])
        }
    }, [selectedIndex, data])

    return({
        isLoading,
        data,
        seletedData,
        selectedIndex, 
        setSelectedIndex,
        dateString,
        uploadDateString,
        download_type_code,
        photo_title,
        photo_type: location_state_data.photo_type,
        isEventApplication,
        isPrintDownloadBtnBadge,
        isPrintDownloadBtn,
    })
}

export default usePhotoDetailDataREST;
import { selectedThemeListInterface } from "../static/interface/selectTheme.interface";
import ThemeList from "./element/ThemeList";
import CSS from "../static/css/ThemeListContainer.module.css"
import WideCard from "../../../Component/Elements/WideCard/WideCard";

const ThemeListContainer = (
    {
        isReadOnly,
        selectedThemeList,
        setSelectedThemeList,
    }:{
        isReadOnly: boolean;
        selectedThemeList: selectedThemeListInterface[];
        setSelectedThemeList: React.Dispatch<React.SetStateAction<selectedThemeListInterface[]>>;
    }
) => {

    return(
        <div
            className={CSS.l_theme_list_container_main}
        >

            {
                selectedThemeList.length > 0
                ?   selectedThemeList.map((selected_theme_item, index) => {
                        return(
                            <ThemeList 
                                key={index}
                                isReadOnly={isReadOnly}
                                selectedTheme={selected_theme_item}
                                setSelectedThemeList={setSelectedThemeList}
                            />
                        )
                    })
                :   <WideCard>
                        테마를 선택해야할 촬영상품이 없습니다.
                    </WideCard>
            }
        </div>
    )
}

export default ThemeListContainer;
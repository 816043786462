import CSS from "../static/css/ThemeDetailModalBtnContainer.module.css"
import Button from "../../../Component/Elements/Button/Button";
import { themaBookDetailListInterface } from "../static/interface/themeBook.interface";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { commonContext } from "../../../App";
import { callAxios, dev_console, isSuccess, printStateMsg } from "../../../Common/ts/commonTools";
import LoopAnimationModal from "../../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import { getDifferentImageUrls, removeS3ImageList } from "../../../Component/Elements/TextEditer/static/ts/tools";

const ThemeDetailModalBtnContainer = (
    {
        themeData,
        isInEditPage,
        removedImageUrlListRef,
        mainContent,
        closeModalHandler,
    }:{
        themeData:themaBookDetailListInterface | null;
        isInEditPage: boolean;
        removedImageUrlListRef?: React.MutableRefObject<string[]>
        mainContent?: string;
        closeModalHandler?: React.MouseEventHandler<HTMLButtonElement>;
    }
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const common_context = useContext(commonContext);

    const editBtnHandler = () => {
        if(themeData){
            sessionStorage.setItem("selectedSubTheme", JSON.stringify(themeData));
            navigate("/ThemeBookManagement");
        }
    }

    // 저장
    const handleOnUploadContent = () => {
        if(common_context.activeRequestsManager &&common_context.activeRequestsManager.status()){
            alert("진행중인 다른 요청이 있습니다.\n 잠시만 기다려주세요.")
            return ;
        }

        if(!window.confirm("저장하시겠습니까?")){
            return ;
        }

        const config = {
            type: themeData?.type,
            content: mainContent,
            thema_id: themeData?.thema_id
        }

        setIsLoading(true);
        const thema_book_content_update = "api/v3/customers/thema-book-content-update";
        if(common_context.activeRequestsManager){
            common_context.activeRequestsManager.add(thema_book_content_update);
        }

        dev_console.log(config);

        callAxios.api({
            url: thema_book_content_update,
            method: "post",
            dataType: "json",
            data: config,
        })
        .then((response) => {
            dev_console.log(thema_book_content_update);
            dev_console.log(response);
            
            if(isSuccess(response)){

                if(removedImageUrlListRef){

                    // 삭제된 이미지들을 클라우드에서도 제거한다.
                    removeS3ImageList(removedImageUrlListRef.current)
                    .then(() => {
                        dev_console.log("all remove clear!");
                    })
                    .catch((error) => {
                        dev_console.error(error);
                    })
                    .finally(() => {
                        // navigate(`/themeBookDetailPage?type=${themeData?.type}&office_id=${themeData?.office_id}&photo_id=${themeData?.photo_id}&thema_id=${themeData?.thema_id}`);
                        navigate("/themeBook");
                    })
                }

            }else{
                printStateMsg(response);
                alert("저장에 실패했습니다.")
            }
        })
        .catch((error) => {
            dev_console.error(error);
            alert("저장에 실패했습니다.")
        })
        .finally(() => {
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.sub(thema_book_content_update);
            }
            setIsLoading(false);
        })
    }

    const deleteBtnHandler = () => {
        if(themeData){
            if(window.confirm("게시글이 삭제됩니다. 삭제를 진행하시겠습니까?")){
                const config = {
                    type: themeData.type,
                    content: "",
                    thema_id: themeData.thema_id
                }
        
                setIsLoading(true);
                const thema_book_content_update = "api/v3/customers/thema-book-content-update";
                if(common_context.activeRequestsManager){
                    common_context.activeRequestsManager.add(thema_book_content_update);
                }
                callAxios.api({
                    url: thema_book_content_update,
                    method: "post",
                    dataType: "json",
                    data: config,
                })
                .then((response) => {
                    dev_console.log(thema_book_content_update);
                    dev_console.log(response);
                    
                    if(isSuccess(response)){
                        navigate("/themeBook");
                    }else{
                        printStateMsg(response);
                        alert("게시글 삭제에 실패했습니다.");
                    }
                })
                .catch((error) => {
                    dev_console.error(error);
                    alert("게시글 삭제에 실패했습니다.")
                })
                .finally(() => {
                    if(common_context.activeRequestsManager){
                        common_context.activeRequestsManager.sub(thema_book_content_update);
                    }
                    setIsLoading(false);
                })
            }
        }
    }

    const cancelHandler = () => {
        if(window.confirm("저장하지 않은 내용들은 반영되지 않습니다.")){

            const addedImageList = getDifferentImageUrls({
                origin: themeData?.content || "",
                newContent: mainContent || "",
                conditions: "added",
            });

            dev_console.log(addedImageList);

            removeS3ImageList(addedImageList)
            .then(() => {
                dev_console.log("all remove clear!");
            })
            .catch((error) => {
                dev_console.error(error);
            })
            .finally(() => {
                // navigate(`/themeBookDetailPage?type=${themeData?.type}&office_id=${themeData?.office_id}&photo_id=${themeData?.photo_id}&thema_id=${themeData?.thema_id}`);
                navigate("/themeBook")
            })
        }
    }

    if(isInEditPage){
        return(
            <div className={CSS.l_theme_detail_modal_btn_container_main}>
                    <Button 
                        onClick={deleteBtnHandler}
                        size={"size1"}
                        purpose={"delete"}
                    >
                        삭제
                    </Button>
                    <Button
                        onClick={cancelHandler}
                        size={"size1"}
                        purpose={"cancel"}
                    >
                        취소
                    </Button>
                    <Button
                        onClick={handleOnUploadContent}
                        size={"size1"}
                        purpose={"save"}
                    >
                        저장
                    </Button>
                    <LoopAnimationModal
                        isModalOpen={isLoading}
                    >
                    </LoopAnimationModal>
            </div>
        )
    }else{
        return(
            <div className={CSS.l_theme_detail_modal_btn_container_main}>
                <Button 
                    onClick={editBtnHandler}
                    size={"size1"}
                >
                    수정
                </Button>
                <Button 
                    onClick={closeModalHandler}
                    size={"size1"}
                    purpose={"close"}
                >
                    닫기
                </Button>
            </div>
        )
    }
}

export default ThemeDetailModalBtnContainer;
import { useEffect, useRef, useState } from "react";
import Hammer from "hammerjs";

import CSS from "./static/css/swipeLine.module.css"

import left_move_arrow from "../../../Common/image/button/left_move_arrow.svg";
import right_move_arrow from "../../../Common/image/button/right_move_arrow.svg";

// 화살표 버튼을 눌렀을 때 이동할 거리
const SWIPE_DISTANCE = 200;

const SwipeLine = (
    {
        children,
        class_name_list,
        web_arrow_size,
        is_key_move_activate,
    }:{
        children: React.ReactNode;
        class_name_list?: string[];
        web_arrow_size?: "full" | "middel" | "small";
        is_key_move_activate?: boolean,
    }
) => {
    
    const [totalclassName, setTotalClassName] = useState<string>("");
    const [translateX, setTranslateX] = useState<number>(0);  // 현재 X 축 위치 상태
    const [maxTranslateX, setMaxTranslateX] = useState<number>(0);
    const [isOverFlow, setIsOverFlow] = useState<boolean>(false);

    const main_ref = useRef<HTMLDivElement>(null); 
    const ref = useRef<HTMLDivElement>(null);
    const data_ref = useRef<HTMLDivElement>(null);

    // 데바운싱 함수 -> 이벤트가 감지되었을때 1초정도 가만히 지켜보게함 -> 윈도우가 움직이는동안 발생하는것 아니라 움직임이 완전히 멈추고난 후 이벤트가 동작되게 컨트롤 가능
    const debounce = (func: (...args: any[]) => void, delay: number) => {
        let timeout: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const updateDimensions = debounce(() => {
        const main_ref_element = main_ref.current;
        const ref_element = ref.current;
        const data_ref_element = data_ref.current;

        if (main_ref_element && ref_element && data_ref_element) {
            // 항목의 크기만큼 컴포넌트 자체의 높이를 높인다.
            const l_swipe_item_list = data_ref_element.querySelectorAll<HTMLDivElement>(".l_swipe_item");
            let max_height = 0;

            l_swipe_item_list.forEach((item) => {
                if (item.offsetHeight > max_height) {
                    max_height = item.offsetHeight + 10;
                }
            });

            main_ref_element.style.height = `${max_height}px`;
            data_ref_element.style.height = `${max_height}px`;
            ref_element.style.height = `${max_height}px`;

            // 오버플로우가 발생하는 상황인지 감지한다.
            setIsOverFlow(data_ref_element.offsetWidth > ref_element.offsetWidth);

            // 항목이 최대로 이동 가능한 거리를 계산한다.
            setMaxTranslateX(data_ref_element.clientWidth - ref_element.clientWidth);
        }
    }, 100); // 100ms의 지연시간으로 디바운싱

    const handleNext = () => {
        if(isOverFlow){
            setTranslateX((translate_x) => Math.max(translate_x - SWIPE_DISTANCE, -maxTranslateX));
        }
    };

    const handlePrev = () => {
        if(isOverFlow){
            setTranslateX((translate_x) => Math.min(translate_x + SWIPE_DISTANCE, 0));
        }
    };

    useEffect(() => {
        const total_class_name_list: string[] = []

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_swipe_line_main);
        setTotalClassName(total_class_name_list.join(" "));    
    }, [class_name_list])

    useEffect(() => {
        const main_ref_element = main_ref.current;
        const ref_element = ref.current;
        const data_ref_element = data_ref.current;

        if (main_ref_element && ref_element && data_ref_element) {
            updateDimensions();

            const resizeObserver = new ResizeObserver(() => {
                updateDimensions();
            });

            resizeObserver.observe(main_ref_element);
            resizeObserver.observe(ref_element);
            resizeObserver.observe(data_ref_element);

            return () => {
                resizeObserver.unobserve(main_ref_element);
                resizeObserver.unobserve(ref_element);
                resizeObserver.unobserve(data_ref_element);
                resizeObserver.disconnect();
            };
        }
    }, [children]);

    useEffect(() => {
        if (ref.current) {
            const hammertime = new Hammer(ref.current);

            hammertime.get("pan").set({ direction: Hammer.DIRECTION_HORIZONTAL });

            hammertime.on("panleft panright", (e: HammerInput) => {
                if (isOverFlow) {
                    setTranslateX((translate_x) => {
                        const result = translate_x + e.deltaX / 5;

                        if (result > 0) {
                            return 0;
                        } else if (result < -maxTranslateX) {
                            return -maxTranslateX;
                        } else {
                            return result;
                        }
                    });
                }
            });

            return () => {
                hammertime.destroy();
            };
        }
    }, [maxTranslateX, isOverFlow]);

    // 키보드 이벤트 리스너 추가
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "ArrowLeft") {
                handlePrev();
            } else if (event.key === "ArrowRight") {
                handleNext();
            }
        };

        if(is_key_move_activate){
            window.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if(is_key_move_activate){
                window.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [handlePrev, handleNext, is_key_move_activate])

    return (
        <div 
            ref={main_ref}
            className={totalclassName}
        >
            {
                isOverFlow
                &&  <div
                        className={`${CSS.l_move_btn_container} ${CSS.left}`}
                        onClick={handlePrev}
                    >
                        <img
                            className={
                                web_arrow_size
                                ?   `${CSS.l_arrow_img} ${CSS.size} ${CSS.web} ${CSS[web_arrow_size]}`
                                :   `${CSS.l_arrow_img}`
                            }
                            src={left_move_arrow}
                        />
                    </div>
            }
            <div 
                ref={ref}
                className={`${CSS.l_swipe_view}`}
            >
                <div
                    ref={data_ref}
                    className={`${CSS.l_swipe_data}`}
                    style={{
                        transform: `translateX(${translateX}px)`,  // translateX 상태에 따라 위치 변경
                    }}
                >
                    {children}
                </div>
            </div>
            {
                isOverFlow
                &&  <div
                        className={`${CSS.l_move_btn_container} ${CSS.right}`}
                        onClick={handleNext}
                    >
                        <img 
                            className={
                                web_arrow_size
                                ?   `${CSS.l_arrow_img} ${CSS.size} ${CSS.web} ${CSS[web_arrow_size]}`
                                :   `${CSS.l_arrow_img}`
                            }
                            src={right_move_arrow}
                        />
                    </div>
            }
        </div>
    )
}

export default SwipeLine
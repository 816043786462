import { useEffect, useState } from "react";
import CSS from "./static/css/VideoPlayer.module.css";
import { dev_console } from "../../../Common/ts/commonTools";
import Text from "../Text/Text";

const VideoPlayer = (
    {
        src,
        video_controls = true,
    }:{
        src?: string;
        video_controls?: boolean,
    }
) => {
    const [videoError, setVideoError] = useState(false); // 비디오 로드 오류 상태

    const handleVideoError = () => {
        setVideoError(true); // 비디오 로드 오류 시 상태 업데이트
    };

    return(
        <div className={CSS.l_video_player_main}>
            {
                videoError && video_controls === false
                ?   <div>
                        <Text>
                            동영상
                        </Text>
                    </div>
                :   <video 
                        className={CSS.l_video_player__video}
                        controls={video_controls}
                        onError={handleVideoError}
                    >
                        <source src={src} type="video/mp4"/>
                    </video>
            }
        </div>
    )
}

export default VideoPlayer;
import ReactQuill from "react-quill";
import { callAxios, dev_console, isSuccess, printStateMsg } from "../../../../Common/ts/commonTools";

export const setImgTag = (
    {
        quillRef,
        url,
        width,
    }:{
        quillRef:React.MutableRefObject<ReactQuill | undefined>;
        url: string;
        width: string
    }
) => {
    // 커서의 위치를 알고 해당 위치에 이미지 태그를 넣어주는 코드 
    // 해당 DOM의 데이터가 필요하기에 useRef를 사용한다.
    // 커서 위치 가져오기
    let range = quillRef.current?.getEditor().getSelection()?.index;

    // 커서 위치가 없으면 마지막 위치로 설정
    if (range === null || range === undefined) {
        range = quillRef.current?.getEditor().getLength();
    }

    if (range !== null && range !== undefined) {

        let quill = quillRef.current?.getEditor();

        quill?.setSelection(range, 1);

        quill?.clipboard.dangerouslyPasteHTML(
            range,
            `<img src=${url} width=${width}/>`
        );

        // 커서를 이미지 뒤로 이동시키고, 선택을 제거함
        quill?.setSelection(range + 1, 0); 

        // 이미지 뒤에 줄바꿈 추가 (필요시)
        quill?.insertText(range + 1, '\n');
    }
}

export const textEditerHandleImageUpload = async (formData: FormData, quillRef:React.MutableRefObject<ReactQuill | undefined>, width: string) => {
    
    // formData 구성
    // office_id: 지점 id
    // thema_id: 테마 id -> 테마북 외의 공간에서 사용할때는 0으로 처리
    // type: 테마북의 detail list에서 보여주는 type -> 테마북 외의 공간에서 사용할때는 0으로 처리
    // file_field: 이미지파일
    
    const manager_image_url = 'api/v3/customers/thema-book-content-img-upload';
    const result = await callAxios.api({
        url: manager_image_url,
        method: 'post',
        dataType: 'formdata',
        data: formData
    })
    if(result){
        dev_console.log(manager_image_url);
        dev_console.log(result);

        if(result.status === 200 && result.data.status === "OK"){

            const url = result.data.img_url;

            setImgTag({
                quillRef: quillRef,
                url: url,
                width: width
            })
            // return { ...result, success: true };
        }else{
            printStateMsg(result);
            alert("사진 추가에 실패했습니다.");
            // return { ...result, success: false };
        }
    }else{
        printStateMsg(result);
        alert("사진 추가에 실패했습니다.");
        // return { ...result, success: false };
    }
}
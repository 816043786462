import { useEffect, useState } from "react";
import Badge from "../../Badge/Badge";
import CSS from "../static/css/swipeTextItem.module.css"

const SwipeTextItem = (
    {
        text,
        onClick,
        is_highlight,
        is_print_badge,
        disabled,
    }:{
        text: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_highlight?: boolean;
        is_print_badge: boolean;
        disabled?: boolean;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const class_name_list: string[] = [];

        if(onClick){
            class_name_list.push("g_click_event_item");
        }

        class_name_list.push(CSS.l_swipe_text_item_main);
        
        if(is_highlight){
            class_name_list.push(CSS.l_item_highlight);
        }

        if(disabled){
            class_name_list.push(CSS.l_item_disabled);
        }

        setTotalClassName(class_name_list.join(" "));
    }, [onClick, disabled, is_highlight])

    return (
        <Badge
            is_print_badge={is_print_badge}
            class_name_list={["l_swipe_item"]}
        >
            <div
                className={totalClassName}
                onClick={onClick}
            >
                {text}
            </div>
        </Badge>
    )
}

export default SwipeTextItem;
import { useEffect, useState } from "react";
import CSS from "../../static/css/dday.module.css";
import { calculatePeriod } from "../../../../Common/ts/commonTools";

const Dday = (
    {
        d_day,
        onClick,
    }:{
        d_day: number;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
    }
) => {
    const [dayText, setDayText] = useState<string>("");
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {

        const class_name_list: string[] = ["g_text", "size1"];
        
        if(onClick){
            class_name_list.push("g_click_event_item");
        }

        class_name_list.push(CSS.l_d_day_main);

        if(!isNaN(d_day)){

            if(d_day > 0){
                class_name_list.push(CSS.before);
            }else if(d_day === 0){
                class_name_list.push(CSS.dday);
            }else{
                class_name_list.push(CSS.after);
            }
        
            // calculatePeriod함수는 d_day가 음수면 미래시간, 양수면 과거의 시간대라고 설정해뒀으므로 해당 부분을 맞춰준다.
            setDayText(calculatePeriod(d_day * -1));
        }
        setTotalClassName(class_name_list.join(" "))

    }, [onClick, d_day])

    return (
        <div 
            className={totalClassName}
            onClick={onClick}
        >
            {
                dayText   
            }
        </div>
    )
}

export default Dday;
import CSS from "../static/css/calendarTitle.module.css";

const CalendarTitle = (
    {
        title,
    }:{
        title: string;
    }
) => {
    return(
        <div
            className={`g_text size6 bold ${CSS.l_calendar_title_main}`}
        >
            {title}
        </div>
    )
}

export default CalendarTitle;
import InfoDataTextWrap, { infoDataElementInterface } from "../InfoDataTextWrap/InfoDataTextWrap";
import CSS from "./static/css/dataInfo.module.css"
import CardContainer from "../CardContainer/CardContainer";

const DataInfo = (
    {
        infoData,
    }:{
        infoData: infoDataElementInterface[];
    }
) => {
    return (
        <CardContainer
            class_name_list={[CSS.l_data_info_main]}
        >                
    
            <div className={CSS.l_info_text_container}>
                {
                    infoData.map((item) => {
                        return (
                            <InfoDataTextWrap 
                                label_text={item.title} 
                                value_text={item.value}
                                is_bold={item.is_bold}
                            />
                        );
                    })
                }
            </div>
        </CardContainer>
    )
}

export default DataInfo;
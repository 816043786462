import { useEffect, useState } from "react"
import { isAbleAccesser } from "../../Common/ts/commonTools"
import WideCard from "../../Component/Elements/WideCard/WideCard";
import ImageBlock from "./components/ImageBlock";
import TextFlexWrap from "../../Component/Elements/TextFlexWrap/TextFlexWrap";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import Media from "../../Component/Elements/Media/Media";
import CSS from "./static/css/photoDetail.module.css";
import DownloadBtnContainer from "./components/DownloadBtnContainer";
import CustomerConfirm from "./components/CustomerConfirm";
import EventApplication from "./components/EventApplication";
import { getCookie } from "../../Common/ts/appTools";
import PhotoListSwipeLine from "../../Component/Elements/PhotoListSwipeLine/PhotoListSwipeLine";
import usePhotoDetailDataREST from "./hooks/usePhotoDetailDataREST.hook";
import Tooltip from "../../Component/Elements/Tooltip/Tooltip";
import Text from "../../Component/Elements/Text/Text";

const PhotoDetail = () => {

    const {
        isLoading,
        data,
        seletedData,
        selectedIndex, 
        setSelectedIndex,
        dateString,
        uploadDateString,
        download_type_code,
        photo_title,
        photo_type,
        isEventApplication,
        isPrintDownloadBtnBadge,
        isPrintDownloadBtn,
    } = usePhotoDetailDataREST();

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    useEffect(() => {
        isAbleAccesser(["C"]);
    }, [])

    return (
        <div className={CSS.l_photo_detail_main}>
            <div className={CSS.l_left_wrap}>
                <div className={`g_card ${CSS.l_left_wrap__top_wrap}`}>
                    <Media
                        origin_src={seletedData?.img_url}
                        src={seletedData?.middle_url}
                        object_fit={"contain"}
                    />
                    <div className={CSS.l_image_name_container}>
                        {seletedData?.file_name}
                    </div>
                </div>
                <div className={CSS.l_left_wrap__bottom_wrap}>
                    {
                        data && data.img_list && data.img_list.length > 0
                        ?   <>
                                <div className={`g_display_none_object flex mobile ${CSS.l_photo_list_swipe_line_tooltip_container}`}>
                                    <Tooltip
                                        isUseIcon={true}
                                        tooltip_node={
                                            <Text>
                                                {"* 키보드 화살표키(← →) 혹은 < > 버튼으로 다른 사진을 선택할 수 있습니다."}
                                            </Text>
                                        }
                                        max_width={"auto"}
                                    >
                                        조작법
                                    </Tooltip>
                                </div>
                                <PhotoListSwipeLine 
                                    children_list={
                                        data.img_list.map((item, index) => {
                                            return (
                                                <ImageBlock
                                                    origin_src={item.img_url}
                                                    file_type={item.file_type}
                                                    file_name={item.file_name}
                                                    key={index}
                                                    src={item.small_url} 
                                                    onClick={() => {
                                                        setSelectedIndex(index);
                                                    }}
                                                    is_selectd={item.photo_id === seletedData?.photo_id}
                                                />
                                            )
                                        })
                                    }
                                    selectedIndex={selectedIndex}
                                    setSelectedIndex={setSelectedIndex}
                                    arrow_size={"middel"}
                                />
                            </>
                        :   <WideCard>
                                사진 정보가 없습니다.
                            </WideCard>
                    }
                </div>
            </div>
            <div className={CSS.l_right_wrap}>
                {
                    data
                    ?   <>
                            <div className={`g_card ${CSS.l_info_container}`}>
                                {
                                    data.photo_shoot_name
                                    &&  <TextFlexWrap 
                                            title={photo_title}
                                            text_list={[data.photo_shoot_name]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }

                                {
                                    data.schedule_date
                                    &&  <TextFlexWrap 
                                            title={"촬영일"}
                                            text_list={[data.schedule_date]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }

                                {
                                    (data.start_time || data.end_time)
                                    &&  <TextFlexWrap 
                                            title={"촬영시간"}
                                            text_list={[`${data.start_time || ""}`, "-", `${data.end_time || ""}`]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }

                                {
                                    data.office_name
                                    &&  <TextFlexWrap 
                                            title={"촬영지점"}
                                            text_list={[data.office_name]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }

                                {
                                    data.package_name
                                    &&  <TextFlexWrap 
                                            title={"패키지명"}
                                            text_list={[data.package_name]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }

                                {
                                    data.contract_id
                                    &&  <TextFlexWrap 
                                            title={"계약번호"}
                                            text_list={[`${data.contract_id}`]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }

                                {
                                    data.img_list
                                    &&  <TextFlexWrap 
                                            title={"파일 수"}
                                            text_list={[`${data.img_list.length}`]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }

                                {
                                    uploadDateString
                                    &&  <TextFlexWrap 
                                            title={"업로드 일자"}
                                            text_list={[uploadDateString]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }

                                {
                                    data.download_at
                                    &&  <TextFlexWrap 
                                            title={"다운로드 일자"}
                                            text_list={[dateString]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }
                                
                                {
                                    data.memo
                                    &&  <TextFlexWrap 
                                            title={"메모"}
                                            text_list={[data.memo || ""]}
                                            flex_direction={"column"}
                                            justify_content={"flex_start"}
                                        />
                                }
                            </div>

                            {
                                isEventApplication
                                &&  <EventApplication 
                                        schedule_id={`${data.schedule_id || ""}`}
                                        upload_date={data.upload_date}
                                        joined_event={data.joined_event}
                                    />
                            }
                            
                            <CustomerConfirm
                                photo_upload_id={`${data.photo_upload_id || "0"}`}
                                photo_type={photo_type}
                                customer_confirmed={data.customer_confirmed}
                                manager_confirmed={data.manager_confirmed}
                                confirm_reason={data.confirm_reason}
                                request_num={data.request_num}
                                contract_product_id={data.contract_product_id}
                            />

                            {
                                isPrintDownloadBtn
                                &&  <DownloadBtnContainer 
                                        download_type={download_type_code}
                                        s3_url={data.s3_url || ""}
                                        file_name={`${data.schedule_date}-${getCookie("name")}-${data.photo_shoot_name}`}
                                        upload_date={data.upload_date}
                                        isDownloading={isDownloading}
                                        setIsDownloading={setIsDownloading}
                                        is_print_badge={isPrintDownloadBtnBadge}
                                    />
                            }
                        </>
                    :   null
                }
            </div>

            {/* 다운로드가 진행될 때 출력되는 모달 */}
            <LoopAnimationModal 
                isModalOpen={isDownloading}
            >
                <div className={`g_text g_text color white`}>
                    다운로드를 준비중입니다.
                </div>
                <div className={`g_text g_text color white`}>
                    잠시만 기다려주세요.
                </div>
            </LoopAnimationModal>

            {/* 첫 로딩때 출력되는 모달 */}
            <LoopAnimationModal
                isModalOpen={isLoading}
            />
        </div>
    )
}

export default PhotoDetail
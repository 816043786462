import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import edrp_oops_logo from "../../Common/image/EDRP-OOPS-02.png"

import hamberger_icon from "./static/img/menu-01.svg";

import { commonContext } from "../../App";

import { getCookie, logout } from "../../Common/ts/appTools";

import CSS from "./static/css/header.module.css";
import Button from "../../Component/Elements/Button/Button";
import UserIcon from "../../Component/Elements/Icon/UserIcon";
import { isNotPrintModeView, isUseTestData, whatIsMode } from "../../Common/ts/commonTools";
import HistoryBackBtn from "./components/HistoryBackBtn";
import { getBackPath } from "./static/ts/Header.tools";

const Header = (
    {
        setIsOpenMenu,
    }:{
        setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
    }
) => {

    const location = useLocation();

    const common_context = useContext(commonContext);
    const navigate = useNavigate();

    const user_type = getCookie("user_type");
    const office_name = getCookie("office_name");

    const result = whatIsMode();

    const logoutBtnHandler = () => {
        if(!window.confirm("로그아웃 하시겠습니까?")){
            return ;
        }

        logout();
    }

    return (
        <header className={CSS.l_header_main}>
        
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,

                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                }}
            >
                {
                    result.is_not_product_mode_safe && !isNotPrintModeView()
                    &&  <div 
                            className={"g_text size7 color mint"}
                        >
                            {result.mode_name}
                        </div>
                }

                {
                    isUseTestData() && !isNotPrintModeView()
                    &&  <div 
                            className={"g_text size7 color mint"}   
                        >
                            테스트데이터 적용중
                        </div>
                }

                {
                    result.is_not_product_mode_safe && !isNotPrintModeView()
                    &&  <div 
                            className={"g_text size7 color mint"} 
                        >
                            {result.get_api_url}
                        </div>
                }
            </div>

            <div className={`${CSS.l_header_left}`}>
                {
                    getBackPath(location.pathname)
                    ?   <HistoryBackBtn />
                    :   <div
                            className="g_click_event_item g_display_none_object web"
                            onClick={() => {
                                setIsOpenMenu((is_open_menu)=>!is_open_menu);
                            }}
                        >
                            <img 
                                src={hamberger_icon} 
                            />
                        </div> 
                }
            </div>

            <div  
                className={`${CSS.l_header_center} g_click_event_item`}              
                onClick={() => {
                    window.location.href = "/";
                }}
            >
                <div className={`${CSS.l_header_logo}`}>
                    <img 
                        src={edrp_oops_logo}
                        style={{
                            height: "100%"
                        }}
                    />
                </div>
            </div>

            <div className={`${CSS.l_header_right}`}>
                {
                    user_type === "E"
                    &&  <>
                            <div>
                                {getCookie("name") || null}님
                            </div>
                            <div className="g_display_none_object mobile">
                                {office_name}점
                            </div>
                            <Button
                                size={"size1"}
                                purpose={"delete"} 
                                onClick={logoutBtnHandler}
                            >
                                로그아웃
                            </Button>
                        </>
                }

                {
                    user_type === "C"
                    &&  <>
                            <div 
                                className="g_click_event_item"
                                onClick={() => {
                                    navigate("/myInfo");
                                }}
                            >
                                {getCookie("name") ? `${getCookie("name")}님` : null}
                            </div>
                        </>
                }
            </div>
        </header>
    )
}

export default Header;
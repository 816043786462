import { useEffect, useState } from "react";
import Img from "./components/Img";
import { getFileType } from "../../../Common/ts/commonTools";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

// file_type = 1 : 이미지
// file_type = 2 : 이미지
// file_type = 3 : 이미지
// file_type = 그 외 숫자 : origin_src에서 확장자를 추출하여 자동으로 설정한다.

const Media = (
    {
        origin_src, // file_type의 자동설정이 필요한 경우 경로를통해 자료형을 가져온다.
        src,
        id,
        alt,
        onClick,
        onLoad,
        loading,
        title,
        object_fit,
        height_standard,
        file_type, // file_type을 직접 설정하고싶은경우 사용
        class_name_list,
        video_controls,
    }:{
        origin_src?: string;
        src?: string;
        id?: string;
        className?: string;
        alt?: string;
        onClick?: React.MouseEventHandler<HTMLImageElement>
        onLoad?: React.ReactEventHandler<HTMLImageElement>; 
        loading?: "eager" | "lazy";
        title?: string,
        object_fit?: "cover" | "fill" | "contain" | "scale-down" | "none";
        height_standard?: boolean;
        file_type?: string;
        class_name_list?: string[];
        video_controls?: boolean;
    }
) => {
    const [node, setNode] = useState<React.ReactNode | null>(null);

    useEffect(() => {

        let final_file_type: number = 0;

        if(file_type){
            final_file_type = parseInt(file_type);
        }else{
            final_file_type = getFileType(src || "");
    
            if(final_file_type === 0 && origin_src){
                final_file_type = getFileType(origin_src || "");
            }        
        }

        if(final_file_type === 1){
            setNode(
                <Img
                    src={src}
                    id={id}
                    alt={alt}
                    onClick={onClick}
                    onLoad={onLoad}
                    loading={loading}
                    title={title}
                    object_fit={object_fit}
                    height_standard={height_standard}
                    class_name_list={class_name_list}
                />
            )
        }else if(final_file_type === 2){
            setNode(
                <VideoPlayer 
                    src={src}
                    video_controls={video_controls}
                />
            )
        }else if(final_file_type === 3){
            setNode(
                <div id={id}>
                    ZIP
                </div>
            )
        }else{
            setNode(
                <Img
                    src={src}
                    id={id}
                    alt={alt}
                    onClick={onClick}
                    onLoad={onLoad}
                    loading={loading}
                    title={title}
                    object_fit={object_fit}
                    height_standard={height_standard}
                    class_name_list={class_name_list}
                />
            )
        }

    }, [object_fit, height_standard, file_type, origin_src, src])

    return(
        <>
            {node}
        </>
    ) 
}

export default Media;
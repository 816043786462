import { useEffect, useState } from "react";
import { formatDate } from "../ts/commonTools";

const useDateString = (date_data: string | Date | null, custom_format_string?: string) => {
    const [originDateData, setOriginDateData] = useState<string | Date | null>(date_data);
    const [datetime, setDatetime] = useState<Date | null>(null);
    const [dateString, setDateString] = useState<string>("");

    useEffect(() => {
        if(originDateData !== null){
            let date_object: Date | null = null;

            if(originDateData instanceof Date){
                setDatetime(originDateData);
                date_object = originDateData;
            }else{
                const date_datetime = new Date(originDateData);
                if(isNaN(date_datetime.getTime())){
                    setDatetime(null);
                }else{
                    setDatetime(date_datetime);
                    date_object = date_datetime;
                }
            }

            if(date_object){
                if(!custom_format_string){
                    custom_format_string = "yyyy년 MM월 dd일";
                }

                setDateString(formatDate(date_object, custom_format_string));
            }
        }
    }, [originDateData, custom_format_string])

    return { datetime, dateString, setOriginDateData };
}

export default useDateString;
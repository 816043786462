import { useRef, useState } from "react";

const useInitData = <T, >(init_data: T) => {
    const [data, setData] = useState<T>(init_data);
    const dataRef = useRef<T>(init_data);

    return({
        data,
        setData,
        dataRef,
    })
}

export default useInitData;
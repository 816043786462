import { useContext, useEffect, useState } from "react";
import CSS from "../static/css/productList.module.css";
import { commonContext } from "../../../../App";
import { scheduleProductInterface } from "../../../../CustomerPage/PhotoSelect/static/interface/photoSelect.interface";
import { isNeedToSeletItem } from "../../../../CustomerPage/PhotoSelect/static/ts/photoSelectTools";
import ProductCard from "./ProductList/ProductCard";
import { useNavigate } from "react-router-dom";
import Text from "../../../../Component/Elements/Text/Text";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";

const ProductList = () => {

    const [productList, setProductList] = useState<scheduleProductInterface[]>([]);

    const common_context = useContext(commonContext);

    const navigate = useNavigate();

    const viewAllHandler = () => {
        navigate("/photoSelect")
    }

    useEffect(() => {
        if(common_context.productList){
            const filted_product_list = common_context.productList.filter((filter_item) => isNeedToSeletItem(filter_item));
            setProductList(filted_product_list);
        }else{
            setProductList([]);
        }
    }, [common_context.productList])

    return(
        <div className={`${CSS.l_product_list_main}`}>
            <div className={CSS.l_product_list__title_continer}>
                <Text
                    size={"size7"}
                    bold={true}
                >
                    제품 제작 신청
                </Text>
                <Text
                    size={"size6"}
                    onClick={viewAllHandler}
                >
                    {"view all >"}
                </Text>
            </div>
            <CardContainer
                class_name_list={[CSS.l_card_container]}
                level={"level1"}
            >
                <div
                    className={CSS.l_product_list__product_list}
                >
                    {
                        productList.length > 0
                        ?   productList.map((item, index) => {
                                return(
                                    <ProductCard 
                                        key={index}
                                        schedule_product={item}
                                    />
                                )
                            })
                        :   <Text
                                size={"size4"}
                                class_name_list={[CSS.l_alert_card_text_container]}
                            >
                                제작 신청을 진행해야할 제품이 없습니다.
                            </Text>
                    }
                </div>
            </CardContainer>
        </div>
    )
}

export default ProductList;
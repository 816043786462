import { useEffect, useRef, useState } from "react";
import TextEditerQuill from "../../Component/Elements/TextEditer/TextEditerQuill";
import { isAbleAccesser } from "../../Common/ts/commonTools";
import { themaBookDetailListInterface } from "../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { useNavigate } from "react-router-dom";
import { getDifferentImageUrls } from "../../Component/Elements/TextEditer/static/ts/tools";
import CSS from "./static/css/themeBookManagement.module.css";
import TitleContainer from "../../PublicPage/ThemeBook/components/TitleContainer";
import ThemeDetailModalBtnContainer from "../../PublicPage/ThemeBook/components/ThemeDetailModalBtnContainer";

const ThemeBookManagement = () => {
    
    const [mainContent, setMainContent] = useState<string>("");
    const [selectedSubTheme, setSelectedSubTheme] = useState<themaBookDetailListInterface | null>(null);
    
    const navigate = useNavigate();

    // 삭제되야할 이미지 목록
    const removedImageUrlListRef = useRef<string[]>([]);

    useEffect(() => {
        isAbleAccesser(["E"]);

        const selected_sub_theme_session = sessionStorage.getItem("selectedSubTheme");
        if(selected_sub_theme_session){
            const result = JSON.parse(selected_sub_theme_session);

            if(result){
                setSelectedSubTheme(result);
            }else{
                navigate("/themeBook");
                sessionStorage.removeItem("selectedSubTheme");
            }
        }else{
            navigate("/themeBook");
            sessionStorage.removeItem("selectedSubTheme");
        }

        return (() => {
            sessionStorage.removeItem("selectedSubTheme");
        })
    }, [])

    useEffect(() => {
        setMainContent(selectedSubTheme?.content || "");
    }, [selectedSubTheme])

    useEffect(() => {
        if(selectedSubTheme){
            removedImageUrlListRef.current = getDifferentImageUrls({
                origin: selectedSubTheme.content || "",
                newContent: mainContent,
                conditions: "removed",
            });
        }
    }, [mainContent, selectedSubTheme])

    return (
        <main className={CSS.l_theme_book_management_main}>
            <div className={CSS.l_theme_book_management__main_container}>
                <div className={CSS.l_title_container}>
                    <TitleContainer 
                        photo_name={selectedSubTheme?.photo_name || ""}
                        title={selectedSubTheme?.thema_name || ""}
                        branch_name={selectedSubTheme?.office_name || ""}
                    />             
                </div>
                <div className={CSS.l_text_editer_quill_container}>
                    <TextEditerQuill
                        read_only={false}
                        content={mainContent}
                        setContent={setMainContent}
                    />
                </div>
                <div className={CSS.l_btn_container}>
                    <ThemeDetailModalBtnContainer 
                        themeData={selectedSubTheme}
                        isInEditPage={true}
                        removedImageUrlListRef={removedImageUrlListRef}
                        mainContent={mainContent}
                    />
                </div>
            </div>
        </main>
    )
}

export default ThemeBookManagement;
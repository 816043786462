import { useContext, useEffect, useState } from "react";
import Button from "../../../Component/Elements/Button/Button";
import CSS from "../static/css/CustomerConfirm.module.css";
import Modal from "../../../Component/Elements/Modal/Modal";
import { callAxios, dev_console } from "../../../Common/ts/commonTools";
import { commonContext } from "../../../App";
import { customerConfirmedType, managerConfirmedType } from "../static/interface/PhotoList.interface";
import { EXAMPLE_TEXT_LIST } from "../../../Common/ts/const";
import TextFlexWrap from "../../../Component/Elements/TextFlexWrap/TextFlexWrap";
import Textarea from "../../../Component/Elements/Textarea/Textarea";
import { isCanConfrom } from "../static/ts/confirmEvent.tools";

const CustomerConfirm = (
    {
        photo_upload_id,
        photo_type,
        customer_confirmed,
        manager_confirmed,
        confirm_reason,
        request_num,
        contract_product_id,
    }:{
        photo_upload_id: string;
        photo_type: string;
        customer_confirmed: customerConfirmedType;
        manager_confirmed: managerConfirmedType;
        confirm_reason: string | null;
        request_num: string | null;
        contract_product_id: number | null;
    }
) => {

    const [isRefusal, setIsRefusal] = useState<boolean>(false);
    const [refusalMsg, setRefusalMsg] = useState<string>("");
    const [node, setNode] = useState<React.ReactNode | null>(null);

    const common_context = useContext(commonContext);

    useEffect(() => {
        setRefusalMsg(confirm_reason || "");
    }, [confirm_reason])

    useEffect(() => {
        let state: number = 0;
        if(photo_type){
            const photo_type_num:number = parseInt(photo_type);

            if(!isNaN(photo_type_num)){
                if(photo_type_num === 2){
                    
                    const result = isCanConfrom({
                        customer_confirmed: customer_confirmed,
                        manager_confirmed: manager_confirmed,
                    })

                    
                    if(result.flag){
                        state = 1;
                    }else{
                        if(result.order === "C"){
                            if(result.result === "N"){
                                state = 4;
                            }else if(result.result === "Y"){
                                state = 2;
                            }else if(result.result === "A"){
                                state = 3;
                            }
                        }else if(result.order === "M"){
                            state = 3;
                        }
                    }
                }
            }
        }

        switch(state){
            case 1:
                setNode(
                    <div className={CSS.l_customer_confirm_main}>    
                        <div className={CSS.l_customer_confirm__btn_container}>
                            <Button
                                purpose={"delete"}
                                size={"size_full"}
                                onClick={refusalModalOpenBtnHandler}
                            >
                                사진이 마음에 들지 않아요.
                            </Button>
                            <Button
                                purpose={"save"}
                                size={"size_full"}
                                onClick={confirmBtnHandler}
                            >
                                사진이 마음에 들어요!
                            </Button>
                        </div>
                        
                        <Modal
                            isModalOpen={isRefusal}
                            modalCloseHandler={closeModalHander}
                        >
                            <div className={`${CSS.l_customer_confirm__refusal_textarea_container}`}>
                                <Textarea
                                    class_name_list={[CSS.l_customer_confirm__refusal_textarea_container__textarea]}
                                    onChange={refusalMsgOnChageHandler}
                                    placeholder={EXAMPLE_TEXT_LIST.join(`\n`)}
                                >
                                    {refusalMsg}
                                </Textarea>
                                <div className={CSS.l_customer_confirm__refusal_textarea_container__btn_container}>
                                    <Button
                                        onClick={refusalBtnHandler}
                                        size={"size1"}
                                        purpose={"delete"}
                                    >
                                        재편집요청
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                )
                break;
            case 2:
                setNode(
                    <div className={CSS.l_customer_confirm_main}>
                        <div className={CSS.l_customer_confirm__btn_container}>
                            <Button
                                size={"size_full"}
                                purpose={"disabled"}
                            >
                                최종적인 확인이 완료되었습니다.
                            </Button>
                        </div>
                    </div>
                )
                break;
            case 3:
                setNode(
                    <div className={CSS.l_customer_confirm_main}>
                        <div className={CSS.l_customer_confirm__btn_container}>
                            <Button
                                size={"size_full"}
                                purpose={"disabled"}
                            >
                                담당자에 의하여 재편집이 요청되었습니다.
                            </Button>
                        </div>
                    </div>
                )
                break;
            case 4:
                setNode(
                    <div className={CSS.l_customer_confirm_main}>
                        <TextFlexWrap
                            classNameList={["g_text_wrap", "warning", "g_text", "size1"]}
                            text_list={[`재편집이 요청되었습니다.` ]}
                            text_list_justify_content={"center"}
                        />
                        <div className={CSS.l_customer_confirm__btn_container}>
                            <Button
                                size={"size_full"}
                                onClick={refusalModalOpenBtnHandler}
                            >
                                재편집 요청 내용 확인
                            </Button>
                        </div>

                        <Modal
                            isModalOpen={isRefusal}
                            modalCloseHandler={closeModalHander}
                        >
                            <div className={`${CSS.l_customer_confirm__refusal_textarea_container}`}>
                                <Textarea
                                    class_name_list={[CSS.l_customer_confirm__refusal_textarea_container__textarea]}
                                    readOnly={true}
                                >
                                    {refusalMsg}
                                </Textarea>
                            </div>
                        </Modal>
                    </div>
                )
                break;
            default:
                setNode(null);
                break;
        }

    }, [photo_type, customer_confirmed, refusalMsg, isRefusal, request_num])

    const refusalModalOpenBtnHandler = () => {
        const request_num_number: number = parseInt(request_num || "");
        if(isNaN(request_num_number)){
            alert("재요청 데이터에서 문제가 발견되었습니다.\n스튜디오로 문의해주세요.");
            return ;
        }

        if(request_num_number > 2){
            alert("재요청 가능 횟수를 초과하셨습니다.\n스튜디오로 문의해주세요.")
            return ;
        }

        setIsRefusal(true);
    }

    const confirmBtnHandler = () => {
        if(common_context.activeRequestsManager && common_context.activeRequestsManager.status()){
            dev_console.error("다른 요청이 아직 완료되지 않은 상태에서 신규 요청을 시도함");
            return ;
        }

        if(!window.confirm("상품 제작을 시작할까요?\n제작이 시작되면 변경이 불가능합니다.")){
            return ;
        }

        const config = {
            photo_upload_id: `${photo_upload_id}`,
            confirm: 2,
            confirm_reason: "",
            contract_product_id: contract_product_id,
        }

        const edit_photo_conf_url = "api/v3/customers/edit-photo-conf";
        if(common_context.activeRequestsManager){
            common_context.activeRequestsManager.add(edit_photo_conf_url);
        }
        callAxios.api({
            url: edit_photo_conf_url,
            method: "post",
            dataType: "json",
            data: config,
        })
        .then((response) => {
            dev_console.log(edit_photo_conf_url);
            dev_console.log(response);

            if(response.status === 200){
                alert("제품제작이 승인되었습니다.");
                window.location.reload();
            }else{
                alert("제품제작승인요청에 실패했습니다.");
            }
        })
        .catch((error) => {
            dev_console.error(error);
            alert("제품제작승인요청 과정에서 문제가 발생했습니다.")
        })
        .finally(() => {
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.sub(edit_photo_conf_url);
            }
        })
    }

    const refusalBtnHandler = () => {
        
        const request_num_number: number = parseInt(request_num || "");
        if(isNaN(request_num_number)){
            alert("재요청 데이터에서 문제가 발견되었습니다.\n스튜디오로 문의해주세요.");
            return ;
        }

        if(request_num_number > 2){
            alert("재요청 가능 횟수를 초과하셨습니다.\n스튜디오로 문의해주세요.")
            return ;
        }

        if(common_context.activeRequestsManager && common_context.activeRequestsManager.status()){
            dev_console.error("다른 요청이 아직 완료되지 않은 상태에서 신규 요청을 시도함");
            return ;
        }

        if(refusalMsg.length === 0){
            alert("사진이 마음에 들지 않는 이유를 작성해주세요.");
            return ;
        }

        if(window.confirm("재편집이 요청됩니다.\n진행하시겠습니까?")){
            
            const config = {
                photo_upload_id: `${photo_upload_id}`,
                confirm: 4,
                confirm_reason: refusalMsg,
                contract_product_id: contract_product_id,
            }
    
            const edit_photo_conf_url = "api/v3/customers/edit-photo-conf";
            if(common_context.activeRequestsManager){
                common_context.activeRequestsManager.add(edit_photo_conf_url);
            }
            callAxios.api({
                url: edit_photo_conf_url,
                method: "post",
                dataType: "json",
                data: config,
            })
            .then((response) => {
                dev_console.log(edit_photo_conf_url);
                dev_console.log(response);
    
                if(response.status === 200){
                    alert("재편집 요청이 신청되었습니다.");
                    window.location.reload();
                }else{
                    alert("제품제작승인요청에 실패했습니다.");
                }
            })
            .catch((error) => {
                dev_console.error(error);
                alert("제품제작승인요청 과정에서 문제가 발생했습니다.")
            })
            .finally(() => {
                if(common_context.activeRequestsManager){
                    common_context.activeRequestsManager.sub(edit_photo_conf_url);
                }
            })

            closeModalHander();
        }
    }

    const refusalMsgOnChageHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRefusalMsg(event.target.value);
    }

    const closeModalHander = () => {
        setIsRefusal(false);
    }

    return(
        <>
            {node}
        </>
    )
}

export default CustomerConfirm;
import { MouseEventHandler } from "react";
import LoopAnimation from "../LoopAnimation/LoopAnimation";
import Modal from "../Modal/Modal";
import CSS from "./static/css/loopAnimationModal.module.css";

const LoopAnimationModal = (
    {
        isModalOpen,
        handleOnClose,
        children
    }:{
        isModalOpen: boolean,
        handleOnClose?: MouseEventHandler<HTMLDivElement>;
        children?: React.ReactNode;
    }
) => {
    return (
        <Modal
            isModalOpen={isModalOpen}
            modalCloseHandler={handleOnClose}
        >
            <div className={CSS.l_loop_animation_modal_main}>
                <LoopAnimation />
                <div className={CSS.l_loop_animation_modal__text_container}>
                    {children}
                </div>
            </div>
        </Modal>
    )
}

export default LoopAnimationModal;
import loginMainPic from '../../Common/image/login/OOPS_1024x1024.png';
import LoginInputContainer from './components/LoginInputContainer';
import "./static/css/Login.css";
import CSS from "./static/css/login.module.css";

function Login() {

    return (
        <>
            {/* 웹 화면 */}
            <div className='g_display_none_object mobile login_wrap'>
                <section className='sign-in'>
                    <div className='container'>
                        <div className='signin-content'>
                            <nav className='select_login'>
                                <LoginInputContainer />
                            </nav>
                        </div>
                    </div>
                </section>
                    <section className='home_login_wrap'> 
                    <img src={loginMainPic} alt='main' />
                </section>
            </div>

            {/* 모바일화면 */}
            <div className={`g_display_none_object web flex ${CSS.l_login_main}`}>
                <div className={CSS.l_login_contents_container}>
                    <LoginInputContainer />
                </div>
            </div>
        </>
    );
}
export default Login;

import { themaBookDetailListInterface } from "../../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import ThemeItemBlock from "../../Reservation/components/element/ThemeItemBlock";
import CSS from "../static/css/optionsContainer.module.css";

const OptionsContainer = (
    {
        selectedProductOptionDataList,
        selectedOptionList,
        setSelectedOptionList,
        disabled,
    }:{
        selectedProductOptionDataList: themaBookDetailListInterface[];
        selectedOptionList: string[];
        setSelectedOptionList: React.Dispatch<React.SetStateAction<string[]>>;
        disabled?: boolean;
    }
) => {
    
    const handleSelectOption = (option_item: themaBookDetailListInterface) => {
        if(disabled === true){
            return ;
        }

        if(selectedOptionList.some((item) => parseInt(item) === option_item.thema_id)){
            setSelectedOptionList((item) => item.filter((filter_item) => parseInt(filter_item) !== option_item.thema_id));
        }else{
            // let is_add = false;
            // let error_msg = "";

            setSelectedOptionList([`${option_item.thema_id}`]);
            // is_add = true;

            // if(!is_add){
            //     alert(`옵션 선택이 불가능합니다.\n사유 : ${error_msg}`);   
            // }
        }
    }

    return (
        <div className={CSS.l_options_container_main}>
            {
                selectedProductOptionDataList.map((item, index) => {
                    if(disabled){
                        
                    }else{
                        return (
                            <ThemeItemBlock
                                key={index}
                                img_src={item.thema_thumbnail.middle_url}
                                theme_name={item.thema_name}
                                is_checked={selectedOptionList.some((selected_option_item) => parseInt(selected_option_item) === item.thema_id)}
                                onClickMain={() => {
                                    handleSelectOption(item);
                                }}
                                size={"small"}
                            />
                        )
                    }
                })
            }
        </div>
    )
}

export default OptionsContainer;
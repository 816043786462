import { useEffect, useState } from "react";
import CSS from "../static/css/mainButton.module.css"
import Badge from "../../../Component/Elements/Badge/Badge";

const MainButton = (
    {
        name,
        img_src,
        activated_img_src,
        onClickFc,
        is_activated,
        is_print_badge,
    }:{
        name: string;
        img_src: string;
        activated_img_src: string;
        onClickFc?: React.MouseEventHandler<HTMLDivElement>;
        is_activated?: boolean;
        is_print_badge: boolean;
    }
) => {

    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [imgNode, setImgNode] = useState<React.ReactNode | null>(null);

    useEffect(() => {

        let state:number = 0;

        if(img_src){
            if(activated_img_src){
                if(isHovered || is_activated){
                    state = 1;
                }else{
                    state = 2;
                }
            }else{
                state = 2;
            }
        }

        switch(state){
            case 1:
                setImgNode(<img src={activated_img_src} />);
                break;
            case 2:
                setImgNode(<img src={img_src} />);
                break;
            default:
                setImgNode(null);
        }

    }, [img_src, activated_img_src, isHovered, is_activated])

    return (
        <div
            className={
                onClickFc
                ?   `g_click_event_item ${CSS.l_main_button_main}`
                :   CSS.l_main_button_main
            }
            onClick={onClickFc}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div
                className={CSS.l_main_button__img_container}
            >
                {imgNode}
            </div>
            <div
                className={
                    is_activated
                    ?   `${CSS.l_lable} ${CSS.activate}`
                    :   CSS.l_lable
                }
            >
                <Badge
                    is_print_badge={is_print_badge}
                >
                    {name}
                </Badge>
            </div>
        </div>
    )
}

export default MainButton;
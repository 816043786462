import { MouseEventHandler } from "react";
import MoveBtnWrap from "./MoveBtnWrap";
import CalendarTitle from "./CalendarTitle";
import CSS from "../static/css/calendarHeader.module.css";

const CalendarHeader = (
    {
        title,
        handlePrevClick,
        handleNextClick,
    }:{
        title: string;
        handlePrevClick: MouseEventHandler<HTMLDivElement>;
        handleNextClick: MouseEventHandler<HTMLDivElement>;
    }
) => {
    return(
        <div className={CSS.l_calendar_header_main}>
            <CalendarTitle 
                title={title}
            />
            <MoveBtnWrap 
                handlePrevClick={handlePrevClick}
                handleNextClick={handleNextClick}
            />
        </div>
    )
}

export default CalendarHeader;
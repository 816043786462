import { useEffect, useState } from "react";

const TitleBtnWrapHook = <T, >(data_list: T[]) => {
    const [dataList, setDataList] = useState<T[]>(data_list);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [selectedData, setSelectedData] = useState<T | null>(null);

    useEffect(() => {
        if(dataList.length > selectedIndex){
            setSelectedData(dataList[selectedIndex])
        }else{
            setSelectedData(null);
        }
    }, [dataList, selectedIndex])
    
    return{
        selectedIndex,
        setSelectedIndex,
        selectedData,
        setDataList,
    }
};

export default TitleBtnWrapHook;
import { useEffect, useState } from "react"
import { contractDetailDataInterface, contractDetailListInterface, productInofoInterface } from "../static/interface/MyContractPrintPage.interface";
import { addCommasToPrice, callAxios, dev_console, isSuccess } from "../../../Common/ts/commonTools";
import { commonResponseInterface } from "../../../Common/interface/App.interface";
import { productTypeType } from "../../PhotoSelect/static/interface/photoSelect.interface";
import { useQuery } from "@tanstack/react-query";

const useContractDataREST = (contract_id: string) => {
    const [babyName, setBabyName] = useState<string>("");
    const [babyBrith, setBabyBrith] = useState<string>("");
    const [momName, setMomName] = useState<string>("");
    const [momPhone, setMomPhone] = useState<string>("");
    const [dadName, setDadName] = useState<string>("");
    const [dadPhone, setDadPhone] = useState<string>("");
    const [counselor, setCounselor] = useState<string>("");
    const [photographer, setPhotographer] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [firstBirthdayParty, setFirstBirthdayParty] = useState<string>("");
    const [oopsId, setOopsId] = useState<string>("");
    const [contractDate, setContractDate] = useState<string>("");
    const [customerNo, setCustomerNo] = useState<string>("");
    const [total, setTotal] = useState<string>("");
    const [downPayment, setDownPayment] = useState<string>("");
    const [interimPayment, setInterimPayment] = useState<string>("");
    const [balance, setBalance] = useState<string>("");
    const [productTitle, setProductTitle] = useState<string>("");
    const [productInfoList, setProductInfoList] = useState<productInofoInterface[]>([]);
    const [date, setDate] = useState<string>("");
    const [signImgUrl, setSignImgUrl] = useState<string>("");
    const [customerSignImgUrl, setCustomerSignImgUrl] = useState<string>("");

    const {
        data: contractDetailData, 
        error, 
        isLoading
    } = useQuery({
        queryKey: ["contractDetailData", contract_id],
        queryFn: callContractDetailData,
        enabled: !!contract_id,
        select: (data) => {
            if(isSuccess(data)){
                return data.data;
            }else{
                return null;
            }
        }
    })

    async function callContractDetailData(){
        const contract_detail_url = `api/v3/customers/contract/detail?contract_id=${contract_id}`
        const response = await callAxios.api({
            url: contract_detail_url,
            method: "get",
        })

        dev_console.log(contract_detail_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(contractDetailData){
            const response_data: commonResponseInterface<contractDetailDataInterface> = contractDetailData;
            const customer_data = response_data.result.customer_data;

            setBabyName(customer_data.baby_name || "");
            setBabyBrith(customer_data.baby_birth || "");
            setMomName(customer_data.mom_name || "");
            setMomPhone(customer_data.mom_phone || "");
            setDadName(customer_data.dad_name || "");
            setDadPhone(customer_data.dad_phone || "");
            setAddress(customer_data.customer_addr || "");
            setFirstBirthdayParty(customer_data.baby_birthparty_loc || "");

            // 고객번호와 OOPSID가 같은 동안에는 이렇게 처리해도됨.
            // 혹시라도 OOPSID의 생성 값이 달라진다면 이 부분도 수정되어야함.
            setOopsId(customer_data.customer_no || "");
            setCustomerNo(customer_data.customer_no || "");
            
            setPhotographer(customer_data.photo_manager || "");

            const contract_data = response_data.result.contract_data
            setCounselor(contract_data.staff_name || "");
            setContractDate(contract_data.product_contract_date || "");

            // 계약 금액
            const contract_totamount_add_comma = addCommasToPrice(contract_data.contract_totamount || "");
            setTotal(contract_totamount_add_comma);

            const contract_payadvance_add_comma = addCommasToPrice(contract_data.contract_payadvance || "");
            setDownPayment(contract_payadvance_add_comma);

            const contract_payinterim_add_comma = addCommasToPrice(contract_data.contract_payinterim || "");
            setInterimPayment(contract_payinterim_add_comma);

            const contract_balance_add_comma = addCommasToPrice(contract_data.contract_balance || "");
            setBalance(contract_balance_add_comma);

            setProductTitle(contract_data.package_name || "");
            setDate(contract_data.product_contract_date || "");


            const product_data_list: productInofoInterface[] = groupProductsByType(response_data.result.contract_detail_list);
            setProductInfoList(product_data_list || []);
            setSignImgUrl("");
            setCustomerSignImgUrl("");
        }else{
            setBabyName("");
            setBabyBrith("");
            setMomName("");
            setMomPhone("");
            setDadName("");
            setDadPhone("");
            setAddress("");
            setFirstBirthdayParty("");

            // 고객번호와 OOPSID가 같은 동안에는 이렇게 처리해도됨.
            // 혹시라도 OOPSID의 생성 값이 달라진다면 이 부분도 수정되어야함.
            setOopsId("");
            setCustomerNo("");
            
            setPhotographer("");

            setCounselor("");
            setContractDate("");

            // 계약 금액
            setTotal("");
            setDownPayment("");
            setInterimPayment("");
            setBalance("");

            setProductTitle("");
            setDate("");

            setProductInfoList([]);
            setSignImgUrl("");
            setCustomerSignImgUrl("");
        }
    }, [contractDetailData])

    useEffect(() => {
        if(!!error){
            alert("계약서 상세정보를 요청하는 과정에서 문제가 발생했습니다.");
            window.close();
        }
    }, [error]);

    const groupProductsByType = (
        contractDetails: contractDetailListInterface[]
    ): productInofoInterface[] => {
        const productMap: Map<productTypeType, string[]> = new Map();
    
        // 제품 타입별로 이름을 그룹화
        contractDetails.forEach((detail) => {
            const { product_type, product_name } = detail;
    
            if (!productMap.has(product_type)) {
                productMap.set(product_type, []);
            }
    
            productMap.get(product_type)!.push(product_name);
        });
    
        // Map을 productInofoInterface 배열로 변환
        const groupedProducts: productInofoInterface[] = [];
    
        productMap.forEach((product_name_list, product_type) => {
            groupedProducts.push({
                product_type,
                product_name_list,
            });
        });
    
        return groupedProducts;
    };

    return(
        {
            babyName,
            babyBrith,
            momName,
            momPhone,
            dadName,
            dadPhone,
            counselor,
            photographer,
            address,
            firstBirthdayParty,
            oopsId,
            contractDate,
            customerNo,
            total,
            downPayment,
            interimPayment,
            balance,
            productTitle,
            productInfoList,
            date,
            signImgUrl,
            customerSignImgUrl,
            isLoading,
        }
    )

}

export default useContractDataREST;
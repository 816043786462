import { MouseEventHandler } from "react";
import CSS from "../static/css/moveBtnWrap.module.css";

import left_move_arrow from "../static/img/left_move_arrow.svg";
import right_move_arrow from "../static/img/right_move_arrow.svg";

const MoveBtnWrap = (
    {
        handlePrevClick,
        handleNextClick,
    }:{
        handlePrevClick: MouseEventHandler<HTMLDivElement>;
        handleNextClick: MouseEventHandler<HTMLDivElement>;
    }
) => {
    return (
        <div className={CSS.l_move_btn_wrap}>
            <div 
                className={`g_click_event_item g_box_shadow ${CSS.l_btn} ${CSS.l_prev_btn}`}
                onClick={handlePrevClick}
            >
                <img 
                    className={CSS.l_arrow_img}
                    src={left_move_arrow}
                />
            </div>
            <div
                onClick={handleNextClick}
                className={`g_click_event_item g_box_shadow ${CSS.l_btn} ${CSS.l_next_btn}`}
            >   
                <img 
                    className={CSS.l_arrow_img}
                    src={right_move_arrow}
                />
            </div>
        </div>
    )
}

export default MoveBtnWrap;
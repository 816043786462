import { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { CalendarOptions, EventSourceInput } from "@fullcalendar/core";
import CalendarHeader from "./components/CalendarHeader";
import CSS from "./static/css/customFullCalendar.module.css";
import DayHeader from "./components/DayHeader";
import DayCell from "./components/DayCell";

const CustomFullCalendar = (
    {
        selectedDate, 
        setSelectedDate,
        events,
    }:{
        selectedDate: Date | null
        setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
        events?: EventSourceInput;
    }
) => {
    const [title, setTitle] = useState<string>('');

    const calendarRef = useRef<FullCalendar>(null);

    const handlePrevClick = () => {
        const calendar_api = calendarRef.current?.getApi();
        if(calendar_api){
            calendar_api.prev();
            setTitle(calendar_api.view.title);
        }
    };

    const handleNextClick = () => {
        const calendar_api = calendarRef.current?.getApi();
        if(calendar_api){
            calendar_api.next();
            setTitle(calendar_api.view.title);
        }
    };

    useEffect(() => {
        const calendar_ref_current = calendarRef.current;
        if(calendar_ref_current){
            const calendarApi = calendar_ref_current.getApi();
            if (calendarApi) {
                setTitle(calendarApi.view.title);
            }
        }
    }, []);

    const calendarOptions: CalendarOptions = {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        events: events,
        locale: 'ko', // 한국어 로케일 설정 -> 달력이 한글로 표시됨
        titleFormat: { year: 'numeric', month: 'long' }, // 날짜 형식 설정
        headerToolbar: false,
        height: "100%",
        dayCellContent: (dayCellInfo) => <DayCell dayCellInfo={dayCellInfo} selectedDate={selectedDate} />,
        dayCellDidMount: (dayCellInfo) => {
            dayCellInfo.el.addEventListener('click', () => {
                setSelectedDate(dayCellInfo.date);
            });
        },
        dayHeaderContent: DayHeader,
    };

    return(
        <div className={`${CSS.l_custom_full_calendar_main}`}>
            <CalendarHeader 
                title={title}
                handlePrevClick={handlePrevClick}
                handleNextClick={handleNextClick}
            />
            <div className={CSS.l_custom_full_calendar__calendar_container}>
                <FullCalendar 
                    ref={calendarRef}
                    {...calendarOptions}
                />
            </div>
        </div>
    )
}

export default CustomFullCalendar;
import { useEffect, useState } from "react";
import CSS from "./static/css/badge.module.css";

const Badge = (
    {

        badge,
        children,
        is_print_badge,
        class_name_list,
    }:{
        badge?: string;
        children?: React.ReactNode;
        is_print_badge: boolean;
        class_name_list?: string[];
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");
    const [totalBageMarkContainerClassName, setTotalBageMarkContainerClassName] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_badge_main)

        setTotalClassName(total_class_name_list.join(" "));
    }, [class_name_list])

    useEffect(() => {
        const total_class_name_list: string[] = ["g_text", "size1"];

        total_class_name_list.push(CSS.l_bage_mark_container);

        setTotalBageMarkContainerClassName(total_class_name_list.join(" "));

    }, [])

    return (
        <div
            className={totalClassName}
        >
            {
                is_print_badge
                &&  <div className={totalBageMarkContainerClassName}>
                        {badge || "●"}
                    </div>  
            }
            {
                children
                &&  <div className={`${CSS.l_bage_children_container}`}>
                        {children}
                    </div>   
            }
        </div>
    )
}

export default Badge;
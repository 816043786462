import { useEffect, useState } from "react";
import CSS from "./static/css/flex.module.css";

const Flex = (
    {
        class_name_list,
        children,
    }:{
        class_name_list?: string[];
        children: React.ReactNode;
    }
) => {

    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        
        const total_class_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_list.push(class_name);
            }
        }

        total_class_list.push(CSS.l_flex_main);
        setTotalClassName(total_class_list.join(" "));

    }, [class_name_list])

    return (
        <div className={totalClassName}>
            {children}
        </div>
    )
}

export default Flex;
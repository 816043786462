import { MouseEventHandler } from "react";
import CSS from "./static/css/modal.module.css";

const Modal = (
    {
        isModalOpen,
        modalCloseHandler,
        className,
        children,
        isBackgroundClose,
    }:{
        isModalOpen: boolean;
        modalCloseHandler?: MouseEventHandler<HTMLDivElement>;
        className?: string;
        children?: React.ReactNode;
        isBackgroundClose?: boolean;
    }
) => {

    if(isModalOpen){
        return (
            <div 
                className={
                    className
                    ? `g_no_print ${className} ${CSS.l_modal}`
                    : `g_no_print ${CSS.l_modal}`   
                }
                onClick={isBackgroundClose ? modalCloseHandler : undefined}
            >
                <div 
                    className={CSS.l_modal__children_container}
                >
                    {
                        modalCloseHandler
                        &&  <div 
                                className={`g_icon g_click_event_item g_no_print g_modal__modal_close_btn`}
                                onClick={modalCloseHandler}
                            >
                                X
                            </div>
                    }
                    <div 
                        className={CSS.l_modal__children_container__children}
                    >
                        {children}
                    </div>
                </div>
            </div>
        )
    }else{
        return <></>
    }
}

export default Modal
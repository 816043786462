import InfoBlock from "../InfoBlock/InfoBlock";
import PartContainer from "../PartContainer/PartContainer";
import Tag from "../Tag/Tag";
import CSS from "./static/css/ContractPay.module.css";

const ContractPay = (
    {
        total,
        down_payment,
        interim_payment,
        balance,
    }:{
        total: string;
        down_payment: string;
        interim_payment: string;
        balance: string;
    }
) => {
    return(
        <PartContainer 
            tag={
                <Tag 
                    title={"계약금액"}
                />
            }
            body={
                <div className={CSS.l_contract_pay_main}>
                    <div className={CSS.l_contract_pay__left_container}>
                        <InfoBlock 
                            title="총액"
                            body={[
                                [
                                    {
                                        text: `${total}원`
                                    }
                                ]
                            ]}
                            horizontal_division_ratio={"symmetry"}
                        />
                        <InfoBlock 
                            title="선금"
                            body={[
                                [
                                    {
                                        text: `${down_payment}원`
                                    }
                                ]
                            ]}
                            horizontal_division_ratio={"symmetry"}
                        />
                        <InfoBlock 
                            title="중도금"
                            body={[
                                [
                                    {
                                        text: `${interim_payment}원`
                                    }
                                ]
                            ]}
                            body_background_color={"same_title"}
                            horizontal_division_ratio={"symmetry"}
                        />
                        <InfoBlock 
                            title="잔금"
                            body={[
                                [
                                    {
                                        text: `${balance}원`,
                                    }
                                ]
                            ]}
                            horizontal_division_ratio={"symmetry"}
                        />
                    </div>
                    <div className={CSS.l_contract_pay__right_continaer}>
                    </div>
                </div>
            }
        />
    )
}

export default ContractPay;
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import PhotoListBlock from "./components/PhotoListBlock";
import { dev_console, isAbleAccesser } from "../../Common/ts/commonTools";
import WideCard from "../../Component/Elements/WideCard/WideCard";
import { commonContext, getPhotoListDataResult } from "../../App";
import { photoReadDataInterface } from "./static/interface/PhotoList.interface";
import style from "./static/css/photoList.module.css";
import Grid from "../../Component/Elements/Grid/Grid";
import LoopAnimation from "../../Component/Elements/LoopAnimation/LoopAnimation";
import { isCanConfrom } from "./static/ts/confirmEvent.tools";
import { flagInterface } from "./static/interface/confirmEvent.Interface";

interface badgePointInterface{
    is_someting_point: boolean;
    childrens: boolean[];
}

const PhotoList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [badgePoint, setBadgePoint] = useState<badgePointInterface[]>([]);
    const [mode, setMode] = useState<number>(1);
    const [viewRange, setViewRange] = useState<number>(1);

    const [photoDataList, setPhotoDataList] = useState<photoReadDataInterface[]>([]);

    const [photoListNode, setPhotoListNode] = useState<React.ReactNode | null>(null);

    const common_context = useContext(commonContext);

    const navigate = useNavigate();

    useEffect(() => {
        isAbleAccesser(["C"]);

        if(common_context.updatePhotoListData){
            common_context.updatePhotoListData()
        }
    }, [])

    useEffect(() => {
        if(common_context.photoListData){
            checkIsNewPhoto(common_context.photoListData);
        }else{
            checkIsNewPhoto(null);
        }
    }, [common_context.photoListData])

    useEffect(()=>{

        setPhotoDataList([]);
        if(common_context.getPhotoDataListPart){
            setIsLoading(true);
            setIsError(false);
            if(mode === 1){
                if(viewRange === 1){
                    common_context.getPhotoDataListPart("origin")
                    .then((result) => {
                        if(result.msg === "OK" && result.return_data){
                            setPhotoDataList(result.return_data);
                        }else{
                            if(result.error_msg !== "empty"){
                                setIsError(true);
                            }
                        }
                    })
                    .catch((error)=>{
                        dev_console.error(error);
                        setIsError(true);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
                }else if(viewRange === 2){
                    common_context.getPhotoDataListPart("editing")
                    .then((result) => {
                        if(result.msg === "OK" && result.return_data){
                            setPhotoDataList(result.return_data);
                        }else{
                            if(result.error_msg !== "empty"){
                                setIsError(true);
                            }
                        }
                    })
                    .catch((error)=>{
                        dev_console.error(error);
                        setIsError(true);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
                }else if(viewRange === 3){
                    common_context.getPhotoDataListPart("edited")
                    .then((result) => {
                        if(result.msg === "OK" && result.return_data){
                            setPhotoDataList(result.return_data);
                        }else{
                            if(result.error_msg !== "empty"){
                                setIsError(true);
                            }
                        }
                    })
                    .catch((error)=>{
                        dev_console.error(error);
                        setIsError(true);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
                }else{
                    setIsLoading(false);
                }
            }else if(mode === 2){
                common_context.getPhotoDataListPart("review")
                .then((result) => {
                    if(result.msg === "OK" && result.return_data){
                        setPhotoDataList(result.return_data);
                    }else{
                        if(result.error_msg !== "empty"){
                            setIsError(true);
                        }
                    }
                })
                .catch((error)=>{
                    dev_console.error(error);
                    alert("사진 데이터 처리 과정에서 문제가 발생했습니다.");
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                })
            }else{
                setIsLoading(false);
            }
        }

    }, [mode, viewRange])

    useEffect(() => {
        let status = 0;
        if(isLoading){
            status = 1;
        }else if(isError){
            status = -1;
        }else{
            if(photoDataList.length > 0){
                status = 2;
            }else{
                status = -2;
            }
        } 

        switch(status){
            case 1:
                setPhotoListNode(
                    <WideCard>
                        <LoopAnimation />
                    </WideCard>
                );
                break;
            case -1:
                setPhotoListNode(
                    <WideCard>
                        문제가 발생했습니다.
                    </WideCard>
                );
                break;
            case 2:
                setPhotoListNode(
                    <Grid>
                        {
                            photoDataList.map((item, index) => {
                                let is_print_badge: boolean = false;
                                if(mode === 1 && viewRange === 2){
                                    is_print_badge = isCanConfrom({
                                        customer_confirmed: item.customer_confirmed,
                                        manager_confirmed: item.manager_confirmed,
                                    }).flag;
                                }else if(!item.download_at){
                                    is_print_badge = true;
                                }

                                let is_print_join_event_btn = false;
                                if(mode === 1 && viewRange === 1){
                                    is_print_join_event_btn = true;
                                }

                                let is_print_confirm: flagInterface | null = null;
                                if(mode === 1 && viewRange ===2){
                                    is_print_confirm = isCanConfrom({
                                        customer_confirmed: item.customer_confirmed,
                                        manager_confirmed: item.manager_confirmed,
                                    })
                                }

                                return (
                                    <PhotoListBlock 
                                        key={index}
                                        img_src={item.thumbnail?.small_url || ""}
                                        title={item.photo_shoot_name}
                                        date={item.schedule_date}
                                        onClick={() => {
                                            navigate(
                                                "/photoDetail"
                                                ,{
                                                    state:{
                                                        selected_photo_upload_id: `${item.photo_upload_id}`,
                                                        main_type: `${mode}`,
                                                        viewRange_type: `${viewRange}`,
                                                        photo_type: item.photo_type,
                                                    }
                                                }
                                            );
                                        }}
                                        is_print_badge={is_print_badge}
                                        schedule_id={item.schedule_id}
                                        upload_date={item.upload_at}
                                        joined_event={item.joined_event}
                                        is_print_join_event_btn={is_print_join_event_btn}
                                        is_print_confirm={is_print_confirm}
                                    />
                                )
                            })
                        }
                    </Grid>
                );
                break;
            case -2:
                setPhotoListNode(
                    <WideCard>
                        사진 정보가 없습니다
                    </WideCard>
                );
                break;
            default:
                setPhotoListNode(null);
                break;
        }
    }, [isLoading, isError, photoDataList])

    const checkIsNewPhoto = (data: getPhotoListDataResult | null) => {
        const contract_badge_point: badgePointInterface = {
            is_someting_point: false,
            childrens: [],
        }

        const single_badge_point: badgePointInterface = {
            is_someting_point: false,
            childrens: [],
        }

        if(data){

            // 나의사진 - 원본
            const contract_childrens_badge_point: boolean[] = [];
            if(data.photo_read_contract_origin_result.msg === "OK" && data.photo_read_contract_origin_result.return_data){
                if(data.photo_read_contract_origin_result.return_data.some((item) => !item.download_at)){
                    contract_childrens_badge_point.push(true);
                }else{
                    contract_childrens_badge_point.push(false);
                }
            }else{
                contract_childrens_badge_point.push(false);
            }

            // 나의사진 - 편집 진행중
            if(data.photo_read_contract_editing_result.msg === "OK" && data.photo_read_contract_editing_result.return_data){
                if(data.photo_read_contract_editing_result.return_data.some((item) => {
                    return(
                        isCanConfrom({
                            customer_confirmed: item.customer_confirmed,
                            manager_confirmed: item.manager_confirmed,
                        }).flag
                    );
                })){
                    contract_childrens_badge_point.push(true);
                }else{
                    contract_childrens_badge_point.push(false);
                }
            }else{
                contract_childrens_badge_point.push(false);
            }

            // 나의사진 - 편집완료
            if(data.photo_read_contract_edited_result.msg === "OK" && data.photo_read_contract_edited_result.return_data){
                if(data.photo_read_contract_edited_result.return_data.some((item) => !item.download_at)){
                    contract_childrens_badge_point.push(true);
                }else{
                    contract_childrens_badge_point.push(false);
                }
            }else{
                contract_childrens_badge_point.push(false);
            }

            if(contract_childrens_badge_point.some((item) => item)){
                contract_badge_point.is_someting_point = true;
                contract_badge_point.childrens = contract_childrens_badge_point;
            }
            
            // 후기사진
            if(data.photo_read_review_result.msg === "OK" && data.photo_read_review_result.return_data){
                if(data.photo_read_review_result.return_data.some((item) => !item.download_at)){
                    single_badge_point.is_someting_point = true;
                }else{
                    single_badge_point.is_someting_point = false;
                }
            }else{
                single_badge_point.is_someting_point = false;
            }
        }

        setBadgePoint([contract_badge_point, single_badge_point]);
    }

    return (
        <div className={`${style.l_photo_list_main}`}>
            <div className="g_title">내 사진</div>
            <TitleBtnWrap<number>
                valueState={mode}
                setValueState={setMode}
                value_list={[1, 2]}
                innerText_list={["나의 사진", "후기 사진"]}
                is_activated={true}
                is_print_badge_list={badgePoint.map((item) => item.is_someting_point)}
            />
            <div className={`g_card ${style.l_photo_list_view}`}>
                {
                    mode === 1
                    &&  <TitleBtnWrap<number>
                            valueState={viewRange}
                            innerText_list={["원본", "편집 진행중", "편집 완료"]}
                            setValueState={setViewRange}
                            value_list={[1, 2, 3]}
                            is_activated={true}
                            is_print_badge_list={badgePoint.length > mode - 1 ? badgePoint[mode - 1].childrens : []}
                        />
                }
                <div className={`${style.l_photo_list_container}`}>
                    {photoListNode}
                </div>
            </div>
        </div>
    )
}

export default PhotoList;
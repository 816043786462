import { useEffect, useState } from "react";
import { infoDataElementInterface } from "../../InfoDataTextWrap/InfoDataTextWrap";
import { progressScheduleInterface } from "../../../../CustomerPage/Reservation/static/interface/reservationHome.interface";
import { getTimeDifferenceInMinutes } from "../../../../Common/ts/commonTools";

const useReservationInfoData = () => {
    const [officeName, setOfficeName] = useState<string>("");
    const [scheduleData, setScheduleData] = useState<progressScheduleInterface | null>(null);
    const [infoDataList, setInfoDataList] = useState<infoDataElementInterface[]>([]);

    useEffect(() => {
        if(scheduleData){
            const info_data_list: infoDataElementInterface[] = [];

            const barnch_name = `${officeName}점`;
            const photo_date = `${scheduleData.schedule_date_format || ""}`;
            const photo_start = `${scheduleData.detail?.photo_start || ""}`;
            const photo_end = `${scheduleData.detail?.photo_end || ""}`;
            const photo_time = `${photo_start}-${photo_end}`;
            const runtime = `${getTimeDifferenceInMinutes(photo_start, photo_end, true) || ""}분`;
            const photo_info: string = `${barnch_name} : ${photo_date} - ${photo_time} (${runtime})`

            info_data_list.push({
                title: "촬영 정보",
                value: photo_info,
            })

            info_data_list.push({
                title: "촬영명",
                value: scheduleData.detail?.schedule_title || "",
            })

            if(scheduleData.detail?.contract_id && scheduleData.detail.contract_id.length > 0){
                info_data_list.push({
                    title: "계약번호",
                    value: `${scheduleData.detail.contract_id}`,
                })
                info_data_list.push({
                    title: "패키지 명",
                    value: `${scheduleData.package_name || ""}`,
                })
            }

            if(scheduleData.detail?.memo && scheduleData.detail.memo.length > 0){
                info_data_list.push({
                    title: "전달사항",
                    value: scheduleData.detail.memo,
                    is_bold: true,
                    is_textarea: true,
                })
            }

            setInfoDataList(info_data_list);
        }
    }, [scheduleData, officeName])

    return({
        infoDataList,
        setOfficeName,
        setScheduleData
    })
}

export default useReservationInfoData;
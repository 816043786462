import { useEffect, useState } from "react";
import CSS from "./static/css/grid.module.css";

const Grid = (
    {
        children,
        class_name_list,
        style,
        line2
    }:{
        children?: React.ReactNode;
        class_name_list?: string[];
        style?: React.CSSProperties;
        line2?: boolean;
    }
) => {
    const [totalNameList, setTotalNameList] = useState<string>("");

    useEffect(() => {

        let total_class_name_list:string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }
            
        total_class_name_list.push(CSS.l_grid_container);

        if(line2 === true){
            total_class_name_list.push(CSS.line2);
        }

        setTotalNameList(total_class_name_list.join(" "));

    }, [class_name_list, line2])

    return (
        <div 
            className={totalNameList}
            style={style}
        >
            {children}
        </div>
    )
}

export default Grid;
import Button from "../../../Component/Elements/Button/Button";
import CSS from "../static/css/infoButtonWrap.module.css";

const InfoButtonWrap = (
    {
        isEditMode,
        handleOnSave,
        handleOnCancel,
        handleOnChangePW,
        handleOnInfoFix,
        handleOnLogOut,
    }:{
        isEditMode: boolean;
        handleOnSave?: React.MouseEventHandler<HTMLButtonElement>;
        handleOnCancel?: React.MouseEventHandler<HTMLButtonElement>;
        handleOnChangePW: React.MouseEventHandler<HTMLButtonElement>;
        handleOnInfoFix: React.MouseEventHandler<HTMLButtonElement>;
        handleOnLogOut: React.MouseEventHandler<HTMLButtonElement>;
    }
) => {
    return (
        <div className={CSS.l_lnfo_button_wrap_main}>
            {
                isEditMode
                ?   <>
                        <Button
                            size={"size1"}
                            purpose={"save"} 
                            onClick={handleOnSave}
                        >
                            저장
                        </Button>
                        <Button 
                            size={"size1"}
                            purpose={"cancel"}
                            onClick={handleOnCancel}
                        >
                            취소
                        </Button>
                    </>
                :   <>
                        <Button 
                            size={"size1"}
                            onClick={handleOnChangePW}
                        >
                            비밀번호 변경
                        </Button>
                        <Button 
                            size={"size1"}
                            onClick={handleOnInfoFix}
                        >
                            개인정보 수정
                        </Button>
                        <Button 
                            size={"size1"}
                            purpose={"delete"}
                            onClick={handleOnLogOut}
                        >
                            로그아웃
                        </Button>
                    </>
            }
        </div>
    )
}

export default InfoButtonWrap;